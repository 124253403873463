import React, { useState, useEffect } from 'react'
import "./ModalDecline.scss"
import { Modal, Alert, Button } from "react-bootstrap"

export default function ModalDecline(props) {
  const [declineStatus, setDeclineStatus] = useState({})
  const [justificationMessage, setJustificationMessage] = useState("Nós agradecemos, mas declinamos desta oportunidade")
  const [showAlertControl, setShowAlertControl] = useState(false)
  const [showAlertConfirm, setShowAlertConfirm] = useState(false)
  const [isDeclined, setIsDeclined] = useState(props.opportunity.declineQueue ? true : (!!props.opportunity?.declined))

  // useEffect usando para resetar os estados ao fechar modal
  useEffect(() => {
    setJustificationMessage("Nós agradecemos, mas declinamos desta oportunidade")
    setShowAlertControl(false)
    setShowAlertConfirm(false)
  }, [props.showModal])

  const handleClose = () => props.setShowModal(false)

  // Função do botão de declinar
  function declineAction() {
    if (Object.values(declineStatus).length > 0 && justificationMessage !== "") {
      setShowAlertConfirm(true)
    } else {
      setShowAlertControl(true)
    }
  }

  function confirmDecline() {
    props.declineOpportunity({
      "index": props.opportunity.index,
      "reason": justificationMessage,
      "declined": false,
      "declineQueue": true,
      "opportunityId": props.opportunity._id,
      "dateEnd": props.opportunity.dateEnd,
      "declineStatusId": declineStatus._id,
      "companyId": props.opportunity.companyId,
      "portalId": props.opportunity.portalId,
      "failed": false,
    }, props.user)
    setIsDeclined(true)

    setTimeout(() => {
      props.setShowModal(false)
    }, 10000);
  }

  return (
    <Modal
      size="xl"
      show={props.showModal}
      onHide={handleClose}
    >
      <Modal.Header className="headerModal" closeButton>
        <Modal.Title>{props.title.message} {<p className="sub-title">{props.title.value}</p>}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          isDeclined ?
            <>
              <Alert className="alert-control" variant="success">
                <Alert.Heading className="title-alert">Oportunidade declinada com sucesso!</Alert.Heading>
                <p>
                  A oportunidade Nº{props.title.value} foi declinada com sucesso.<br /> Retorne para a tela inicial para analisar outras oportunidades.
                </p>
              </Alert>
            </>
            :
            <>
              <div className="container-justificativa">
                <p>Justificativa de declínio</p>
                <input value={justificationMessage} onChange={(e) => setJustificationMessage(e.target.value)} type="text" className="input-justificativa" />
              </div>

              {props.declineStatus.map(data => {
                return (
                  <div className="container-radio" >
                    <input
                      className="input-radio"
                      type="radio"
                      id={data.attribute}
                      name="option"
                      onChange={(e) => { setDeclineStatus(data) }}
                    />
                    <label >{data.attribute} - {data.description}</label>
                  </div>
                )
              })}

              <div className="container-btn-decline">
                {!showAlertControl && !showAlertConfirm ? <button onClick={() => { declineAction() }} className="btn-decline">Declinar</button> : ""}
              </div>

              {
                showAlertControl
                  ?
                  <Alert className="alert-control" variant="danger" onClose={() => setShowAlertControl(false)} dismissible>
                    <Alert.Heading>Campos não preenchidos!</Alert.Heading>
                    <p>
                      Para seguir com o declínio, todos os campos devem ser preenchidos.
                    </p>
                  </Alert>
                  :
                  <>
                  </>
              }

              {
                showAlertConfirm ?
                  <Alert className="alert-control" show={showAlertConfirm} variant="primary">
                    <Alert.Heading>Confirmar envio</Alert.Heading>
                    <p>
                      Deseja realmente realizar o declínio?
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button className="mr-3" onClick={confirmDecline} variant="outline-primary">
                        Sim
                      </Button>
                      <Button onClick={() => setShowAlertConfirm(false)} variant="outline-primary">
                        Não
                      </Button>
                    </div>
                  </Alert>
                  :
                  <>
                  </>
              }
            </>
        }

      </Modal.Body>
    </Modal >
  )
}
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchSignUpStart = () => {
  return {
    type: actionTypes.FETCH_SIGNUP_START,
  };
};

export const fetchSignUpSuccess = (signUp) => {
  return {
    type: actionTypes.FETCH_SIGNUP_SUCCESS,
    signUp: signUp,
  };
};

export const fetchSignUpFailed = () => {
  return {
    type: actionTypes.FETCH_SIGNUP_FAILED,
  };
};

export const postSignUp = (signUp) => {
  return (dispatch) => {
    dispatch(fetchSignUpStart());
    return new Promise((resolve, reject) => {

      ServeServices.postSignUp(signUp)
        .then((response) => {
          dispatch(fetchSignUpSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          dispatch(fetchSignUpFailed());
          reject(err);
        });

    });
  };
};

export const postInvite = (user, usuario) => {
  return (dispatch) => {
    dispatch(fetchSignUpStart());
    return new Promise((resolve, reject) => {

      ServeServices.postInvite(user, usuario)
        .then((response) => {
          dispatch(fetchSignUpSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          dispatch(fetchSignUpFailed());
          reject(err);
        });
    });
  };
};

export const confirmSignUp = (signUp) => {
  return (dispatch) => {
    dispatch(fetchSignUpStart());
    return new Promise((resolve, reject) => {

      ServeServices.confirmSignUp(signUp)
        .then((response) => {
          dispatch(fetchSignUpSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          dispatch(fetchSignUpFailed());
          reject(err);
        });

    });
  };
};

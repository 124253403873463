import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchingCompaniesStart = () => {
  return {
    type: actionTypes.FETCH_COMPANIES_START,
  };
};

export const fetchCompaniesSuccess = (action) => {
  return {
    type: actionTypes.FETCH_COMPANIES_SUCCESS,
    companies: action,
  };
};

export const fetchCompaniesFailed = () => {
  return {
    type: actionTypes.FETCH_COMPANIES_FAILED,
  };
};

// export const initCompanies = () =>{
//     return dispatch =>{
//         dispatch(fetchingCompaniesStart());

//         ServeServices.getCompanies()
//         .then(response => {
//             dispatch(fetchCompaniesSuccess(response));
//         })
//         .catch(() =>{
//             dispatch(fetchCompaniesFailed());
//         })
//     }
// }

export const initCompanies = (usuario) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchingCompaniesStart());

      ServeServices.getCompanies(usuario)
        .then((response) => {
          dispatch(fetchCompaniesSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCompaniesFailed());
          reject(err);
        });
        
    });
  };
};

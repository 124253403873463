import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchProposalsStart = () => {
  return {
    type: actionTypes.FETCH_PROPOSALS_START
  }
}

export const fetchProposalsSuccess = (proposals) => {
  return {
    type: actionTypes.FETCH_PROPOSALS_SUCCESS,
    proposals: proposals,
  }
}

export const fetchProposalsFailed = () => {
  return {
    type: actionTypes.FETCH_PROPOSALS_FAILED
  }
}

export const getProposalsStart = () => {
  return {
    type: actionTypes.FETCH_PROPOSALS_START
  }
}

export const getProposalsSuccess = (proposals) => {
  return {
    type: actionTypes.FETCH_PROPOSALS_SUCCESS,
    proposals: proposals,
  }
}

export const getProposalsFailed = () => {
  return {
    type: actionTypes.FETCH_PROPOSALS_FAILED
  }
}

export const initProposals = (token) => {
  return dispatch => {
    dispatch(fetchProposalsStart());

    return new Promise((resolve, reject) => {

      ServeServices.getProposals(token)
        .then(response => {
          dispatch(fetchProposalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchProposalsFailed());
          reject(err);
        })

    })

  }
}

export const getProposals = (token, dateFrom, dateTo) => {
  return dispatch => {
    dispatch(getProposalsStart());

    return new Promise((resolve, reject) => {

      ServeServices.getProposals(token, dateFrom, dateTo)
        .then(response => {
          dispatch(getProposalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(getProposalsFailed());
          reject(err);
        })

    })

  }
}

export const getProposalById = (usuario, index) => {
  return dispatch => {
    dispatch(getProposalsStart());

    return new Promise((resolve, reject) => {

      ServeServices.getProposalById(usuario, index)
        .then(response => {
          dispatch(getProposalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(getProposalsFailed());
          reject(err);
        })

    })

  }
}
import React, { useState } from "react";
import "./MyAccount.scss";
import { Row, Col, Button, InputGroup, DropdownButton, Dropdown, FormControl } from "react-bootstrap";
import SearchInput from "../Ranking/OpportunityFilter/SearchInput/SearchInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import RequestTwoLineChart from './charts/TwoLineChart'
import RequestProgressChart from './charts/ProgressChart'

function MyAccount(props) {
  const [monthsValuesReached, setMonthsValuesReached] = useState([0, 12, 18, 5, 23, 52, 0, 0, 0, 0, 0, 0]);
  const [monthsValuesStipulated, setMonthsValuesStipulated] = useState([0, 1800, 3800, 1500, 2500, 4200, 0, 0, 0, 0, 0, 0]);
  const [goalReached, setGoalReached] = useState();
  const [goalStipulated, setGoalStipulated] = useState();
  const [mesDescricao, setMesDescricao] = useState(["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"])
  const [formSearch, setFormSearch] = useState("Data Fim")
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [mesSelecionado, setMesSelecionado] = useState('Janeiro')
  const [anoSelecionado, setAnoSelecionado] = useState('2021')
  const [trimestreSelecionado, setTrimestreSelecionado] = useState('1º Trimestre')
  const [semestreSelecionado, setSemestreSelecionado] = useState('1º Semestre')
  const [loading, setLoading] = useState()
  const [statusChart, setStatusChart] = useState(true)

  /* Gerando anos automaticamente a partir de 2021 */
  let year = [];
  for (let i = 2021; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }

  /*Função chamada após o click no botão da lupa*/
  async function handleGetDashboard() {
    setLoading(true); // ativando o spinner de loading

    let numMes = 0;
    switch (mesSelecionado) {
      case "Janeiro":
        numMes = 1;
        break;
      case "Fevereiro":
        numMes = 2;
        break;
      case "Março":
        numMes = 3;
        break;
      case "Abril":
        numMes = 4;
        break;
      case "Maio":
        numMes = 5;
        break;
      case "Junho":
        numMes = 6;
        break;
      case "Julho":
        numMes = 7;
        break;
      case "Agosto":
        numMes = 8;
        break;
      case "Setembro":
        numMes = 9;
        break;
      case "Outubro":
        numMes = 10;
        break;
      case "Novembro":
        numMes = 11;
        break;
      case "Dezembro":
        numMes = 12;
        break;
      default:
        numMes = 1;
        break;
    }

    let _dateFrom = dateFrom.split("-")
    let _dateTo = dateTo.split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dashboardData = []
    let dateNow = new Date();
    let seletor = formSearch;

    if (seletor === "Data Fim") {

    } else if (seletor === "Mês") {
      newDateFrom = new Date(anoSelecionado, numMes - 1, 1, "0", "0", "0");
      dateNow.setMonth(numMes - 1);
      dateNow.setDate(1);
      let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");

    } else if (seletor === "Trimestre") {
      switch (trimestreSelecionado) {
        case ("1º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(0);
          let trimestre1 = new Date(anoSelecionado, dateNow.getMonth() + 4, 0);
          trimestre1.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre1.getMonth(), trimestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 3, 1, "0", "0", "0");
          dateNow.setMonth(4);
          let trimestre2 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre2.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre2.getMonth(), trimestre2.getDate() - 1, "23", "59", "59");
          break;
        case ("3º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let trimestre3 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre3.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre3.getMonth(), trimestre3.getDate() - 1, "23", "59", "59");
          break;
        case ("4º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 9, 1, "0", "0", "0");
          dateNow.setMonth(10);
          let trimestre4 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre4.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), trimestre4.getMonth(), trimestre4.getDate() - 1, "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(4);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
      }
    } else if (seletor === "Semestre") {
      switch (semestreSelecionado) {
        case ("1º Semestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let semestre1 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(anoSelecionado, semestre1.getMonth() - 1, semestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Semestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(13);
          let semestre2 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), semestre2.getMonth() - 1, semestre2.getDate(), "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
          break;
      }
    } else {
      newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
      newDateTo = new Date(anoSelecionado, 11, "31", "23", "59", "59");
    }

    await props.getOneUser(props.user, newDateFrom / 1000, newDateTo / 1000).then((val) => {
      dashboardData = val
    });

    /* Buscando o array com as missões formatadas */
    let missionFormatted = saveMonthNumber(dashboardData)

    /* testando se o usuario tem missão cadastrada */
    if (missionFormatted.length === 0) {
      setStatusChart(false);
    } else {
      setStatusChart(true);
    }

    /* Ajustando os valores alcançados meta de cada mês*/
    let goalsAchievedEveryMonth = savingGoalsAchievedMonth(missionFormatted)
    setMonthsValuesStipulated(goalsAchievedEveryMonth)

    /* Ajustando os valores estipulados de cada mês*/
    let goalsSetEachMonth = savingStipulatedGoalsMonth(missionFormatted)
    setMonthsValuesReached(goalsSetEachMonth)

    /*Salvando a meta do mes atual e o valor alcançado da meta no mes atual */
    saveCurrentAndStipulatedMonthlyTargetValue(missionFormatted)

    setLoading(false) // Desativando o spinner de loading
  }

  /* Função para retornar um array de objetos que contém o número de cada mês*/
  function saveMonthNumber(data) {
    let missions = [];
    data.missions.map((mission) => {
      let mouthNumber = new Date(mission.monthTimeStamp * 1000).getMonth() + 1
      missions.push({
        "monthTimeStamp": mission.monthTimeStamp,
        "sumTarget": mission.sumTarget,
        "target": mission.target,
        "month": mouthNumber,
      })
    })
    return missions
  }

  /* Salvando valores alcançados de metas ao decorrer dos meses */
  function savingGoalsAchievedMonth(data) {
    let aux = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    data.map((mission) => {
      aux[mission.month] = mission.sumTarget
    })
    return aux
  }

  /* Salvando valores estipulados de metas ao decorrer dos meses */
  function savingStipulatedGoalsMonth(data) {
    let aux = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    data.map((mission) => {
      aux[mission.month] = mission.target
    })
    return aux
  }

  /* Salvando valor alcançado e estipulado de meta para o mês atual */
  function saveCurrentAndStipulatedMonthlyTargetValue(data) {
    let aux = new Date();
    data.map((mission) => {
      if (mission.month === (aux.getMonth() + 1)) {
        setGoalReached(mission.sumTarget)
        setGoalStipulated(mission.target)
      }
    })
  }

  let UFs = props.companies.map(o => o.uf);

  return (
    <Row className="container-main">
      <Col>
        <div className="container-user-information">
          <Row style={{ "marginLeft": "0rem", "marginRight": "10rem", "marginTop": "0rem" }}>
            <Col
              xs={12}
              className="container-data"
            >
              <h4>Dados da conta</h4>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Nome:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info">
                {props.user.data.name}
              </p>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Email:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info">
                {props.user.data.email}
              </p>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Matrícula:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info">
                {props.user.data.userReg}
              </p>
            </Col>
          </Row>

          <Row style={{ "marginLeft": "10rem", "marginRight": "0rem" }}>
            <Col
              xs={12}
              className="container-data"
            >
              <h4>Dados pessoais</h4>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Setor:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info">
                {props.user.data.sector}
              </p>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Portais:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info" >
                {props.portals.map((portals) => {
                  if (props.user.data.portalId.includes(portals._id)) {
                    return (
                      ` ${portals.name} / `
                    )
                  }
                })}
              </p>
            </Col>
            <Col xs={3}>
              <p className="line-left-info">
                Estados cadastrados:
              </p>
            </Col>
            <Col xs={9}>
              <p className="line-right-info" >
                {UFs.map(uf => {
                  return (
                    ` ${uf} / `
                  )
                })}
              </p>
            </Col>
          </Row>
        </div>
      </Col>

      {/* <Col>
        <div className="container-goal-information">
          <Row>
            <Col className="container-search">
              <p className="container-search-title"><b>Metas do usuário</b></p>
              <InputGroup className="container-date">
                <DropdownButton
                  className="container-select-type"
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={formSearch}
                >
                  <Dropdown.Item id="option-select" onBlur={(e) => { setFormSearch(e.target.text) }}>Data Fim</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setFormSearch(e.target.text) }}>Mês</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setFormSearch(e.target.text) }}>Trimestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setFormSearch(e.target.text) }}>Semestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setFormSearch(e.target.text) }}>Ano</Dropdown.Item>
                </DropdownButton>
                <SearchInput
                  id="input-opo"
                  title="Data"
                  value={dateFrom}
                  selector="dataStart"
                  onChange={(e) => { setDateFrom(e.target.value) }}
                  show={formSearch === "Data"}
                />
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={mesSelecionado}
                  className={formSearch === "Mês" ? "type-month" : "display-none"}
                >
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Janeiro</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Fevereiro</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Março</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Abril</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Maio</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Junho</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Julho</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Agosto</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Setembro</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Outubro</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Novembro</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setMesSelecionado(e.target.text) }}>Dezembro</Dropdown.Item>
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={anoSelecionado}
                  className={formSearch === "Mês" ? "type-month" : "display-none"}
                >
                  {year.map((o) => {
                    return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                  })}
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={trimestreSelecionado}
                  className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
                >
                  <Dropdown.Item id="option-select" onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>1º Trimestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>2º Trimestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>3º Trimestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>4º Trimestre</Dropdown.Item>
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={anoSelecionado}
                  className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
                >
                  {year.map((o) => {
                    return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                  })}
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={semestreSelecionado}
                  className={formSearch === "Semestre" ? "type-semester" : "display-none"}
                >
                  <Dropdown.Item id="option-select" onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>1º Semestre</Dropdown.Item>
                  <Dropdown.Item id="option-select" onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>2º Semestre</Dropdown.Item>
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={anoSelecionado}
                  className={formSearch === "Semestre" ? "type-semester" : "display-none"}
                >
                  {year.map((o) => {
                    return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                  })}
                </DropdownButton>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={anoSelecionado}
                  className={formSearch === "Ano" ? "type-year" : "display-none"}
                >
                  {year.map((o) => {
                    return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                  })}
                </DropdownButton>
                <FormControl
                  placeholder="De"
                  aria-label="De"
                  type="date"
                  aria-describedby="basic-addon1"
                  value={dateFrom}
                  onChange={(e) => { setDateFrom(e.target.value) }}
                  className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
                />
                <FormControl
                  placeholder="Até"
                  aria-label="Até"
                  type="date"
                  aria-describedby="basic-addon1"
                  value={dateTo}
                  onChange={(e) => { setDateTo(e.target.value) }}
                  className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
                />
                <InputGroup.Append>
                  <Button variant="flat" onClick={handleGetDashboard}><FontAwesomeIcon className="" size="lg" icon={faSearch} /></Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">{(loading === true) ? <div className="lds-dual-ring m-4 "></div> : <></>}</Row>
          <div className={(statusChart === true) ? "display-none" : "message-user"}>
            <h6>O usuário logado não possui meta cadastrada</h6>
          </div>
          <RequestTwoLineChart
            className={(loading === true || loading === undefined || statusChart === false) ? "display-none" : " d-flex justify-content-center w-auto"}
            reached={monthsValuesReached}
            stipulated={monthsValuesStipulated}
            categories={mesDescricao}
            type="line"
            height={180}
            width="90%"
            title="Metas estipuladas ao decorrer dos meses"
          />
          <RequestProgressChart
            className={(loading === true || loading === undefined || statusChart === false) ? "display-none" : " d-flex justify-content-center"}
            title="Mês atual: Alcançado X Estipulado"
            reached={goalReached}
            stipulated={goalStipulated}
            height={200}
            width="95%"
          />
        </div>
      </Col> */}
    </Row >
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    portals: state.portals.portals,
    companies: state.companies.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(storeActions.getUser()),
    getOneUser: (usuario, dateFrom, dateTo) => dispatch(storeActions.getOneUser(usuario, dateFrom, dateTo)),
    getCompany: () => dispatch(storeActions.getCompany()),
    putUser: (user) => dispatch(storeActions.putUser(user)),
    putCompany: (company) => dispatch(storeActions.putCompany(company)),
    postUser: (user) => dispatch(storeActions.postUser(user)),
    postCompany: (company) => dispatch(storeActions.postCompany(company)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";


export const fetchingOpportunitiesStart = () => {
    return {
        type: actionTypes.FETCH_OPPORTUNITIES_START
    }
}

export const fetchOpportunitiesSuccess = (action) => {
    return {
        type: actionTypes.FETCH_OPPORTUNITIES_SUCCESS,
        opportunities: action,
    }
}

export const fetchOpportunitiesFailed = () => {
    return {
        type: actionTypes.FETCH_OPPORTUNITIES_FAILED
    }
}

export const initOpportunities = (usuario, portal) =>{
    return dispatch =>{
        dispatch(fetchingOpportunitiesStart());
        return new Promise((resolve, reject)=> {

            ServeServices.getOpportunities(usuario, portal)
            .then(response => {
                dispatch(fetchOpportunitiesSuccess(response));
                resolve(response);
            })
            .catch((err) =>{
                localStorage.removeItem("reduxStore");
                localStorage.removeItem("private");
                localStorage.removeItem("dataFetched");
                window.location.reload();
                dispatch(fetchOpportunitiesFailed());
                reject(err);
            })

        })
    }
}
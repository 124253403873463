import React from "react";
import "./LoadingResources.css";
import LoadingSpinner from "../../Loading/Spinner";
import { Button } from "react-bootstrap";

export default function LoadingResources(props) {
  function resetSystem() {
    localStorage.removeItem("reduxStore");
    localStorage.removeItem("private");
    localStorage.removeItem("dataFetched");
    window.location.reload();
  }

  return (
    <div className="loading-resources-backdrop">
      <div className="loading-resources-main d-flex flex-column justify-content-center align-items-center p-2">
        <p>Um erro interno ocorreu no Libo.Di</p>
        <p>Clique no botão abaixo para reiniciar o sistema</p>
        <Button onClick={() => resetSystem()}>Reiniciar</Button>
      </div>
    </div>
  );
}

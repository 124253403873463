import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";

export default function Filter(props) {
  const [dayOpps, setDayOpps] = useState(localStorage.getItem("dayOpps") ? localStorage.getItem("dayOpps") : []);
  const [modalFullDecline, setModalFullDecline] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState({});
  const [note, setNote] = useState("Oportunidades não filtradas. O declínio será feito em massa.");
  const [declineStatus, setDeclineStatus] = useState({});
  const [justificationMessage, setJustificationMessage] = useState("Nós agradecemos, mas declinamos desta oportunidade");
  const [ensureDecline, setEnsureDecline] = useState(false);
  const [barPercentage, setBarPercentage] = useState(0);
  const [done, setDone] = useState(false);

  let dictionary = {}
  props.data.map(item => {
    dictionary[item.dateEnd.split(" ")[0]] = !!dictionary[item.dateEnd.split(" ")[0]] ? dictionary[item.dateEnd.split(" ")[0]].concat(item) : [item];
  })

  useEffect(() => {
    if (modalFullDecline) {
      props.initStatusList(props.user).then(value => {
        setStatusList(value);
      })
    }
  }, [statusList.length === 0 && modalFullDecline === true]);

  async function setDecline(opData) {
    const result = await props.postDeclineOpportunity({
      "index": opData.index,
      "reason": justificationMessage,
      "declined": false,
      "declineQueue": true,
      "opportunityId": opData._id,
      "dateEnd": opData.dateEnd,
      "declineStatusId": declineStatus._id,
      "companyId": opData.companyId,
      "portalId": opData.portalId,
      "failed": false,
    }, props.user);

    // console.log(result);

    // console.log({
    //   "index": opData.index,
    //   "reason": justificationMessage,
    //   "declined": false,
    //   "declineQueue": true,
    //   "opportunityId": opData._id,
    //   "dateEnd": opData.dateEnd,
    //   "declineStatusId": declineStatus._id,
    //   "companyId": opData.companyId,
    //   "portalId": opData.portalId,
    //   "failed": false,
    // })
  }

  async function createStatus(opData) {

    // console.log({
    //   "_id": null,
    //   "index": opData.index,
    //   "note": note,
    //   "dateEnd": opData.dateEnd,
    //   "statusListId": selectedStatusList._id,
    //   "userId": props.user.data._id,
    //   "opportunityId": opData._id,
    //   "portalId": opData.portalId,
    //   "companyId": props.user.data.companyId
    // })
    const result = await props.setOpportunityStatus(props.user, {
      "_id": null,
      "index": opData.index,
      "note": note,
      "dateEnd": opData.dateEnd,
      "statusListId": selectedStatusList._id,
      "userId": props.user.data._id,
      "opportunityId": opData._id,
      "portalId": opData.portalId,
      "companyId": props.user.data.companyId
    });

    // console.log(result);
  }

  // FUNÇÃO SUICIDA. USE COM CAUTELA
  const massDecline = (e) => {
    e.preventDefault();

    console.log("Teste")
    if(props.user.data?.presentation === true){
      alert("No modo apresentação, não é possível fazer esta ação.");

    }else if(!selectedStatusList?.name){
      alert("Selecione o status");

    }else if(!declineStatus?.description){
      console.log(!declineStatus?.description)
      alert("Selecione a justificativa para o Petronect");
    }else{
      // let elegibleDeclineOpps = props.rejectableOpps.filter(opp => opp.status === "Não criada" && opp.viewed === false && opp.localStatus.length === 0);
      let elegibleDeclineOpps = props.rejectableOpps.filter(opp => opp.status === "Não criada" && opp.localStatus.length === 0);
      // console.log("Controle: ", elegibleDeclineOpps); // USE ISTO PARA CONTROLE

      if (!!selectedStatusList?._id) {
        if (!!declineStatus) {
          elegibleDeclineOpps.map(async (o, idx) => {
            await createStatus(o);
            await setDecline(o);
            setBarPercentage(parseInt((idx / elegibleDeclineOpps.length) * 100));
          })

          // console.log(elegibleDeclineOpps.length, props.rejectableOpps.length, props);
          setDone(true);
        } else {
          alert("Selecione a ação!");
        }
      } else {
        alert("Selecione o motivo do declínio!");
      }
    }
  }

  const resetModal = () => {
    setSelectedStatusList({});
    setDeclineStatus({});
    setEnsureDecline(false);
    setBarPercentage(0);
    setModalFullDecline(false);
    setDone(false);
  }

  // setListDayOpps(Object.keys(dictionary))

  // console.log(props.rejectableOpps, props.showRejectable, props.declineStatus)
  return (
    <div className="d-flex flex-row-reverse mb-4 mr-2">
      <div className="input-group mb-2 mr-sm-2">
        <div className="input-group-prepend">
          <div className="input-group-text">Selecione o Data Fim: </div>
        </div>
        <select value={dayOpps} onChange={(e) => { localStorage.setItem("dayOpps", e.target.value); setDayOpps(e.target.value); props.setDataSlice(e.target.value, props.opportunityWithManufacturer) }}>
          <option value={[]}>Todas</option>
          {Object.keys(dictionary).map(el => {
            return (<option value={el}>{el}</option>)
          })}
        </select>
      </div>

      {props.showRejectable ? (<button type="button" className="btn btn-danger mr-4" onClick={() => setModalFullDecline(true)}><FontAwesomeIcon size="lg" icon={faSortAmountDownAlt} /></button>) : <></>}

      <Modal show={modalFullDecline} onHide={() => resetModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Declínio de Oportunidades não Filtradas</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="col-auto">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Status</div>
              </div>
              <select className="form-control" type="text" onChange={
                (e) => {
                  setSelectedStatusList(statusList.find(o => o._id === e.target.value));
                  // console.log(statusList.find(o => o._id === e.target.value))
                  // setIsEdited(true);
                  // console.log(statusList.find(o => o._id === e.target.value))
                }}
                value={selectedStatusList._id}>
                <option disabled={true} selected={true}>Selecione a opção</option>
                {statusList.map(o => {
                  return (<option value={o._id}>{o.name}</option>)
                })}
              </select>
            </div>

            <h4 className="mt-4">Anotação/Justificativa: </h4>
            <textarea onChange={(e) => { setNote(e.target.value) }} class="form-control" value={note} rows="3"></textarea>

            <hr />

            <h4 className="mt-2">Justificativa do declínio:</h4>
            <select onChange={(e) => { setDeclineStatus(props.declineStatus.find(probe => probe._id === e.target.value)) }} value={declineStatus._id} className="form-control">
              <option disabled={true} selected={true}>Selecione o motivo</option>
              {props.declineStatus.map((status) => {
                return (<option value={status._id}>{status.description}</option>)
              })}
            </select>

            <hr />

            <h3>Mensagem:</h3>
            <input type="text" value={justificationMessage} onChange={(e) => { setJustificationMessage(e.target.value) }} className="form-control" />
            <h6 className="mt-4"><b>*Apenas oportunidades não visualizadas, sem ação e de status "Não criada" serão declinadas!</b></h6>

            <div className="progress mt-3">
              <div className="progress-bar" role="progressbar" style={{ width: barPercentage.toString() + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div style={{ display: (done ? "block" : "none") }} className="alert alert-success mt-4" role="alert">
              Feito!
            </div>

          </div>

        </Modal.Body>
        <Modal.Footer className="d-flex p-3">
          <div className="form-check mr-auto">
            <input disabled={(done)} type="checkbox" checked={ensureDecline} onChange={() => { setEnsureDecline(!ensureDecline) }} className="form-check-input" />
            <label className="form-check-label">Confirmar Declínio em Massa</label>
          </div>
          <div className="">
            <Button disabled={!ensureDecline || done} className="mr-4" variant="danger" onClick={(e) => massDecline(e)}>
              Declinar
            </Button>
            <Button variant="primary" onClick={() => resetModal()}>
              Fechar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

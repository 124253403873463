import React, { useState, useEffect } from "react";
import { Alert, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faDownload, faSearch, faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import ServeServices from "../../../ServeServices";
import Pagination from "./Pagination/RankingPagination";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import "./DataTableRanking.scss";
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import RequestBarChart from "../../pages/Ranking/Charts/RequestBarChart";

const sortArrayOfObjects = (array, selector, direction) => {
  if (direction === "ASC") {
    array.sort(function (a, b) {
      if (
        a[selector].toString().toLowerCase() >
        b[selector].toString().toLowerCase()
      ) {
        return 1;
      }
      if (
        a[selector].toString().toLowerCase() <
        b[selector].toString().toLowerCase()
      ) {
        return -1;
      }
      return 0;
    });
  } else if (direction === "DES") {
    array.sort(function (a, b) {
      if (
        a[selector].toString().toLowerCase() <
        b[selector].toString().toLowerCase()
      ) {
        return 1;
      }
      if (
        a[selector].toString().toLowerCase() >
        b[selector].toString().toLowerCase()
      ) {
        return -1;
      }
      return 0;
    });
  }
};

const downloadTable = (prepare) => {
  let fileName = "";

  let formData = prepare.map(item => {
    let manufacturers = item.match?.map(o => o.manufacturer)?.join(",")
    let formatterBRL = new Intl.NumberFormat('pt-BR');
    let localStatus = item.localStatus.length > 0 ? item.localStatus[0].statusName.name : "Sem Status";
    let note = item.localStatus.length > 0 ? item.localStatus[0].note : "Sem comentários";

    fileName = item.origin;
    return {
      "Número": item.index,
      "Descrição": item.description,
      "Data Início": item.dateStart,
      "Data Fim": item.dateEnd,
      "Origem": item.origin,
      "Status": item.status,
      "Total Cotado": formatterBRL.format(item?.totalPrice),
      "Visto": item.active ? "Sim" : "Não",
      "Declinado": item.declined ? "Sim" : "Não",
      "Status Interno": localStatus,
      "Comentário": note,
      "Fabricantes": manufacturers
    }
  })

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(formData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataToDownload = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataToDownload, fileName + fileExtension);
}

export default function DataTable({
  title,
  data,
  columns,
  dataType,
  callback,
  pagination,
  filter,
  fabricantes,
  tableData,
  carregaFabricanteEscolhido,
  initProposalData,
  rangeOfMonths,
  anoSelecionado,
  dataTimestamp,
  user,
  currentCompany,
  tableRender
}) {
  const [itemsShow, setItemsShow] = useState((localStorage.getItem("itemsShowRanking") === null) ? 0 : localStorage.getItem("itemsShowRanking"));
  const [selectedPage, setSelectedPage] = useState(null);
  const [manufacturerSelected, setManufacturerSelected] = useState(null);
  const [monthSelected, setMonthSelected] = useState(null);
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortAscDesc, setSortAscDesc] = useState("");
  // const [manufacturerNames, setManufacturerNames] = useState([])
  const [optionsFabricante, setOptionsFabricante] = useState([])
  const [opTable, setOpTable] = useState([])
  const [optionsMonth, setOptionsMonth] = useState([])
  const [statusChart, setStatusChart] = useState(false)
  const [dataBar, setDataBar] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [dataDownload, setDataDownload] = useState(null)
  const [actualDataTable, setActualDataTable] = useState([])
  const [itemsDescriptions, setItemsDescriptions] = useState([])
  const [selectedTable, setSelectedTable] = useState([])
  const [loading, setLoading] = useState(false)
  const [legendQuoted, setLegendQuoted] = useState(0)
  const [legendPresented, setLegendPresented] = useState(0)
  const [messageError, setMessageError] = useState(null)
  const [messageProposalsError, setMessageProposalsError] = useState(null)
  const [disabledSelectMonth, setDisabledSelectMonth] = useState(true)
  const [disabledSearch, setDisabledSearch] = useState(true)
  const [haveProposal, setHaveProposal] = useState(false)
  if (!data) {
    data = [];
  }

  useEffect(() => {

    if (localStorage.getItem("selectedPageRanking") === null) {
      setSelectedPage(0)
    } else {
      setSelectedPage(localStorage.getItem("selectedPageRanking"))
    }

  }, [localStorage.getItem("selectedPageRanking")])

  // CONTINUAR
  useEffect(() => {
    carregaFabricanteEscolhido([]);
    // setOptionsFabricante(manufacturerNames)
    setOptionsMonth(monthOptions)

    let filtering = []

    data.map(obj => {
      if(haveProposal === true && obj.proposta === true){
        filtering.push(obj)
      }else if(haveProposal === false){
        filtering.push(obj)
      }
    })

    setOpTable(filtering)
  }, [data.length > 0, tableRender === true, haveProposal])

  const options = [10, 15, 20, 25, 30, 50, 100];
  let numberOfPages;
  const requiredPages = opTable.length / options[itemsShow];

  if (requiredPages % 1 !== 0) {
    if (requiredPages % 1 >= 5) {
      numberOfPages = Math.floor(requiredPages);
    } else {
      numberOfPages = Math.floor(requiredPages) + 1;
    }
  } else {
    numberOfPages = requiredPages;
  }

  const selectors = columns.map((column) => {
    if (column["selector"] == "_Status") {
      return "status"
    } else {
      return column["selector"]
    }
  });
  const overlays = columns.map((column) => {
    if (column["overlay"] === true) {
      return column["selector"];
    }
  });
  const sliceIndex = Number(selectedPage) * options[itemsShow];
  let filteredData = [];
  let foundId = [];
  let aux = 0;
  let dataSorted = [...opTable];


  if (sortIndex >= 0) {
    sortArrayOfObjects(dataSorted, selectors[sortIndex], sortAscDesc);
  }

  const dataToShow = dataSorted.slice(
    sliceIndex,
    sliceIndex + options[itemsShow]
  );

  const handleSortFilterSelect = (index) => {
    if (index === -1 || index !== sortIndex) {
      setSortIndex(index);
      setSortAscDesc("DES");
    } else if (index === sortIndex && sortAscDesc === "DES") {
      setSortAscDesc("ASC");
    } else if (index === sortIndex && sortAscDesc === "ASC") {
      setSortIndex(-1);
      setSortAscDesc("");
    }
  };

  const tableHeader = columns.map((column, index) => {
    let activeFilter = "";
    if (sortIndex === index) {
      activeFilter = "show ";
      if (sortAscDesc === "DES") {
        activeFilter += " arrow-up";
      } else {
        activeFilter += " arrow-down";
      }
    }
    return (
      <th className="datatable-header-col" key={`${column}-${index}`}>
        <div
          className="d-flex flex-row"
          onClick={() => {
            handleSortFilterSelect(index);
          }}
        >
          <p>
            {column.name}
          </p>
          <i className={"arrow-up ml-2 " + activeFilter}></i>
        </div>
      </th>
    );
  });
  const tableBody = dataToShow.map((row, index) => {
    const rowClass =
      index % 2 === 0 ? "datatable-row-even" : "datatable-row-odd";

    let active = row.active ? null : "unviewed";

    return (
      <tr
        onClick={() => callback ? callback(row) : null}
        className={"datatable-row " + rowClass}
        key={`${row}-${index}`}
      >
        {selectors.map((col) => {
          if (col === "anexo") {
            return (
              <th className={"datatable-row-col"} key={`${row}-${col}-${index}`}>
                <FontAwesomeIcon size="lg" icon={faFileDownload} onClick={async () => {
                  if (Array.isArray(row.files)) {
                    row.files.map(obj => { DownloadFileAsync(obj, row.fileId) })
                  } else {
                    row.files.split(',').map(obj => { DownloadFileAsync(obj, row.fileId) })
                  }

                }} />
              </th>
            )

          } else {
            return (
              < th className={"datatable-row-col " + active} key={`${row}-${col}-${index}`}>
                {row[col]}
              </th>
            )
          }
        })
        }
      </tr >
    );
  });

  const onPageChanged = (index) => {
    if (index < 1) {
      index = 0;
    } else if (index >= numberOfPages - 1) {
      index = numberOfPages - 1;
    }
    localStorage.setItem("selectedPageRanking", index);
    setSelectedPage(index);
  };

  const onItemsShowChanged = (index) => {

    localStorage.setItem("itemsShowRanking", index);
    setItemsShow(index);

    localStorage.setItem("selectedPageRanking", 0);
    setSelectedPage(0);
  };

  //Salvando o nome dos fabricantes
  // Aperfeiçoar
  // const manufacturerNames = fabricantes.find(item => item.name === "Fabricante")?.category.map(item => {
  //   return { "value": item.name, "label": item.name }
  // })

  //Salvando os meses disponíveis
  const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
  let monthFrom = new Date(rangeOfMonths.dateFrom * 1000).getMonth()
  let monthTo = new Date(rangeOfMonths.dateTo * 1000).getMonth()

  const monthOptions = [];
  for (let i = monthFrom; i <= monthTo; i++) {
    monthOptions.push({
      "value": i,
      "label": months[i]
    })
  }

  let Filters;
  let PaginationContent;

  /* DESATIVADO */
  // let TitleContent;
  // if (title) {
  //   TitleContent = (
  //     <Col xs={12}>
  //       <h1 className="datatable-title mt-3">{title} {">"} {categorySelected} {">"} {mainManufacturer}</h1>
  //     </Col>
  //   );
  // }


  if (filter) {
    Filters = (
      <Row className={tableData.length === 0 ? "display-none" : "container-select mt-2"}>
        <button
          onClick={() => { setHaveProposal(!haveProposal) }}
          className={(haveProposal === true) ? "btn text-flat proposalToggleWIdth" : "btn text-danger proposalToggleWIdth"}
        >
          <FontAwesomeIcon
            size="lg"
            icon={(haveProposal === true) ? faToggleOn : faToggleOff}
          />
          &nbsp;Com propostas&nbsp;
        </button>
        <Select
          className="select-manufacturers"
          onChange={(e) => {
            setOptionsFabricante(fabricantes.find(probe => probe.name === e.value).category.map(item => {
              return { "value": item.name, "label": item.name }
            })); setDisabledSelectMonth(false)
          }}
          options={fabricantes.map(item => {
            return { "value": item.name, "label": item.name }
          })}
          placeholder={"Selecione a Categoria"}
        />
        <Select
          className="select-manufacturers"
          onChange={(e) => { setManufacturerSelected(e.value); setDisabledSelectMonth(false) }}
          options={optionsFabricante}
          placeholder={"Selecione o Fabricante"}
        />
        <Select
          className="select-month"
          onChange={(e) => { setMonthSelected(e.value); setDisabledSearch(false) }}
          options={optionsMonth}
          placeholder={"Selecione o Mês"}
          isDisabled={disabledSelectMonth}
        />
        <Button
          variant="flat"
          onClick={fillChart}
          disabled={disabledSearch}
        >
          <FontAwesomeIcon size="lg" icon={faSearch} />
        </Button>
        {/* Sub-filtro (Filtre os campos) ** DESATIVADO ** */}
        {/* <Filter
          filters={appliedFilters}
          onFilterEnter={handleFilterAdding}
          onFilterRemove={handleFilterRemoving}
        /> */}
      </Row >
    );
  }

  if (pagination) {
    PaginationContent = (
      <Col className="d-flex flex-row justify-content-end align-content-center mt-3 mr-3">
        <Pagination
          title={dataType !== undefined ? dataType : title}
          itens={opTable.length}
          options={options}
          selectedPage={selectedPage}
          selectedOption={itemsShow}
          onPageChanged={onPageChanged}
          onItemsShowChanged={onItemsShowChanged}
          pagesNumber={numberOfPages}
          downloadTable={downloadTable}
          dataToShow={dataToShow}
          tableData={tableData}
        />
      </Col>
    );
  }

  function fillChart() {
    setStatusChart(true)
    setSelectedTable([])
    setSelectedPage(0) //resetando pagina que op usuário parou na ultima navegação

    carregaFabricanteEscolhido(manufacturerSelected)

    let _dateFrom = " ".split("-")
    let _dateTo = " ".split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dateNow = new Date();

    newDateFrom = new Date(anoSelecionado, monthSelected, 1, "0", "0", "0");
    dateNow.setMonth(monthSelected);
    dateNow.setDate(1);
    let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
    newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");

    let data = [];
    let dataOpp = [];
    dataTimestamp.map(opportunity => {
      if (opportunity.match.length > 0 && opportunity.dateEnd >= (newDateFrom / 1000) && opportunity.dateEnd <= (newDateTo / 1000)) {
        opportunity.match.map(match => {
          if (match.manufacturer === manufacturerSelected) {
            if (!dataOpp.includes(opportunity)) {
              dataOpp.push(opportunity)
            }
            match.items.map(items => {
              data.push(items)
            })
          }
        })
      }
    })

    let dateFixedOpps = dataOpp.map(obj => {
      let objDateEnd = new Date(obj.dateEnd * 1000);
      let objDateStart = new Date(obj.dateStart * 1000);
      obj["dateEnd"] = (objDateEnd.getDate() <= 9 ? "0" + String(objDateEnd.getDate()) : objDateEnd.getDate()) + "/" +
        (objDateEnd.getMonth() + 1 <= 9 ? "0" + String(objDateEnd.getMonth() + 1) : objDateEnd.getMonth() + 1) + "/" + objDateEnd.getFullYear();

      obj["dateStart"] = (objDateStart.getDate() <= 9 ? "0" + String(objDateStart.getDate()) : objDateStart.getDate()) + "/" +
        (objDateStart.getMonth() + 1 <= 9 ? "0" + String(objDateStart.getMonth() + 1) : objDateStart.getMonth() + 1) + "/" + objDateStart.getFullYear();
      return obj
    })

    setOpTable(dateFixedOpps);

    data.sort(function (a, b) {
      if (a > b) {
        return true;
      } else {
        return -1;
      }
    })

    let controlItem = []
    let controlQuantity = 1
    let quantityForItem = []

    for (let i = 0; i < data.length; i++) {
      if (controlItem.includes(data[i])) {
        controlQuantity++
      } else {
        if (controlItem.length > 0) {
          quantityForItem.push({
            description: data[i - 1],
            quantity: controlQuantity
          })
        }

        controlItem.push(data[i])
        controlQuantity = 1
      }

      if (i === (data.length - 1)) {
        quantityForItem.push({
          description: data[i],
          quantity: controlQuantity
        })
      }
    }
    setDataBar(quantityForItem)

    if (quantityForItem.length === 0) {
      setMessageError(true)
    } else {
      setMessageError(false)
      let indexOpportunities = [];
      dataOpp.map(opportunity => {
        if (opportunity.proposalId !== "") {
          indexOpportunities.push(opportunity.index)
        }
      })
      getProposals(indexOpportunities)

      if (indexOpportunities.length === 0) {
        setMessageProposalsError(true)
      } else {
        setMessageProposalsError(false)
      }
    }
  }

  async function getProposals(index) {
    setLoading(true)

    let proposals;
    await initProposalData(user, index).then((val) => {
      proposals = val
    });
    setDataTable(proposals)

    //classificando os dados da tabela
    let dataPorItem = {}
    proposals.map(prop => {
      if (dataPorItem[prop["itemDescription"]] === undefined) {
        dataPorItem[prop["itemDescription"]] = []
      }
      dataPorItem[prop["itemDescription"]].push(prop)
    })

    //ordenando o objeto 'dataPorItem'
    let dataPorItemClassificado = {};
    for (let prop in dataPorItem) {
      let empresa = dataPorItem[prop].find((item) => item.corporateName === currentCompany)
      let newProp = dataPorItem[prop].filter((item) => item.corporateName !== currentCompany)
      if (dataPorItemClassificado[prop] === undefined) {
        dataPorItemClassificado[prop] = []
      }
      let teste = newProp.sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (empresa !== undefined) {
        teste.unshift(empresa)
      }
      dataPorItemClassificado[prop] = teste
    }

    //Formatando o objeto como array
    let dataPorItemCompleto = []
    for (var prop in dataPorItemClassificado) {
      for (let i = 0; i < dataPorItemClassificado[prop].length; i++) {
        dataPorItemCompleto.push({
          "index": dataPorItemClassificado[prop][i].index,
          "dateEnd": dataPorItemClassificado[prop][i].dateEnd,
          "corporateName": dataPorItemClassificado[prop][i].corporateName,
          "currency": dataPorItemClassificado[prop][i].currency,
          "itemDescription": dataPorItemClassificado[prop][i].itemDescription,
          "itemNumber": dataPorItemClassificado[prop][i].itemNumber,
          "itemPrice": dataPorItemClassificado[prop][i].itemPrice,
          "proposalNumber": dataPorItemClassificado[prop][i].proposalNumber,
          "quantity": dataPorItemClassificado[prop][i].quantity,
          "taxCode": dataPorItemClassificado[prop][i].taxCode,
          "totalPrice": dataPorItemClassificado[prop][i].totalPrice,
          "um": dataPorItemClassificado[prop][i].um
        })
      }
    }
    setDataDownload(dataPorItemCompleto)
    setActualDataTable(dataPorItemCompleto)

    let descriptionsTable = []
    proposals.map(prop => {
      if (!descriptionsTable.includes(prop.itemDescription)) {
        descriptionsTable.push(prop.itemDescription)
      }
    })

    setItemsDescriptions(descriptionsTable)

    setLoading(false)
  }

  // função de filtro de item da tabela com dados das propostas
  function itemSelectTable(e) {
    setSelectedTable(e.target.value)
    let dataTableAux = []
    dataTable.map(item => {
      if (item.itemDescription === e.target.value) {
        dataTableAux.push(item)
      }
    })

    //classificando os dados da tabela
    let dataPorItem = {}
    dataTableAux.map(prop => {
      if (dataPorItem[prop["itemDescription"]] === undefined) {
        dataPorItem[prop["itemDescription"]] = []
      }
      dataPorItem[prop["itemDescription"]].push(prop)
    })

    //ordenando o objeto 'dataPorItem'
    let dataPorItemClassificado = {};
    for (let prop in dataPorItem) {
      let empresa = dataPorItem[prop].find((item) => item.corporateName === currentCompany)
      let newProp = dataPorItem[prop].filter((item) => item.corporateName !== currentCompany)
      if (dataPorItemClassificado[prop] === undefined) {
        dataPorItemClassificado[prop] = []
      }
      let teste = newProp.sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (empresa !== undefined) {
        teste.unshift(empresa)
      }
      dataPorItemClassificado[prop] = teste
    }

    //Formatando o objeto como array
    let dataPorItemCompleto = []
    for (var prop in dataPorItemClassificado) {
      for (let i = 0; i < dataPorItemClassificado[prop].length; i++) {
        dataPorItemCompleto.push({
          "index": dataPorItemClassificado[prop][i].index,
          // "dateEnd": dataPorItemClassificado[prop][i].dateEnd,
          "corporateName": dataPorItemClassificado[prop][i].corporateName,
          "currency": dataPorItemClassificado[prop][i].currency,
          "itemDescription": dataPorItemClassificado[prop][i].itemDescription,
          "itemNumber": dataPorItemClassificado[prop][i].itemNumber,
          "itemPrice": dataPorItemClassificado[prop][i].itemPrice,
          "proposalNumber": dataPorItemClassificado[prop][i].proposalNumber,
          "quantity": dataPorItemClassificado[prop][i].quantity,
          "taxCode": dataPorItemClassificado[prop][i].taxCode,
          "totalPrice": dataPorItemClassificado[prop][i].totalPrice,
          "um": dataPorItemClassificado[prop][i].um
        })
      }
    }
    setActualDataTable(dataPorItemCompleto)
  }

  function downloadTableProposals(e) {
    e.preventDefault();

    let formData = dataDownload.map(prop => {
      // let dateEnd = new Date(prop.dateEnd * 1000)
      return {
        "Oportunidade": prop.index,
        "Nome da empresa": prop.corporateName,
        "Descrição do item": prop.itemDescription,
        "Valor unitário": prop.itemPrice === null ? 0 : prop.itemPrice,
        "Quantidade": prop.quantity,
        "Valor total": prop.totalPrice,
        "Moeda": prop.currency,
        // "Data final": dateEnd.getDate() + "/" + (dateEnd.getMonth() + 1) + "/" + dateEnd.getFullYear(),
        "Número da proposta": prop.proposalNumber,
        "Código de Imposto": prop.taxCode,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = { Sheets: { 'Dados das propostas': ws }, SheetNames: ['Dados das propostas'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Propostas - ${manufacturerSelected} ` + ".xlsx");
  }

  const DownloadFileAsync = async (filename, fileid) => {
    const link = await ServeServices.getOpportunityFile(user, filename, fileid);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link.data.link;
    a.target = "_blank";
    a.download = filename;
    a.click();
  }

  return (
    <>
      <Row className="w-100 h-100">
        {/* {TitleContent} */} {/* Legenda das opções selecionadas para exibição da tabela **DESATIVADO**/}
        {Filters} {/* Filtro por fabricante */}

        <Col xs={12} className="w-100 datatable-outer">
          <table className="w-100 datable-main">
            <thead>
              <tr className="datable-head-row">{tableHeader}</tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </Col>
        {PaginationContent}
        <div className="container-spinner">{(loading === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</div>
      </Row>
      {/* <Row className={statusChart === true && !loading && messageError === false ? "" : "display-none"}>
        <RequestBarChart
          className="d-flex justify-content-center bar-chart-ranking"
          data={dataBar.map((data) => { return data.quantity })}
          categories={dataBar.map((data) => { return data.description })}
          name="Quantidade"
          type="bar"
          title="Quantidade de oportunidades por descrição"
          height={400}
          width="100%"
        />
      </Row> */}
      <br></br>
      <Alert className={!loading && messageError !== false && messageError !== null ? "alert-control-decline" : "display-none"} variant="warning">
        <Alert.Heading>Itens não encontrados!</Alert.Heading>
        <p>
          Não foi possível encontrar os itens específicos do fabricante selecionado.
        </p>
      </Alert>
      <Row className={statusChart === true && !loading && messageError === false && messageProposalsError === false ? "d-flex flex-row justify-content-center align-content-center" : "display-none"}>
        <Table striped bordered hover className="table-ranking">
          <thead>
            <tr>
              <th>Razão social</th>
              <th>
                Descrição do item
                <select onChange={(e) => { itemSelectTable(e) }} value={selectedTable} className="select-item-ranking">
                  <option className={selectedTable !== "" ? "display-none" : ""}> - </option>
                  {itemsDescriptions.map(item => {
                    return (<option value={item}>{item}</option>)
                  })}
                </select>
              </th>
              <th>Valor Item</th>
              <th>Quantidade</th>
              <th>Unidade de medida</th>
              <th>Valor Total</th>
              <th>Moeda</th>
              <th>Código Fiscal</th>
            </tr>
          </thead>
          <tbody>
            {actualDataTable
              .map(item => {
                return (<>
                  <tr>
                    <td><small>{item?.corporateName}</small></td>
                    <td><small>{item?.itemDescription}</small></td>
                    <td><small>{item.itemPrice === null ? 0 : item.itemPrice}</small></td>
                    <td><small>{item?.quantity}</small></td>
                    <td><small>{item?.um}</small></td>
                    <td><small>{item?.totalPrice}</small></td>
                    <td><small>{item?.currency}</small></td>
                    <td><small>{item?.taxCode}</small></td>
                  </tr>
                </>)
              })}
          </tbody>
        </Table>
      </Row>
      <Alert className={!loading && messageProposalsError !== false && messageProposalsError !== null ? "alert-control-decline" : "display-none"} variant="warning">
        <Alert.Heading>Propostas não encontrados!</Alert.Heading>
        <p>
          Não foi possível encontrar propostas para o fabricante selecionado neste período de tempo.
        </p>
      </Alert>
      <Row className={statusChart === true && !loading && messageError === false ? "pt-3 d-flex flex-row justify-content-center align-content-center" : "display-none"}>
        <b>Baixar Propostas</b>
      </Row>
      <Row className={statusChart === true && !loading && messageError === false ? "p-3 d-flex flex-row justify-content-center align-content-center" : "display-none"}>
        <Button variant="flat" onClick={(e) => downloadTableProposals(e)}>
          <FontAwesomeIcon size="lg" icon={faDownload} />
        </Button>
      </Row>
      {/* <div className={statusChart === true && !loading && messageError === false ? "container-legenda-ranking" : "display-none"}>
        <h5 className="legenda-ranking-quoted">Valor total cotado para o Fabricante selecionado: {legendQuoted}</h5>
        <h5 className="legenda-ranking-presented">Valor total efetivado para o Fabricante selecionado: {legendPresented}</h5>
      </div> */}
    </>
  );
}
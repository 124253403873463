import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import "./Pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function Pagination({
  title,
  itens,
  options,
  selectedPage,
  selectedOption,
  onPageChanged,
  onItemsShowChanged,
  pagesNumber,
  downloadTable,
  dataToShow,
  tableData
}) {
  const optionsComponents = options.map((el, index) => (
    <Dropdown.Item key={el} onClick={() => {
      onItemsShowChanged(index);
    }}>
      {el}
    </Dropdown.Item>
  ));

  const getItensShow = () => {
    let maxShowed = (Number(selectedPage) + 1) * options[selectedOption];
    maxShowed = maxShowed > itens ? itens : maxShowed;

    return `${Number(selectedPage) * options[selectedOption] + 1
      }-${maxShowed} de ${itens}`;
  };

  return (
    <div className="d-flex justify-content-end align-items-center mb-2 dropdown-pagination">
      <p onClick={() => { downloadTable(dataToShow) }} className="datatable-pagination-text ml-4 mr-4 dl-button">Baixar página <FontAwesomeIcon size="lg" icon={faDownload} /></p>
      <p onClick={() => { downloadTable(tableData) }} className="datatable-pagination-text ml-4 mr-4 dl-button">Baixar tudo <FontAwesomeIcon size="lg" icon={faDownload} /></p>
      <p className="datatable-pagination-text">{title} por página</p>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle id="dropdown-custom-1">
          {options[selectedOption]}
        </Dropdown.Toggle>
        <Dropdown.Menu>{optionsComponents}</Dropdown.Menu>
      </Dropdown>
      <p className="datatable-pagination-text ml-4 mr-4">{getItensShow()}</p>
      <div className="d-flex flex-row align-items-center">
        <button
          className="pagination-btn-control"
          onClick={() => onPageChanged(0)}
        >
          <div className="left-arrow-line"></div>
        </button>
        <button
          className="pagination-btn-control"
          onClick={() => onPageChanged(Number(selectedPage) - 1)}
        >
          <div className="left-arrow"></div>
        </button>
        <button
          className="pagination-btn-control"
          onClick={() => onPageChanged(Number(selectedPage) + 1)}
        >
          <div className="right-arrow"></div>
        </button>
        <button
          className="pagination-btn-control"
          onClick={() => onPageChanged(pagesNumber - 1)}
        >
          <div className="right-arrow-line"></div>
        </button>
      </div>
    </div>
  );
}

export default Pagination;

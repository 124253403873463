import * as actionTypes from "./actionTypes";
import ServerServices from "../../ServeServices";
import * as actionStores from "../actions/index";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user: authData,
  };
};

export const authFail = () => {
  return {
    type: actionTypes.AUTH_FAIL,
  };
};

export const notify = (notification) => {
  return {
    type: actionTypes.NOTIFICATION_CREATE,
    notification,
  };
};

export const authenticate = (userData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(authStart());

      ServerServices.signIn(userData)
        .then((response) => {
          dispatch(authSuccess(response));

          dispatch(
            actionStores.notify(
              {
                id: "login-message",
                header: "",
                content: `Seja bem vindo, ${response.data.name}`,
                type: "success",
              },
              5000
            )
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(authFail());

          dispatch(
            actionStores.notify(
              {
                id: "error-message",
                header: "Credenciais",
                content: "E-mail ou senha inválidos",
                type: "error",
              },
              5000
            )
          );
          reject(error);
        });
    });
  };
};

export const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START,
  };
};

export const signupSucess = (signupData) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    user: signupData,
  };
};

export const signupFail = () => {
  return {
    type: actionTypes.SIGNUP_FAIL,
  };
};

// export const signup = (userData) => {
//   return (dispatch) => {
//     dispatch(signupStart());

//     ServerServices.signUp(userData)
//       .then((response) => {
//         dispatch(signupSucess(response));

//         dispatch(
//           actionStores.notify(
//             {
//               id: "login-message",
//               header: "",
//               content: `Usuário criado, ${response.name}`,
//               type: "success",
//             },
//             5000
//           )
//         );
//         return true;
//       })
//       .catch((error) => {

//         dispatch(signupFail());

//         dispatch(
//           actionStores.notify(
//             {
//               id: "error-message",
//               header: "Cadastro",
//               content: "Verifique as informações inseridas",
//               type: "error",
//             },
//             5000
//           )
//         );

//         return false;
//       });
//   };
// };



export const signup = (userData) => {
  return (dispatch) => {
    dispatch(signupStart());

    return new Promise((resolve, reject) => {
      ServerServices.signUp(userData)
        .then((response) => {
          dispatch(signupSucess(response));

          dispatch(
            actionStores.notify(
              {
                id: "login-message",
                header: "",
                content: `Usuário criado, ${response.name}`,
                type: "success",
              },
              5000
            )
          );
          resolve(true);
        })
        .catch((error) => {

          dispatch(signupFail());

          dispatch(
            actionStores.notify(
              {
                id: "error-message",
                header: "Cadastro",
                content: "Verifique as informações inseridas",
                type: "error",
              },
              5000
            )
          );

          resolve(false);
        });
    })
  };

};

export const logout = () => {
  return {
    type: actionTypes.LOG_OUT,
  };
};

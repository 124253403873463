import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchDashboardStart = () => {
  return {
    type: actionTypes.FETCH_DASHBOARD_START,
  };
};

export const fetchDashboardSuccess = (dashboard) => {
  return {
    type: actionTypes.FETCH_DASHBOARD_SUCCESS,
    dashboard: dashboard,
  };
};

export const fetchDashboardFailed = () => {
  return {
    type: actionTypes.FETCH_DASHBOARD_FAILED,
  };
};

export const setDashboard = (userId, companyId, dateFrom, dateTo, originFetch, catalog) => {
  return (dispatch) => {
    dispatch(fetchDashboardStart());
    return new Promise((resolve, reject) => {

      ServeServices.getDashboard(userId, companyId, dateFrom, dateTo, originFetch, catalog)
        .then((response) => {
          dispatch(fetchDashboardSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchDashboardFailed());
          reject(err);
        });

    });
  };
};

import React from "react";
import "./UserInfo.scss";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";

export default function UserInfo({ userCompany, userInfo, userInfoClass, logoutHandler, props, user }) {
  let permissionsUser = props.user.data.permissions;
  // user.data.permissions.map(data => {
  //   if (data.companyId === user.data.companyId) {
  //     permissionsUser = data;
  //   }
  // })

  let companyNames = []
  let aux
  if (userCompany?.length > 0) {
    userCompany.map(data => {
      aux = data.name.split(' ')[0]
      aux = aux[0].toUpperCase() + aux.substr(1).toLocaleLowerCase()
      companyNames.push({
        "name": aux + ' ' + data.uf,
        "companyData": data
      })
    })
  }

  // console.log(props.portals)
  
  const portalsUserCurrentCompany = props.portals.filter(portal => user.data.portalId.includes(portal._id) && portal.companyId === user.data.companyId)

  // console.log(props.portals)

  async function adjustLayout(value, portalList) {
    let selectedPortalIdTemp;

    //let selectedPortalIdTemp = props.portals.find(probe => probe.companyId === value.id && probe.default === true);

    props.portals.map(data => {
      if (data.companyId === value.id && data.default === true) {
        selectedPortalIdTemp = data._id
      }
    })

    // console.log([portalList, selectedPortalIdTemp, value])

    let userData = props.user;

    await props.putUser({
      "companyId": value.id,
      "selectedPortalId": selectedPortalIdTemp,
      "_id": props.user.data._id
    }, props.user)

    userData["data"]["companyId"] = value.id;
    userData["data"]["selectedPortalId"] = selectedPortalIdTemp;
    await props.fetchData(userData)
  }

  async function adjustSelectedPortal(value) {
    await props.putUser({
      "selectedPortalId": value._id,
      "_id": props.user.data._id
    }, props.user)

    let userData = props.user;
    userData["data"]["selectedPortalId"] = value._id;
    await props.fetchData(userData)
  }

  return (
    <div className="user-info-main-wrapper">
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          className="d-flex flex-row justify-content-center align-items-center"
        >
          <VscAccount style={{ "color": " rgba(56, 56, 56,0.9)" }} size={"2rem"} />
          <p className={`p-0 pl-2 m-0 ${userInfoClass}`}>{userInfo}</p>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="user-info-dropdown-item"><NavLink to={"/minhaconta"} className="user-info-nav-link">Minha conta</NavLink></Dropdown.Item>
          {permissionsUser.viewUser &&
            <Dropdown.Item className="user-info-dropdown-item"><NavLink to={"/usuarios"} className="user-info-nav-link">Usuários cadastrados</NavLink></Dropdown.Item>
          }
          <Dropdown.Item className="user-info-dropdown-item"><NavLink to={"/empresa"} className="user-info-nav-link">Dados da empresa</NavLink></Dropdown.Item>
          <Dropdown.Divider />
          {companyNames?.map(data => {
            return (
              <Dropdown.Item className="user-info-dropdown-item">
                <NavLink
                  style={data.companyData.id === props.user.data.companyId ? { color: "#524ec6" } : {}}
                  onClick={() => adjustLayout(data.companyData, props.portals)}
                  to={"/oportunidades"}
                  className="user-info-nav-link"
                >
                  {data.name}
                </NavLink>
              </Dropdown.Item>
            )
          })}
          <Dropdown.Divider />
          {portalsUserCurrentCompany.map(portal => {
            return (
              <Dropdown.Item className="user-info-dropdown-item">
                <NavLink
                  style={portal._id === props.user.data.selectedPortalId ? { color: "#524ec6" } : {}}
                  onClick={() => adjustSelectedPortal(portal)}
                  to={"/oportunidades"}
                  className="user-info-nav-link"
                >
                  {portal.name}
                </NavLink>
              </Dropdown.Item>
            )
          })}
          <Dropdown.Divider />
          <Dropdown.Item className="user-info-dropdown-item">
            <NavLink
              onClick={() => logoutHandler()}
              style={{ color: "#524ec6" }}
              to="inicio"
            >
              Sair
            </NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Form } from "react-bootstrap";

export default function ModalUser(props) {
  const [office, setOffice] = useState('')
  const [email, setEmail] = useState('')
  const [registry, setRegistry] = useState('')
  const [photoBase64, setPhotoBase64] = useState('')
  const [presentation, setPresentation] = useState(false)
  const [companies, setCompanies] = useState([])
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)
  const [allPortals, setAllPortals] = useState([])
  const [optionsPermissionsUser, setOptionsPermissionsUser] = useState(null);

  let permissions = props.user.permissions;

  /* useEffect para atualizar os campos do modal */
  useEffect(() => {
    statesUpdater();
    if (props.user.companyIdList) {
      let arrayCompanyIdListUser = props?.user?.companyIdList.split(',')
      let arrayCompanyListTemp = []
      props.company?.map((companies) => {
        let aux = companies.name.split(' ')[0]
        aux = aux[0].toUpperCase() + aux.substr(1).toLocaleLowerCase()

        if (arrayCompanyIdListUser.includes(companies._id)) {
          arrayCompanyListTemp.push({
            "id": companies._id,
            "name": aux,
            "uf": companies.uf,
            "authorized": true
          })
        } else {
          arrayCompanyListTemp.push({
            "id": companies._id,
            "name": aux,
            "uf": companies.uf,
            "authorized": false
          })
        }
      })
      setCompanies(arrayCompanyListTemp)
      updateOptionsPermissions(props.user.permissions)
    }
  }, [props])

  function updateOptionsPermissions(permissionsUser) {
    let optionsPermissions = (
      <>
        <Form.Check
          checked={permissionsUser?.viewUser}
          inline
          type="checkbox"
          label="Edição de usuários"
          name="viewUser"
          onChange={(e) => controlPermissions(e.target.checked, "viewUser")}
        />
        {/* <Form.Check
          checked={permissionsUser?.viewCatalog}
          inline
          type="checkbox"
          label="Catálogo"
          name="viewCatalog"
          onChange={(e) => controlPermissions(e.target.checked, "viewCatalog")}
        /> */}
        {/* <Form.Check
          checked={permissionsUser?.viewPortal}
          inline
          type="checkbox"
          label="Portal"
          name="viewPortal"
          onChange={(e) => controlPermissions(e.target.checked, "viewPortal")}
        /> */}
        <Form.Check
          checked={permissionsUser?.viewRanking}
          inline
          type="checkbox"
          label="Ranking"
          name="viewRanking"
          onChange={(e) => controlPermissions(e.target.checked, "viewRanking")}
        />
        <Form.Check
          checked={permissionsUser?.viewDashboard}
          inline
          type="checkbox"
          label="Dashboard"
          name="viewDashboard"
          onChange={(e) => controlPermissions(e.target.checked, "viewDashboard")}
        />
        {/* <Form.Check
          checked={permissionsUser?.viewBI}
          inline
          type="checkbox"
          label="B.I."
          name="viewBI"
          onChange={(e) => controlPermissions(e.target.checked, "viewBI")}
        /> */}
      </>
    )
    setOptionsPermissionsUser(optionsPermissions);
  }

  function statesUpdater() {
    if (props.show !== false) {
      setOffice(props.user.sector)
      setEmail(props.user.email)
      setRegistry(props.user.userReg)
      setPassword("")
      setConfirmPassword("")
      setPhotoBase64(props.user.avatar)
      setPresentation(props.user?.presentation)
      
      let _allPortals = [];
      props.portals?.map((portalCompany) => {
        if (portalCompany.disabled === false) {
          if (props.user.portalId.includes(portalCompany._id)) {
            _allPortals.push({
              "id": portalCompany._id,
              "name": portalCompany.name,
              "company_id": portalCompany.companyId,
              "authorized": true
            })
          } else {
            _allPortals.push({
              "id": portalCompany._id,
              "name": portalCompany.name,
              "company_id": portalCompany.companyId,
              "authorized": false
            })
          }
        }
      })
      setAllPortals(_allPortals)
    }
  }

  /* Função para salvar as edições nos usuários */
  const saveEdits = async () => {
    
    let companyIdList = companies.filter(company => company.authorized).map(company => company.id)
    const companyIdListString = companyIdList.join(',')

    let portalsUser = allPortals.filter(portal => portal.authorized).map(portal => portal.id)

    const values = {
      "name": props.user.name,
      "email": email,
      "userReg": props.user.userReg,
      "portalId": portalsUser,
      "selectedPortalId": props.user.selectedPortalId,
      "sector": office,
      "disabled": props.user.disabled,
      "mainUser": props.user.mainUser,
      "companyId": props.user.companyId,
      "companyIdList": companyIdListString,
      "target": props.user.target,
      "registry": registry,
      "permissions": permissions,
      "avatar": photoBase64,
      "presentation": presentation,
      "password": password,
      "_id": props.user._id,
    }

    // console.log("salvar")
    // console.log(password , confirmPassword)
    if (password === confirmPassword) {
      setError(false)
      await props.putUser(values, props.userData)
      props.onHide(false)
    } else {
      setError(true)
    }

  }

  /* função para salvar o base64 da foto no estado */
  function loadPhoto(e) {
    getBase64(e).then(result => {
      setPhotoBase64(result);
    })
  }

  /* função que processa o arquivo inputado transformando-o em base64 */
  function getBase64(file) {
    return new Promise((resolve) => {
      let baseUrl = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        baseUrl = reader.result;
        resolve(baseUrl);
      })
    })
  }

  function controlCompany(value, index) {
    companies[index] = {
      "id": companies[index].id,
      "name": companies[index].name,
      "uf": companies[index].uf,
      "authorized": value
    }
    setCompanies([...companies])
  }

  function controlPortals(value, index) {
    allPortals[index] = {
      "name": allPortals[index].name,
      "company_id": allPortals[index].company_id,
      "id": allPortals[index].id,
      "authorized": value
    }
    setAllPortals([...allPortals])
  }

  function controlPermissions(value, data) {
    permissions[data] = value;
    updateOptionsPermissions(permissions);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edição de usuário
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-1">
            <Form.Group as={Col} md="6" >
              <Form.Label>Setor</Form.Label>
              <Col sm="20">
                <Form.Control autocomplete="off" value={office} onChange={(e) => setOffice(e.target.value)} type="text" placeholder="Setor" />
              </Col>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="text"
                placeholder="E-mail do usuário"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col} md="6">
              <Form.Label>Nova senha do usuário</Form.Label>
              <Form.Control
                style={error ? { borderColor: "red" } : {}}
                type="password"
                placeholder="Senha do usuário"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Confirmar nova senha do usuário</Form.Label>
              <Form.Control
                type="password"
                style={error ? { borderColor: "red" } : {}}
                placeholder="Conformar senha do usuário"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Form.Group>
            <Form.Label>Modo Vitrine</Form.Label>
            <Row className="ml-4">
            <Form.Check
                    // key={index}
                    checked={presentation}
                    inline
                    type="checkbox"
                    label="Prevenir alterações"
                    // name={company.name}
                    onChange={(e) => setPresentation(!presentation)}
                  />
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Empresas</Form.Label>
            <Row className="ml-4">
              {companies?.map((company, index) => {
                return (
                  <Form.Check
                    key={index}
                    checked={company.authorized}
                    inline
                    type="checkbox"
                    label={company.name.replace(',', '') + ' ' + company.uf}
                    name={company.name}
                    onChange={(e) => controlCompany(e.target.checked, index)}
                  />
                )
              })}
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label>Portais do usuário</Form.Label>
            <Row className="ml-4">
              {allPortals.map((portal, index) => {
                return (
                  <Form.Check
                    key={index}
                    checked={portal.authorized}
                    inline
                    type="checkbox"
                    label={portal.name}
                    name={portal.name}
                    onChange={(e) => controlPortals(e.target.checked, index)}
                  />
                )
              })}
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label>Autorizações de acesso do usuário</Form.Label>
            <Row className="ml-4 mr-4">
              {optionsPermissionsUser}
            </Row>
          </Form.Group>

          <Form.Group className="position-relative mb-1">
            <Form.Label>Foto</Form.Label>
            <Col className="text-center">
              <img className="presentation" src={photoBase64} />
            </Col>
            <Form.Control
              type="file"
              required
              name="photo"
              onChange={(e) => loadPhoto(e.target.files[0])}
            />
          </Form.Group>
          <Row className="justify-content-end mr-1">
            <Button onClick={(e) => { saveEdits() }} variant="flat">Salvar alterações</Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchCompanyStart = () => {
  return {
    type: actionTypes.FETCH_COMPANY_START,
  };
};

export const fetchCompanySuccess = (company) => {
  return {
    type: actionTypes.FETCH_COMPANY_SUCCESS,
    company: company,
  };
};

export const fetchCompanyFailed = () => {
  return {
    type: actionTypes.FETCH_COMPANY_FAILED,
  };
};

export const getCompany = (usuario) => {
  return (dispatch) => {
    dispatch(fetchCompanyStart());
    return new Promise((resolve, reject) => {

      ServeServices.getCompany(usuario)
        .then((response) => {
          dispatch(fetchCompanySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCompanyFailed());
          reject(err);
        });

    });
  };
};

export const putCompany = (company, usuario) => {
  return (dispatch) => {
    dispatch(fetchCompanyStart());
    return new Promise((resolve, reject) => {

      ServeServices.putCompany(company, usuario)
        .then((response) => {
          dispatch(fetchCompanySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCompanyFailed());
          reject(err);
        });

    });
  };
};

export const postCompany = (company, usuario) => {
    return (dispatch) => {
      dispatch(fetchCompanyStart());
      return new Promise((resolve, reject) => {
  
        ServeServices.postCompany(company, usuario)
          .then((response) => {
            dispatch(fetchCompanySuccess(response));
            resolve(response);
          })
          .catch((err) => {
            localStorage.removeItem("reduxStore");
            localStorage.removeItem("private");
            localStorage.removeItem("dataFetched");
            window.location.reload();
            dispatch(fetchCompanyFailed());
            reject(err);
          });
  
      });
    };
  };

import React, { useState, useEffect } from "react";
import "./PasswordReset.scss";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormCard from "../../Cards/FormCard/FormCard";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import HCaptcha from '@hcaptcha/react-hcaptcha';

function PasswordReset(props) {
  const origin = window.location.origin;

  const [senha, setSenha] = useState("");
  const [token, setToken] = useState("");
  const [senhaRepetida, setSenhaRepetida] = useState("");
  const [submitted, setSubmitted] = useState(false)
  const [statusCaptcha, setStatusCaptcha] = useState(true); // Se estiver online = TRUE; Se estiver local = FALSE 

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const page_type = urlParams.get('token')

    if (page_type === null) {
      window.location.assign('/')
    }
    setToken(page_type)
  }, [window.location.search])

  const notifyCreation = (type, message) => {
    props.notify(
      {
        id: `id ${message}`,
        header: 'TODO',
        content: message,
        type: type,
      },
      5000
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // const link = baseURL + "/api/v1/";
    // const link = "http://172.16.102.202:3002/api/v1/";
    const link = (origin.includes("localhost:3000")) ? "http://localhost:3002/api/v1/": origin + "/api/v1/";

    const response = await fetch(link + 'user/passwordreset' ,{
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        password: senha,
        token: token
      })
    })

    // console.log(response)

    setSubmitted(true)

    // props.onPasswordResetSubmitted(userData);
  };

  const PasswordResetTitle = (
    <Row className="d-flex flex-row justify-content-center align-items-center">
      <Col xs={1} className="arrow-click-container"></Col>
      <Col xs={11}>
        <h1>Esqueci a senha</h1>
      </Col>
    </Row>
  );

  function handleVerificationSuccess(token, ekey) {
    setStatusCaptcha(false)
  }

  return (
    <FormCard>
      <Row className="w-100 h-100">
        <Col xs={12} className="d-flex flex-row pl-4 pt-3">
          <a href="/login">
              <i className="arrow left"></i> Voltar
          </a>
        </Col>

        <Col>
        <Col xs={12}>
            <p className="passwordReset-title">
              Por favor, insira uma nova senha maior ou igual que seis carácteres.
            </p>
          </Col>

          <Col xs={12} className="d-flex flex-column justify-content-center">
            <Form
              className="p-2 align-items-center mb-5"
              onSubmit={(e) => onSubmit(e)}
            >
              <Form.Group
                controlId="exampleForm.ControlInput1"
                // className="d-flex flex-row align-items-center mb-4"
              >
                <Row className="w-100 align-items-center">
                  <Col
                    xs={12}
                    className="d-flex justify-content-center flex-column w-100 align-items-center"
                  >
                    <Form.Label className="passwordReset-input-label mt-2">
                    <p className="email-legend"> Nova senha </p>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="passwordReset-input-control ml-4"
                      value={senha}
                      placeholder="Nova senha"
                      onChange={(e) => setSenha(e.target.value)}
                    />
                  </Col>

                  <Col
                    xs={12}
                    className="d-flex justify-content-center flex-column w-100 align-items-center"
                  >
                    <Form.Label className="passwordReset-input-label mt-2">
                    <p className="email-legend"> Repita nova senha </p>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="passwordReset-input-control ml-4"
                      value={senhaRepetida}
                      placeholder="Repita nova senha"
                      onChange={(e) => setSenhaRepetida(e.target.value) && console.log(senhaRepetida)}
                    />
                  </Col>
                  
                </Row>
              </Form.Group>
              { statusCaptcha ?
              <>
              <Col xs={12} className="content-captcha position-absolute">
                <HCaptcha
                  sitekey="dfc29da1-236a-4160-8057-e51409ede601"
                  onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />
              </Col></>
              :
              <Button
              
                disabled={(senha === "" || senha !== senhaRepetida) !== (senha.length <= 6 && senha.senhaRepetida <= 6)}
                variant="primary"
                type="submit"
                className="button-subimit"
                onClick={()=>notifyCreation("alert","Senha alterada")}
              >
                Enviar
              </Button>
              }
              
            </Form>
          </Col>
        </Col>
      </Row>
    </FormCard>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPasswordResetSubmitted: (userData) =>
      dispatch(storeActions.signup(userData)),
      
    notify: (notification, delay) =>
    dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

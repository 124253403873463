import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchCategoryStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORY_START,
  };
};

export const fetchCategorySuccess = (category) => {
  return {
    type: actionTypes.FETCH_CATEGORY_SUCCESS,
    category: category,
  };
};

export const fetchCategoryFailed = () => {
  return {
    type: actionTypes.FETCH_CATEGORY_FAILED,
  };
};

export const initCategory = (usuario) => {
  return (dispatch) => {
    dispatch(fetchCategoryStart());
    return new Promise((resolve, reject) => {

      ServeServices.getCategory(usuario)
        .then((response) => {
          dispatch(fetchCategorySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCategoryFailed());
          reject(err);
        });

    });
  };
};

export const putCategory = (regex, usuario) => {
  return (dispatch) => {
    dispatch(fetchCategoryStart());
    return new Promise((resolve, reject) => {
      ServeServices.putCategory(regex, usuario)
        .then((response) => {
          dispatch(fetchCategorySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCategoryFailed());
          reject(err);
        });
    });
  };
};

export const postCategory = (regex, token) => {
  return (dispatch) => {
    dispatch(fetchCategoryStart());
    return new Promise((resolve, reject) => {
      ServeServices.postCategory(regex, token)
        .then((response) => {
          dispatch(fetchCategorySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCategoryFailed());
          reject(err);
        });

    });
  };
};

export const deleteCategory = (categoryId, token) => {
  return (dispatch) => {
    dispatch(fetchCategoryStart());
    return new Promise((resolve, reject) => {
      ServeServices.deleteCategory(categoryId, token)
        .then((response) => {
          dispatch(fetchCategorySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchCategoryFailed());
          reject(err);
        });
    });
  };
};
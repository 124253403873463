import React, { useState, useEffect } from "react";
import "./NotificationItem.scss";

function NotificationItem({type, header, content, notificationTime }) {
  const [itemClass, setItemClass] = useState("side-notifcation-show");

  useEffect(() => {
    setTimeout(() => {
      setItemClass("side-notifcation-hide");
    }, notificationTime - 1000);
  });

  return (
    <li>
      <div
        className={`notification-item-wrapper ${itemClass} ${type} d-flex justify-content-center align-items-center m-2`}
      >
        <p className="m-0 notification-item-header">{header}</p>
        <p className="m-0 notification-item-text">{content}</p>
      </div>
    </li>
  );
}

export default NotificationItem;

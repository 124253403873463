import * as actionTypes from "./actionTypes";
import * as actionStores from "../actions/index";
import { interlaceData } from "../auxMethods";

export const dataFetchingStart = (token) => {
  return {
    type: actionTypes.DATA_FETCHING_START,
    token: token,
  };
};

export const dataFetchingSuccess = () => {
  return {
    type: actionTypes.DATA_FETCHING_SUCCESS,
  };
};

export const dataFetchingFail = () => {
  return {
    type: actionTypes.DATA_FETCHING_FAIL,
  };
};

export const dataErase = () => {
  return {
    type: actionTypes.DATA_FETCHING_ERASE,
  };
};

export const privateStateChange = (bol) => {
  return {
    type: actionTypes.DATA_FETCHING_PRIVATE_CONTROL,
    private: bol
  };
};

export const notify = (notification) => {
  return {
    type: actionTypes.NOTIFICATION_CREATE,
    notification,
  };
};

export const fetchData = (usuario) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(dataFetchingStart(usuario.token));
      try {
        let opportunities,
          items,
          portals,
          regex,
          declineStatus,
          companies,
          category,
          statusList;
        await dispatch(actionStores.initCompanies(usuario)).then((el) => { companies = el });
        await dispatch(actionStores.initPortals(usuario)).then(el => portals = el);
        await dispatch(actionStores.initItems(usuario)).then(el => items = el);
        await dispatch(actionStores.initIntegrity(usuario));
        await dispatch(actionStores.initOpportunities(usuario, usuario.data.companyId)).then(el => opportunities = el);
        await dispatch(actionStores.initDeclineStatus(usuario, usuario.data.companyId)).then(el => declineStatus = el);
        await dispatch(actionStores.initRegex(usuario)).then(el => regex = el);
        await dispatch(actionStores.initStatusList(usuario)).then(el => statusList = el);
        await dispatch(actionStores.initCategory(usuario)).then(el => category = el);

        await interlaceData(
          items,
          portals,
          companies
        );

        dispatch(dataFetchingSuccess());
        resolve(true);
      } catch (err) {
        // console.error(err);
        localStorage.removeItem("reduxStore");
        localStorage.removeItem("private");
        localStorage.removeItem("dataFetched");
        window.location.reload();
        dispatch(dataFetchingFail());
        reject(false);
      }
    });
  };
};

export const setInteraction = (usuario, opportunityId) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(actionStores.setUserInteraction(usuario, opportunityId));
        resolve(true);
      } catch (err) {
        localStorage.removeItem("reduxStore");
        localStorage.removeItem("private");
        localStorage.removeItem("dataFetched");
        window.location.reload();
        dispatch(dataFetchingFail());
        reject(false);
      }
    });
  };
}

export const privateChange = (value) => {
  return dispatch => {
    dispatch(privateStateChange(value))
  }
}
import * as actionTypes from "../actions/actionTypes";
// import * as actions from "../actions/index";
import { updateObject } from "../utility";
import {defineState} from 'redux-localstore';

const defaultState = {
  user: null,
  error: false,
  loading: false,
};

const initialState = defineState(defaultState)('auth');

const authStart = (state) => {
  return updateObject(state, { error: false, loading: true, user: null });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    error: false,
    loading: false,
  });
};

const authFail = (state) => {
  return updateObject(state, { error: true, loading: false, user: null });
};

// Signup
const signupStart = (state) => {
  return updateObject(state, { error: false, loading: true });
};

const signupSuccess = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false,
  });
};

const signupFail = (state) => {
  return updateObject(state, { error: true, loading: false });
};

const logout = (state) => {
  return updateObject(state, { user: null, error: true, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state);
    case actionTypes.SIGNUP_START:
      return signupStart(state);
    case actionTypes.SIGNUP_SUCCESS:
      return signupSuccess(state, action);
    case actionTypes.SIGNUP_FAIL:
      return signupFail(state);
    case actionTypes.LOG_OUT:
      return logout(state);
    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchStatusListStart = () => {
        return {
                type: actionTypes.FETCH_STATUS_LIST_START,
        };
};

export const fetchStatusListSuccess = (statusList) => {
        return {
                type: actionTypes.FETCH_STATUS_LIST_SUCCESS,
                statusList: statusList,
        };
};

export const fetchStatusListFailed = () => {
        return {
                type: actionTypes.FETCH_STATUS_LIST_FAILED,
        };
};

export const initStatusList = (usuario) => {
        return (dispatch) => {
                dispatch(fetchStatusListStart());
                return new Promise((resolve, reject) => {

                        ServeServices.getStatusList(usuario)
                                .then((response) => {
                                        dispatch(fetchStatusListSuccess(response));
                                        resolve(response);
                                })
                                .catch((err) => {
                                        localStorage.removeItem("reduxStore");
                                        localStorage.removeItem("private");
                                        localStorage.removeItem("dataFetched");
                                        window.location.reload();
                                        dispatch(fetchStatusListFailed());
                                        reject(err);
                                });

                });
        };
};

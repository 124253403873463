import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  notifications: [],
  notificationDelay: 5000,
};

const createNotification = (state, action) => {
  const newNotification = [...state.notifications];
  newNotification.push(action.notification);
  return updateObject(state, { notifications: newNotification });
};

const deleteNotification = (state, action) => {
  const newNotifications = state.notifications.filter(
    (not) => not.id !== action.id
  );
  return updateObject(state, {
    notifications: newNotifications,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_CREATE:
      return createNotification(state, action);
    case actionTypes.NOTIFICATION_DELETE:
      return deleteNotification(state, action);
    default:
      return state;
  }
};

export default reducer;

import { combineReducers } from "redux";
import control from "./control";
import opportunities from "./opportunities";
import pastOpportunities from "./pastOpportunities";
import purchases from "./purchases";
import portals from "./portals";
import dashboard from "./dashboard";
import auth from "./auth";
import notifications from "./notification";
import items from "./items";
import proposals from "./proposals";
import parts from "./parts";
import companies from "./companies";
import regex from "./regex";
import category from "./category";
import user from "./user";
import signUp from "./signUp"
import userInteraction from "./userInteraction";
import declineStatus from "./declineStatus";
import declineOpportunity from "./declineOpportunity";
import feedback from "./feedback";

export default combineReducers({
  control,
  opportunities,
  pastOpportunities,
  purchases,
  portals,
  auth,
  notifications,
  items,
  dashboard,
  proposals,
  parts,
  companies,
  regex,
  category,
  user,
  signUp,
  userInteraction,
  declineStatus,
  declineOpportunity,
  feedback
});

import React, { useState } from "react";
import { Row, Col, Modal, Container, Button } from "react-bootstrap";
import Select from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function ModalDownload(props) {
  const [statusProposal, setStatusProposal] = useState(false)
  const [optionsOpportunity, setOptionsOpportunity] = useState(null)
  const [opportunitiesManufacturerSelected, setOpportunitiesManufacturerSelected] = useState(null)
  const [proposalsOpportunitySelected, setProposalsOpportunitySelected] = useState(null)
  const [manufacturerSelected, setManufacturerSelected] = useState(null)
  const [opportunitySelected, setOpportunitySelected] = useState(null)
  const [statusDownloadOpportunities, setStatusDownloadOpportunities] = useState(false)
  const [statusDownloadProposals, setStatusDownloadProposals] = useState(false)
  const [statusLoadingProposals, setStatusLoadingProposals] = useState(false)

  let optionsManufacturer = []
  props?.opportunityManufacturer?.map(data => {
    optionsManufacturer.push({
      "value": data.manufacturer,
      "label": data.manufacturer,
      "isDisabled": false
    })
  })

  function manufacturerAction(manufacturer) {
    setOpportunitySelected(null)
    setOptionsOpportunity(null)
    setStatusDownloadProposals(false)
    setStatusLoadingProposals(false)
    setManufacturerSelected(manufacturer)

    let dataManufacturerSelected = props?.opportunityManufacturer?.find(data => { if (data.manufacturer === manufacturer) return data })
    setOpportunitiesManufacturerSelected(dataManufacturerSelected.opportunities)

    let options = []
    dataManufacturerSelected.opportunities?.map((opp) => {
      if (opp.totalPrice > 0) {
        options.push({
          "value": opp.index,
          "label": opp.index,
          "isDisabled": false
        })
      }
    })
    setOptionsOpportunity(options)
    setStatusDownloadOpportunities(true)
    setStatusProposal(true)
  }

  async function opportunityAction(opportunity) {
    setStatusLoadingProposals(true)
    setStatusDownloadProposals(false)

    let proposals;
    await props.initProposalData(props.user, opportunity).then((val) => {
      proposals = val
    });
    setProposalsOpportunitySelected(proposals)
    setStatusLoadingProposals(false)
    setStatusDownloadProposals(true)
  }

  function downloadOpportunities(e) {
    e.preventDefault();

    let formData = opportunitiesManufacturerSelected.map(opp => {
      let dateEnd = new Date(opp.dateEnd * 1000)
      let dateStart = new Date(opp.dateStart * 1000)
      return {
        "Oportunidade": opp.index,
        "Origem": opp.origin,
        "Descrição da oportunidade": opp.description,
        "Fabricante": manufacturerSelected,
        "Data inicial": dateStart.getDate() + "/" + (dateStart.getMonth() + 1) + "/" + dateStart.getFullYear(),
        "Data final": dateEnd.getDate() + "/" + (dateEnd.getMonth() + 1) + "/" + dateEnd.getFullYear(),
        "Status da oportunidade": opp.status,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = { Sheets: { 'Dados da oportunidade': ws }, SheetNames: ['Dados da oportunidade'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Oportunidades - ${manufacturerSelected} ` + ".xlsx");
  }

  function downloadProposals(e) {
    e.preventDefault();

    let dataPorItem = {}
    proposalsOpportunitySelected.map(prop => {
      if (dataPorItem[prop["itemDescription"]] === undefined) {
        dataPorItem[prop["itemDescription"]] = []
      }
      dataPorItem[prop["itemDescription"]].push(prop)
    })

    //ordenando o objeto 'dataPorItem'
    let dataPorItemClassificado = {};
    for (let prop in dataPorItem) {
      let empresa = dataPorItem[prop].find((item) => item.corporateName === props.currentCompany)
      let newProp = dataPorItem[prop].filter((item) => item.corporateName !== props.currentCompany)
      if (dataPorItemClassificado[prop] === undefined) {
        dataPorItemClassificado[prop] = []
      }
      let teste = newProp.sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (empresa !== undefined) {
        teste.unshift(empresa)
      }
      dataPorItemClassificado[prop] = teste
    }

    //Formatando o objeto como array
    let dataPorItemCompleto = []
    for (var prop in dataPorItemClassificado) {
      for (let i = 0; i < dataPorItemClassificado[prop].length; i++) {
        dataPorItemCompleto.push({
          "index": dataPorItemClassificado[prop][i].index,
          // "dateEnd": dataPorItemClassificado[prop][i].dateEnd,
          "corporateName": dataPorItemClassificado[prop][i].corporateName,
          "currency": dataPorItemClassificado[prop][i].currency,
          "itemDescription": dataPorItemClassificado[prop][i].itemDescription,
          "itemNumber": dataPorItemClassificado[prop][i].itemNumber,
          "itemPrice": dataPorItemClassificado[prop][i].itemPrice,
          "proposalNumber": dataPorItemClassificado[prop][i].proposalNumber,
          "quantity": dataPorItemClassificado[prop][i].quantity,
          "taxCode": dataPorItemClassificado[prop][i].taxCode,
          "totalPrice": dataPorItemClassificado[prop][i].totalPrice,
          "um": dataPorItemClassificado[prop][i].um
        })
      }
    }

    let formData = dataPorItemCompleto.map(prop => {
      // let dateEnd = new Date(prop.dateEnd * 1000)
      return {
        "Oportunidade": prop.index,
        "Nome da empresa": prop.corporateName,
        "Descrição do item": prop.itemDescription,
        "Valor unitário": prop.itemPrice === null ? 0 : prop.itemPrice,
        "Quantidade": prop.quantity,
        "Valor total": prop.totalPrice,
        "Moeda": prop.currency,
        // "Data final": dateEnd.getDate() + "/" + (dateEnd.getMonth() + 1) + "/" + dateEnd.getFullYear(),
        "Número da proposta": prop.proposalNumber,
        "Código de Imposto": prop.taxCode,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = { Sheets: { 'Dados das propostas': ws }, SheetNames: ['Dados das propostas'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Propostas - ${opportunitySelected.value} ` + ".xlsx");
  }

  return (
    <Modal  {...props} show={props.showModalDownload} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header
        closeButton
        onClick={() => {
          props.onHide();
          setStatusProposal(false);
          setStatusDownloadOpportunities(false);
          setStatusDownloadProposals(false);
        }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Download dos dados do fabricante
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <h5>Oportunidades</h5>
          <Row>
            <Col xs={8} md={6}>
              <p>Selecione o </p>
            </Col>
            <Col>
              <Select
                options={optionsManufacturer}
                placeholder={"Fabricante"}
                onChange={(e) => { manufacturerAction(e.value) }}
              />
            </Col>
          </Row>

          <Button
            className={statusDownloadOpportunities !== true ? "display-none" : ""}
            variant="flat"
            onClick={(e) => { downloadOpportunities(e) }}
          >
            <FontAwesomeIcon size="lg" icon={faDownload} />
          </Button>

        </Container>

        <Container className={statusProposal !== true ? "display-none" : ""}>
          <h5>Propostas</h5>

          <Row >
            <Col xs={8} md={6}>
              <p>Selecione a </p>
            </Col>
            <Col>
              <Select
                defaultValue={null}
                value={opportunitySelected}
                options={optionsOpportunity}
                placeholder={"Oportunidade"}
                onChange={(e) => { setOpportunitySelected(e); opportunityAction(e.value) }}
              />
            </Col>
          </Row>

          <Button variant="flat" className={statusLoadingProposals !== true ? "display-none" : ""}>
            <span size="lg" class="spinner-grow spinner-grow-sm"></span>
          </Button>

          <Button
            className={statusDownloadProposals !== true ? "display-none" : ""}
            variant="flat"
            onClick={(e) => { downloadProposals(e) }}
          >
            <FontAwesomeIcon size="lg" icon={faDownload} />
          </Button>


        </Container>
      </Modal.Body>
    </Modal >
  )
}

import React, { useState } from "react";
import "./Portals.scss";
import { Button, Card } from "react-bootstrap";
import CreatePortal from "./CreatePortal";
import ListPortal from "./ListPortal";

export default function Portals() {
  const [showModal, setShowModal] = useState(false);

  return (

    <Card className="container-portal">
      <Button
        className="button-create-portal"
        variant="flat"
        onClick={() => { setShowModal(true) }}
      >
        Cadastrar novo portal
      </Button>

      {showModal &&
        <CreatePortal
          showModal={showModal}
          setShowModal={setShowModal}
        />}

      <ListPortal />

    </Card>

  );
}
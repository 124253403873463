import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as storeActions from "../../../../store/actions/index"
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss"

function ListPortal(props) {
  const [isPortalDisabled, setIsPortalDisabled] = useState([]);
  const [portalsUser, setPortalsUser] = useState([]);

  useEffect(() => {
    readDataPortals()
  }, [])

  async function readDataPortals() {
    const portals = await props.initPortals(props.user);

    setPortalsUser(portals)

    if (isPortalDisabled.length === 0) {
      const temp = new Array(parseInt(portals.length))
      portals.map((portal, index) => {
        temp[index] = portal.disabled
      })
      setIsPortalDisabled(temp)
    }
  }

  async function controlDisablePortal(index, id, status) {
    let _isPortalDisabled = isPortalDisabled;
    _isPortalDisabled[index] = status;
    setIsPortalDisabled(_isPortalDisabled)

    const currentPortal = portalsUser.find(portal => portal._id === id);

    const data = {
      name: currentPortal.name,
      site: currentPortal.site,
      companyId: props.user.data.companyId,
      disabled: status,
      _id: currentPortal._id
    }

    await props.updatePortal(data, props.user)

    readDataPortals()
  }

  return (
    <div className="container-list-portals">
      <ListGroup variant="flush" as="ol" className="list-portals">
        {portalsUser.map((portal, index) => {
          return (
            <ListGroup.Item
              key={index}
              as="li"
              className="list-item"
            >
              <div className="container-data-portals">
                <p>{portal.name}</p>
              </div>

              <div>
                {isPortalDisabled[index] === true ?
                  <button
                    className="button-disable"
                    onClick={() => { controlDisablePortal(index, portal._id, false) }}
                  >
                    <FontAwesomeIcon size="lg" icon={faToggleOff} />
                  </button>
                  :
                  <button
                    className="button-enable"
                    onClick={() => { controlDisablePortal(index, portal._id, true) }}
                  >
                    <FontAwesomeIcon size="lg" icon={faToggleOn} />
                  </button>
                }
              </div>
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    portals: state.portals.portals,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initPortals: (usuario) => dispatch(storeActions.initPortals(usuario)),
    updatePortal: (data, usuario) => dispatch(storeActions.updatePortal(data, usuario)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPortal);
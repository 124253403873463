import axios from "axios";
const origin = window.location.origin;

 //const baseUrl = "http://172.16.102.186:3002/api/v1/";

 const baseUrl = (origin.includes("localhost:3000")) ? "http://localhost:3002/api/v1/": origin + "/api/v1/";
/**
 * Apontamentos de API individuais em:
 *  ForgotPassword.js
 *  PasswordReset.js
 * 
 */

class UserService {
  static getUsers(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}user`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getDashboard(usuario, dateFrom, dateTo, originFetch, catalog) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}dashboard`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
            "portal-id": usuario.data.selectedPortalId,
            "origin-fetch": originFetch,
            "catalog": catalog
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getDeclineOpportunity(usuario, companyId, dateFrom, dateTo, opNumber) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}declineOpportunity/declined`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": companyId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
            "portal-id": usuario.data.selectedPortalId,
            "opnumber": opNumber
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getOpportunities(usuario, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}opportunity`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "main-company-id": companyId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getDeclineStatus(usuario, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}declineStatus`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "main-company-id": companyId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getStatusList(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}statusList`, {
          headers: {
            "x-access-token": usuario.token,
            "portal-id": usuario.data.selectedPortalId,
            "main-company-id": usuario.data.companyId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getOpportunityStatus(usuario, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}opportunityStatus`, {
          headers: {
            "x-access-token": usuario.token,
            "portal-id": usuario.data.selectedPortalId,
            "main-company-id": usuario.data.companyId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getOneOpportunityStatus(usuario, opId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}opportunityStatus/${opId}`, {
          headers: {
            "x-access-token": usuario.token,
            "portal-id": usuario.data.selectedPortalId,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postOpportunityStatus(usuario, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}opportunityStatus`, data, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postDeclineOpportunity(data, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}declineOpportunity`, data, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postFeedback(data, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}feedback`, data, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getPastOpportunities(usuario, portal, dateFrom, dateTo, index) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}opportunity/past`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "main-company-id": usuario.data.companyId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
            "portal-id": usuario.data.selectedPortalId,
            "index": Number(index)
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getProposalDataByIndex(usuario, index) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}proposal/data/index`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId,
            "opportunities": index
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getProposalById(usuario, index) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}proposal/opportunity/${index}`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getPurchases(usuario, portal, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}purchase`, {
          headers: {
            "x-access-token": usuario.token,
            "userId": usuario.data._id,
            "companyId": portal.companyId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo)
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getOpportunityFile(usuario, fileName, fileId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}opportunity/download`, {
          headers: {
            "fileid": fileId,
            "filename": fileName,
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getItems(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}item`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getRegex(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}catalog`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getCategory(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}category`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId
          },
        }).then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    })
  }

  static putRegex(regex, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}catalog/${regex._id}`, regex, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static putCategory(regex, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}category/${regex._id}`, regex, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postRegex(regex, token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}catalog`, regex, {
          headers: {
            "x-access-token": token,
            "main-company-id": regex.companyId,
            "portal-id": regex.portalId
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postCategory(regex, token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}category`, regex, {
          headers: {
            "x-access-token": token,
            "main-company-id": regex.companyId,
            "portal-id": regex.portalId
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static deleteCategory(categoryId, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}category/${categoryId}`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getUser(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}user`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getOneUser(usuario, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}user/${usuario.data._id}`, {
          headers: {
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static putUser(user, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}user/${user._id}`, user, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postUser(user, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}user`, user, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postInvite(user, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}invite`, user, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static setIntegrity(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}horse`, {
          lat: usuario.latitude,
          lon: usuario.longitude,
          ipAddress: usuario.ip.v4,
          accuracy: usuario.accuracy,
          companyId: usuario.data.companyId,
          token: usuario.token
        }, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  /*SignUp */
  static postSignUp(signUp) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}signUp`, signUp)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  /*SignUp */
  static confirmSignUp(confirm) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}confirm`, confirm)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  /*company */
  static getCompany(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}company/all`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static putCompany(usuario, company) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}company/${company._id}`, company, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postCompany(company, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}company`, company, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        }).then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static setInteraction(usuario, opportunityId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}interaction`, {
          "companyId": usuario.data.companyId,
          "userId": usuario.data._id,
          "opportunity": {
            "id": opportunityId,
            "viewed": true
          }
        }, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getProposals(usuario, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}proposal`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId,
            "portal-id": usuario.data.selectedPortalId,
            "datefrom": Number(dateFrom),
            "dateto": Number(dateTo),
          },
        })
        .then((res) => {
          resolve(res.data);
          //resolve([]);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getPortals(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}portal`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static postPortal(data, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}portal`, data, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        }).then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static putPortal(data, usuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}portal/${data._id}`, data, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getCompanies(usuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}company/all`, {
          headers: {
            "x-access-token": usuario.token,
            "main-company-id": usuario.data.companyId
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static getParts(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}part`, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static signIn(loginInfo) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}login`, loginInfo)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

  static signUp(userInfo) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}user`, userInfo)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          localStorage.setItem("integrity", true);
        });
    });
  }

}

export default UserService;
import React from "react";
import "./Index.scss";
import liboIcon from "../../../assets/petrolead-branco.png";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";

function Index(props) {
  return (
    <div className="index-card-backdrop">
      <div className="index-center-wrapper">
        <img className="petrolead" src={liboIcon} alt="ícone-Petrolead" />
        <NavLink to="/login" className="index-card-button" onClick={() => props.privateChange(true)}>
          Acessar
        </NavLink>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    privateChange: (value) =>
      dispatch(storeActions.privateChange(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
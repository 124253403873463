import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchDeclineOpportunityStart = () => {
  return {
    type: actionTypes.FETCH_DECLINE_OPPORTUNITY_START,
  };
};

export const fetchDeclineOpportunitySuccess = (declineOpportunity) => {
  return {
    type: actionTypes.FETCH_DECLINE_OPPORTUNITY_SUCCESS,
    declineOpportunity: declineOpportunity,
  };
};

export const fetchDeclineOpportunityFailed = () => {
  return {
    type: actionTypes.FETCH_DECLINE_OPPORTUNITY_FAILED,
  };
};

export const postDeclineOpportunity = (data, usuario) => {
  return (dispatch) => {
    dispatch(fetchDeclineOpportunityStart());
    return new Promise((resolve, reject) => {

      ServeServices.postDeclineOpportunity(data, usuario)
        .then((response) => {
          dispatch(fetchDeclineOpportunitySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchDeclineOpportunityFailed());
          reject(err);
        });
    });
  };
};

export const getDeclineOpportunity = (userId, companyId, dateFrom, dateTo, opNumber) => {
  return (dispatch) => {
    dispatch(fetchDeclineOpportunityStart());
    return new Promise((resolve, reject) => {

      ServeServices.getDeclineOpportunity(userId, companyId, dateFrom, dateTo, opNumber)
        .then((response) => {
          dispatch(fetchDeclineOpportunitySuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchDeclineOpportunityFailed());
          reject(err);
        });
    });
  };
};
import React from "react";
import "./FormCard.scss";
import intro from "../../../assets/intro3.jpg";
import PetroleadIcon from "../../../assets/petrolead-branco.png";
import LiboIcon from "../../../assets/Logo-Libo-removebg-preview.png"
import { Row, Col } from "react-bootstrap";

function FormCard({ children }) {
  return (
    <div className="form-card-backdrop">
      <Row className="form-card-wrapper ">
        <div className="container-left-login">
          <div className="form-card-cover">
            <p className="form-card-image-text">Seja bem-vindo </p>
            <img className="petrolead-image"src={PetroleadIcon} alt="Petrolead icon" />

          </div>
          <div className="logo-libo">
<p className="form-card-image-text-libo">Por:</p>
          <img className="libo-image" src={LiboIcon} alt="Libo icon" ></img>
</div>
        </div>

        <div className="container-right-login">
          <Row
            className="d-flex flex-column justify-content-center align-items-center"
          >
            {children}
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default FormCard;

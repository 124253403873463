import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { connect } from "react-redux";
import * as storeActions from "../../../../../store/actions/index";
import "./styles.scss"

function CategoryFilters(props) {
  const [currentCategory, setCurrentCategory] = useState(props.category);

  // console.log(props.categoriesList)

  useEffect(() => {
    loadCategory()
  }, [props.category])

  async function loadCategory() {
    const categories = await props.initRegex(props.user);

    setCurrentCategory(categories.find((obj) => obj._id === props.category._id))
    // setCategoryData(categories.filter((obj) => obj._id === props.category._id))
  }

  async function updateCategory(updatedCategory) {
    setCurrentCategory(updatedCategory)

    const regex = {
      _id: updatedCategory._id,
      name: updatedCategory.name,
      disabled: updatedCategory.disabled,
      dashboard: updatedCategory.dashboard,
      subFilter: updatedCategory.subFilter,
      userId: updatedCategory.userId,
      companyId: updatedCategory.companyId,
      portalId: updatedCategory.portalId
    }
    await props.putRegex(regex, props.user);
    loadCategory();
  }

  async function exportCategory() {
    let items = await props.initCategory(props.user);
    items = items.filter(obj => { if (obj.catalogId === currentCategory._id) return obj }).sort((a, b) => {
      if (a.name > b.name) {
        return true
      } else {
        return -1
      }
    })

    let dictionary = {};

    items.map(obj => {
      if (!dictionary[obj.name]) {
        dictionary[obj.name] = {}
      }
    })

    let formData1 = Object.entries(dictionary).map(obj => {
      return {
        "Palavras-Chave": obj[0],
        ...obj[1]
      }
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet((formData1));

    const workSheets = {
      "Palavras-Chave": ws
    };

    const wb = { Sheets: workSheets, SheetNames: ['Palavras-Chave'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToDownload = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToDownload, "Palavras-Chave - " + currentCategory.name + fileExtension);

    // console.log(items)
  }

  return (
    <Card className="mb-1 limit-height">
      <Card.Body>
        <Card.Title>{currentCategory.name}</Card.Title>
        <Card.Text>
          <ListGroup className="" variant="flush">
            <Row className="p-3">
              <Col>Sub-Filtro</Col>
              <Col>Dashboard</Col>
              <Col>Ativo</Col>
              <Col>Exportar</Col>
            </Row>
            <ListGroup.Item>
              <Row>
                <Col>
                  <button
                    onClick={() => { updateCategory({ ...currentCategory, subFilter: !currentCategory.subFilter }) }}
                    className={(currentCategory.subFilter === true) ? "btn btn-hover text-flat btn-circle" : "btn btn-hover text-danger btn-circle"}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={(currentCategory.subFilter === true) ? faToggleOn : faToggleOff}
                    />
                  </button>
                </Col>
                <Col>
                  <button
                    onClick={() => { updateCategory({ ...currentCategory, dashboard: !currentCategory.dashboard }) }}
                    className={(currentCategory.dashboard === true) ? "btn btn-hover text-flat btn-circle" : "btn btn-hover text-danger btn-circle"}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={(currentCategory.dashboard === true) ? faToggleOn : faToggleOff}
                    />
                  </button>
                </Col>
                <Col>
                  <button
                    onClick={() => { updateCategory({ ...currentCategory, disabled: !currentCategory.disabled }) }}
                    className={(currentCategory.disabled === true) ? "btn btn-hover btn-hover text-danger btn-circle" : "btn btn-hover text-flat btn-circle"}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={(currentCategory.disabled === true) ? faToggleOff : faToggleOn}
                    />
                  </button>
                </Col>
                <Col>
                  <button
                    onClick={exportCategory}
                    type="button"
                    className="btn btn-hover text-primary btn-circle"
                  >
                    <FontAwesomeIcon className="" size="lg" icon={faDownload} />
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCategory: (user) => dispatch(storeActions.initCategory(user)),
    initRegex: (token) => dispatch(storeActions.initRegex(token)),
    postRegex: (regex, token) => dispatch(storeActions.postRegex(regex, token)),
    putRegex: (regex, token) => dispatch(storeActions.putRegex(regex, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilters);

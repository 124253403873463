import "./App.scss";
import React, { Component } from "react";
import SideMenu from "./components/SideMenu/SideMenu";
import TopMenu from "./components/TopMenu/TopMenu";

import DashboardOportunidades from "./components/pages/DashboardOportunidades/DashboardOportunidades";

import Oportunidade from "./components/pages/Oportunidade/Oportunidade";
import NotFound from "./components/pages/NotFound/NotFound";
import MyAccount from "./components/pages/MyAccount/MyAccount";
import Users from "./components/pages/Users/Users";
import Company from "./components/pages/Company/Company";
import Dashboard from "./components/pages/Dashboards/Dashboard";
import Businessinteligence from "./components/pages/Businessinteligence/Businessinteligence";
import Ranking from "./components/pages/Ranking/Ranking";
import { Catalog } from "./components/pages/Catalog";
import Portals from "./components/pages/Portals/Portals";
import Decline from "./components/pages/Decline/Decline";

import LoginScreen from "./components/pages/Login/Login";
import SignInScreen from "./components/pages/SignUp/SignUp";
import SignUpGuest from "./components/pages/SignUp/SignUpGuest/SignUpGuest";
import ForgotPassword from "./components/pages/ForgotPassword/ForgotPassword";
import PasswordReset from "./components/pages/PasswordReset/PasswordReset";


import Index from "./components/pages/Index/Index";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SideNotification from "./components/Modals/Notification/SideNotification";
import LoadingResources from "./components/Modals/Loading/LoadingResources";
// import Breadcrumb from "./components/Base/Breadcrumb/Breadcrumb";

// import * as storeActions from "./store/actions/index";

// import Sidebar from "./components/Teste/Side";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

class App extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let integrityCheck = localStorage.getItem("integrity");

    if(integrityCheck === "true"){
      localStorage.removeItem("reduxStore");
      localStorage.removeItem("private");
      localStorage.removeItem("dataFetched");
      localStorage.removeItem("integrity");
      window.location.reload();
    }


    let mainContent;
    let loadingScreen;

    let origin = window.location.origin;

    // console.log(origin)

    if (this.props.loading) {
      loadingScreen = <LoadingResources />;
    }

    if (localStorage.dataFetched === 'true') {

      mainContent = (
        <div className="d-flex flex-column">
          {loadingScreen}
          <SideMenu />
          <TopMenu />
          <Container
            className="main-content-container m-0 normalize-main-container"
            style={{ minHeight: "100vh", position: "relative" }}
            id="main-content-container"
            fluid
          >
            <Row className="m-0">
              <Col xs={12} className="m-0 h-100">
                <Switch>
                  <Route
                    path="/oportunidades"
                    exact
                    component={DashboardOportunidades}
                  />
                  <Route
                    path="/oportunidades/:id"
                    exact
                    component={Oportunidade}
                  />
                  {/* DESATIVADO */}
                  {/* <Route
                    path="/businessinteligence"
                    exact
                    component={Businessinteligence}
                  /> */}
                  <Route
                    path="/ranking"
                    exact
                    component={Ranking}
                  />
                  <Route path="/minhaconta" exact component={MyAccount} />
                  <Route path="/usuarios" exact component={Users} />
                  <Route path="/empresa" exact component={Company} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/catalogo" exact component={Catalog} />
                  <Route path="/decline" exact component={Decline} />
                  <Route path="/portais" exact component={Portals} />
                  <Route component={NotFound} />
                </Switch>
                <Redirect from="/login" to="/oportunidades" />
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      mainContent = (
        <>
          <Switch>
            <Route path="/login" exact component={LoginScreen} />
            <Route path="/inicio" exact component={Index} />

            {/* FUNCIONALIDADE DE CADASTRO DE NOVAS CONTAS => DESATIVADO */}
            
            <Route path="/signupguest" exact component={SignUpGuest} />
            <Route path="/signup" exact component={SignInScreen} />
            
            <Route path="/passwordforget" exact component={ForgotPassword} />
            <Route path="/passwordreset" exact component={PasswordReset} />
            <Redirect to="/inicio" />
          </Switch>
        </>
      );
    }

    return (
      <div className="App">
        <SideNotification />
        <BrowserRouter>{mainContent}</BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    opportunities: state.opportunities.opportunities,
    portals: state.portals.portals,
    companies: state.companies.companies,
    items: state.items.items,
    parts: state.parts.parts,
    proposals: state.proposals.proposals,
    dataFetched: state.control.dataFetched,
    loading: state.control.loading,
  };
};

export default connect(mapStateToProps)(App);
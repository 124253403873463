import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchPastOpportunitiesStart = () => {
  return {
    type: actionTypes.FETCH_PAST_OPPORTUNITIES_START,
  };
};

export const fetchPastOpportunitiesSuccess = (pastOpportunities) => {
  return {
    type: actionTypes.FETCH_PAST_OPPORTUNITIES_SUCCESS,
    pastOpportunities: pastOpportunities,
  };
};

export const fetchPastOpportunitiesFailed = () => {
  return {
    type: actionTypes.FETCH_PAST_OPPORTUNITIES_FAILED,
  };
};

export const setPastOpportunities = (userId, companyId, dateFrom, dateTo, index) => {
  return (dispatch) => {
    dispatch(fetchPastOpportunitiesStart());
    return new Promise((resolve, reject) => {

      ServeServices.getPastOpportunities(userId, companyId, dateFrom, dateTo, index)
        .then((response) => {
          dispatch(fetchPastOpportunitiesSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchPastOpportunitiesFailed());
          reject(err);
        });

    });
  };
};
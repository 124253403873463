import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchOpportunityStatusStart = () => {
        return {
                type: actionTypes.FETCH_OPPORTUNITY_STATUS_START,
        };
};

export const fetchOpportunityStatusSuccess = (opportunityStatus) => {
        return {
                type: actionTypes.FETCH_OPPORTUNITY_STATUS_SUCCESS,
                opportunityStatus: opportunityStatus,
        };
};

export const fetchOpportunityStatusFailed = () => {
        return {
                type: actionTypes.FETCH_OPPORTUNITY_STATUS_FAILED,
        };
};

export const initOpportunityStatus = (usuario, dateFrom, dateTo) => {
        return (dispatch) => {
                dispatch(fetchOpportunityStatusStart());
                return new Promise((resolve, reject) => {

                        ServeServices.getOpportunityStatus(usuario, dateFrom, dateTo)
                                .then((response) => {
                                        dispatch(fetchOpportunityStatusSuccess(response));
                                        resolve(response);
                                })
                                .catch((err) => {
                                        localStorage.removeItem("reduxStore");
                                        localStorage.removeItem("private");
                                        localStorage.removeItem("dataFetched");
                                        window.location.reload();
                                        dispatch(fetchOpportunityStatusFailed());
                                        reject(err);
                                });

                });
        };
};

export const initOneOpportunityStatus = (usuario, opId) => {
        return (dispatch) => {
                dispatch(fetchOpportunityStatusStart());
                return new Promise((resolve, reject) => {

                        ServeServices.getOneOpportunityStatus(usuario, opId)
                                .then((response) => {
                                        dispatch(fetchOpportunityStatusSuccess(response));
                                        resolve(response);
                                })
                                .catch((err) => {
                                        localStorage.removeItem("reduxStore");
                                        localStorage.removeItem("private");
                                        localStorage.removeItem("dataFetched");
                                        window.location.reload();
                                        dispatch(fetchOpportunityStatusFailed());
                                        reject(err);
                                });

                });
        };
};

export const setOpportunityStatus = (usuario, data) => {
        return (dispatch) => {
                dispatch(fetchOpportunityStatusStart());
                return new Promise((resolve, reject) => {

                        ServeServices.postOpportunityStatus(usuario, data)
                                .then((response) => {
                                        dispatch(fetchOpportunityStatusSuccess(response));
                                        resolve(response);
                                })
                                .catch((err) => {
                                        localStorage.removeItem("reduxStore");
                                        localStorage.removeItem("private");
                                        localStorage.removeItem("dataFetched");
                                        window.location.reload();
                                        dispatch(fetchOpportunityStatusFailed());
                                        reject(err);
                                });

                });
        };
};

import "./DashboardOportunidades.scss";
import React, { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import Card from "../../Cards/Card";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import MyTable from "../../Tables/MyTable/DataTableDashboardOpportunities";
import SourcesMenu from "./SourcesMenu/SourcesMenu";
import NavBreadcrumb from "../../Base/Breadcrumb/Breadcrumb";
// import SearchInput from "./OpportunityFilter/SearchInput/SearchInput";
import DataFilter from "./OpportunityFilter/jsx/DataFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUndo } from "@fortawesome/free-solid-svg-icons";
import OpportunityStatus from "./OpportunityFilter/jsx/OpportunityStatus";
import { Tooltip } from '@mui/material';
export function resetFiltros(){
  window.location.href = window.location.href + "?reset=1";
}

const timestampToDate = (dataIn) => {
  try {
    let data = new Date(dataIn * 1000)
    data.setHours(data.getHours() - 3)
    data = data.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
    let newData = data.split(" ");
    let newFormData = newData[0].split("-");
    let outPutData = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
    return outPutData;
  }
  catch (e) {
    return "31/12/2099 23:59:59"
  }
}

const formatTableData = (data, items, opNumber) => {

  if (Number(opNumber) > 7000000000 && Number(opNumber) < 7009999999) {
    data = [data.find(el => Number(el.index) === Number(opNumber))];
  }

  try {
    const array = data.map((el) => {
      const oppItems = items.filter((item) => item.opportunityId._id === el._id);
      const itemSize = oppItems.length;
      const result = {
        index: el.index,
        multiple: el.multiple,
        description: el.description,
        declineQueue: el.declineQueue,
        declined: el.declined,
        dateStart: timestampToDate(el.dateStart),
        dateEnd: timestampToDate(el.dateEnd),
        status: el.status,
        items: itemSize,
        localStatus: el.localStatus,
        origin: el.origin,
        portalId: el.portalId,
        gmt: el.gmt,
        criterioJulgamento: el.judgmentCriteriom,
        _id: el._id,
        active: el.viewed
      };
      return result;
    });
    return array;
  } catch (err) {
    return [];
  }
};

const filterByList = (inputData, arrOpps) => {
  if (inputData.length > 0 && arrOpps) {
    return inputData.filter(obj => arrOpps.includes(obj.index))
  } else {
    return inputData;
  }
}

const filterByRefArray = (filters, data, ref, selector) => {
  if (filters.length > 0) {
    let refFiltered = [];
    for (let i = 0; i < filters.length; i++) {
      refFiltered = refFiltered.concat(ref.filter(el => el[selector] === filters[i]));
    }
    let filtered = [];
    refFiltered.forEach((el) => {
      data.forEach((item) => {
        if (item._id === el.opportunityId._id) {
          filtered.push(item);
        }
      });
    });
    return filtered;
  }
  return [];
}

const filterByDate = (di, df, data, formSearch) => {
  let filtered = [];

  if (di !== "") {
    const today = df;
    // const tomorrow = new Date(today)
    let newDateFrom = new Date(di + " 00:00:00");
    let newDateTo = (df === "") ? (new Date(di + " 23:59:59")) : (new Date(df + " 23:59:59"));

    data.map((item) => {
      if (formSearch === "Data Início") {
        let newDateStart = new Date(item.dateStart * 1000);
        newDateStart.setHours(newDateStart.getHours() - 3);
        if (newDateStart.getTime() >= newDateFrom.getTime() && newDateStart.getTime() <= newDateTo.getTime()) {
          filtered.push(item);
        }
      } else if (formSearch === "Data Fim") {
        let newDateEnd = new Date(item.dateEnd * 1000);
        newDateEnd.setHours(newDateEnd.getHours() - 3);
        if (newDateEnd.getTime() >= newDateFrom.getTime() && newDateEnd.getTime() <= newDateTo.getTime()) {
          filtered.push(item);
        }
      } else {
        filtered.push(item);
      }
    });
    return filtered;
  } else {
    return data;
  }
}

const dataClick = (obj, props) => {
  try {
    props.setUserInteraction(props.user, obj._id)
    props.history.push(props.location.pathname + "/" + obj.index.props.children);
  } catch (err) {
    localStorage.removeItem("reduxStore");
    localStorage.removeItem("private");
    localStorage.removeItem("dataFetched");
    window.location.reload();
  }
};

const portalSet = (props, portalSelect) => {
  if (portalSelect === "") {
    return (props.opportunities.filter((el) => el.portalId === props.portals[0]._id));
  } else if (portalSelect !== "") {
    return (props.opportunities.filter((el) => el.portalId === portalSelect));
  }
}

const itemSet = (props, originTab) => {
  let output = []
  props.items.map(opItem => {
    let opportunityObj = props.opportunities.find(o => opItem.opportunityId === o._id)
    let portalObj = props.portals.find(o => opItem.portalId === o._id)

    opItem["opportunityId"] = !opportunityObj ? opItem["opportunityId"] : opportunityObj;
    opItem["portalId"] = !portalObj ? opItem["portalId"] : portalObj;

    if (opItem["opportunityId"].origin === originTab) {
      output.push(opItem);
    }

  })
  return (output);
}

const setOpportunityStyle = (tableData, opportunityListWithWarning, opportunityListWithImportantItems) => {
  let output = [];
  tableData.map(dataItem => {
    let tintText = (opportunityListWithWarning.includes(dataItem["index"])) ? "text-danger" : "text-primary"
    dataItem["index"] = (<span className={tintText}>{dataItem["index"]}</span>);
    output.push(dataItem);
  });
  return output;
}

const seeRejectableOpportunities = (tableData, rejectableOpps, active) => {
  let output = [];
  tableData.map(op => {
    if (rejectableOpps.some(probe => probe.index === op.index)) {
      output.push(op);
    }
  })
  return active ? output : tableData;
}

const setOriginObject = (inputData) => {
  let originMap = inputData.map((o) => { return o.origin })
  let originObj = {}

  originMap.map(o => {
    if (!originObj.hasOwnProperty(o)) {
      originObj[o] = 0;
    }
    if (originObj.hasOwnProperty(o)) {
      originObj[o]++;
    }
  });
  return originObj;
}

const filterByOpNumber = (opNumber, tableData) => {
  let filtered = []

  if (opNumber !== "") {
    if (Number(opNumber) > 7000000000) {
      filtered = [tableData.find((item) => Number(item.index) === Number(opNumber))];
    }
  }
  return (filtered[0] == undefined ? [] : filtered);
}

const filterByRegex = (regexBTN, tableData, dataItems) => {
  let filtered = []
  let listOpportunity = []
  if (regexBTN !== "") {
    dataItems.map((item) => {
      let fullDescription = item.fullDescription.toLowerCase();
      let partNumber = item.partNumber.toLowerCase();
      let regexItem = regexBTN;

      if (regexItem !== "") {
        if (fullDescription.split(regexItem.toLowerCase()).length > 1 || partNumber.split(regexItem.toLowerCase()).length > 1) {
          if (!listOpportunity.includes(item.opportunityId.index)) {
            listOpportunity.push(item.opportunityId.index);
            filtered.push(item.opportunityId);
          }
        }
      }
    });
  }
  return filtered;
}

function Dashboard(props) {
  const [tableData, setTableData] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [inputData, setInputData] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [opportunityListWithWarning, setOpportunityListWithWarning] = useState([]);
  const [opportunityListWithImportantItems, setOpportunityListWithImportantItems] = useState([]);
  const [regexFilter, setRegexFilter] = useState([]);
  const [mainSelected, setMainSelected] = useState([]);
  const [adressFilters, setAdressFilters] = useState([]);
  const [rejectableOpps, setRejectableOpps] = useState([]);
  const [destinyRegex, setDestinyRegex] = useState("");
  const [customRegex, setCustomRegex] = useState("");
  const [regexButton, setRegexButton] = useState("");
  const [originTab, setOriginTab] = useState(JSON.parse(localStorage.getItem("memory"))?.originTab ? JSON.parse(localStorage.getItem("memory")).originTab : "Minhas Participações");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [opNumber, setOpNumber] = useState("");
  const [opNumberField, setOpNumberField] = useState("");
  const [opProduct, setOpProduct] = useState("");
  const [opSearchRegex, setOpSearchRegex] = useState("");
  const [showRejectable, setShowRejectable] = useState(false);
  const [categorySelected, setCategorySelected] = useState("");
  const [selectedPortal, setSelectedPortal] = useState("");
  const [formSearch, setFormSearch] = useState("Nº da Oportunidade");
  const [mainManufacturer, setMainManufacturer] = useState("");
  const [arrOpps, setArrOpps] = useState(JSON.parse(localStorage.getItem("memory"))?.arrOpps ? JSON.parse(localStorage.getItem("memory")).arrOpps : []);
  const [arrOppsItems, setArrOppsItems] = useState([]);
  const [arrOppsWarn, setArrOppsWarn] = useState([]);
  const [modalStatusOpportunity, setModalStatusOpportunity] = useState(null)
  const [regexBTN, setRegexBTN] = useState("");
  const [opportunityStatus, setOpportunityStatus] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [opportunitiesColumns, setOpportunitiesColumns] = useState(
    [{
      name: "Número",
      selector: "index",
      sortable: true,
    },
    {
      name: "Desc. do Objeto",
      selector: "description",
      sortable: true,
      overlay: true
    },
    {
      name: "Dt Início",
      selector: "dateStart",
      sortable: true,
    },
    {
      name: "Dt Fim",
      selector: "dateEnd",
      sortable: true,
    }, {
      name: "Status",
      selector: "status",
      sortable: false,
    }, {
      name: "Origem",
      selector: "origin",
      sortable: true,
    }, {
      name: "Ação",
      selector: "action",
      sortable: false,
    }
    ]
  )
  let customRegexF = [];

  useEffect(() => {
    resetEvent()
  }, [window.location.href])


  useEffect(() => {
    var futureDate = new Date()

    props.initOpportunityStatus(props.user, (new Date().getTime() / 1000), (futureDate.setDate(futureDate.getDate() + 7) / 1000)).then(value => {
      // console.log(value)
      setOpportunityStatus(value);
    })
  }, [opportunityStatus.length === 0]);

  useEffect(() => {
    props.initStatusList(props.user).then(value => {
      setStatusList(value);
    })
  }, [statusList.length === 0]);

  useEffect(() => {
    if (!localStorage.getItem("memory")) {
      localStorage.setItem("memory", JSON.stringify({
        "originTab": originTab,
        "regexButton": regexButton,
        "opportunityListWithWarning": opportunityListWithWarning,
        "arrOpps": arrOpps,
        "showRejectable": showRejectable,
      }))
    } else {

      let valores = JSON.parse(localStorage.getItem("memory"));
      if (originTab !== valores.originTab || (regexButton !== valores.regexButton && regexButton !== "")) {
        localStorage.setItem("memory", JSON.stringify({
          "originTab": originTab,
          "regexButton": regexButton,
          "opportunityListWithWarning": opportunityListWithWarning,
          "arrOpps": arrOpps,
          "showRejectable": showRejectable,
        }))
      } else {
        setOriginTab(valores.originTab);
        setRegexButton(valores.regexButton);
        setOpProduct(valores.regexButton);
        setOpportunityListWithWarning(valores.opportunityListWithWarning);
        setArrOpps(valores.arrOpps);
        setShowRejectable(valores.showRejectable);
      }
    }
    // Seleciona dados do portal selecionado

    setInputData(portalSet(props, props.user.data.selectedPortalId));

    // Merge entre Oportunidades e Itens
    setDataItems(itemSet(props, originTab));
    // Filtros disponíveis
    const byDateF = filterByDate(dateFrom, dateTo, inputData, formSearch);
    const originF = byDateF.filter(o => o.origin === originTab);

    if (opProduct !== "") {
      
      customRegexF = filterByRegex(regexButton, tableData, dataItems);
      // console.log("Filter by Regex", customRegexF.length)
    } else {
      customRegexF = []
    }
    const destinyF = filterByRefArray(adressFilters, originF, dataItems, 'destinyZone');
    const customF = filterByList(inputData, arrOpps);
    // Unifica todos os dados do filtro
    let lake = (customF.length > 0) ? customF : originF;
    lake = (customRegexF.length > 0) ? customRegexF : lake;
    // lake = (destinyF.length > 0) ? destinyF : lake;
    // Verificando se devemos acionar o filtro
    var filtersApplied = true;
    if (
      destinyF.length === 0 &&
      customF.length === 0 &&
      byDateF.length === 0 &&
      originF.length === 0 &&
      customRegexF.length === 0 &&
      destinyRegex === "" &&
      customRegex === ""
    ) {
      filtersApplied = false;
    }

    setOriginList(setOriginObject(inputData));
    // Sinalização de cores nos números de oportunidade e 
    setTableData(setOpportunityStyle(
      formatTableData(                              // Formata para tabela
        seeRejectableOpportunities(                 // Mostra oportunidades não filtradas
          (filtersApplied ? lake : inputData),
          rejectableOpps,
          showRejectable),
        props.items,
        opNumber                                    // Oportunidade específica
      ),
      opportunityListWithWarning,                   // Lista de oportunidades prestes a vencer
      opportunityListWithImportantItems            // Oportunidades com itens de interesse (amarelo)
    ));

    
      setRegexFilter(props.regex.map((o) => {o["category"] = props.category.filter((obj) => obj.catalogId === o._id); return o}))
  }, [opNumber != "", adressFilters.length !== 0, dateFrom, dateTo, regexButton, opNumber !== "", showRejectable, originTab, tableData.length !== 0, inputData.length !== 0, dataItems.length !== 0]);

  function resetFilters() {
    setDateFrom("");
    setDateTo("");
    setOpNumber("");
    setRegexButton("");
    setArrOpps([]);
    setOpportunityListWithWarning([]);
    setOpNumberField("")
    setOpNumber("")
    setOpProduct("")
    setOpSearchRegex("")
    setFormSearch("Nº da Oportunidade");
    // console.log("ok");
  }

  let originComponent = [];
  Object.keys(originList).forEach(key => {

let legenda = "";
if(key === "Minhas Participações"){
legenda = "Oportunidades privadas de acordo com CRC cadastrado"
}
else if(key === "Oportunidades Públicas"){
legenda = "Oportunidades públicas de alcance nacional"
}
    originComponent.push(
      <Tooltip title={legenda}>
      <Button
        variant="flat"
        onClick={() => {
          setOriginTab(key);
          resetFilters();
          setShowRejectable(false);
          localStorage.removeItem("memory");
          localStorage.removeItem("dayOpps");
          localStorage.removeItem("selectedPage");
        }}>
        <p>{key}<br></br>( {originList[key]} )</p>
      </Button>
      </Tooltip>
    )
  })

  function faSearchAction() {
    localStorage.setItem("selectedPage", 0)
    setRegexButton(opProduct)

    setShowRejectable(false);
  }

  function handleResetFilter() {
    setDestinyRegex("");
    setOpNumber("")
    setOpProduct("")
    setOpSearchRegex("")
    resetFilters();
    localStorage.removeItem("memory");
    localStorage.removeItem("dayOpps");
    setAdressFilters([]);
    setShowRejectable(false);
    setFormSearch("Nº da Oportunidade");
  }

  // Reset de filtros
  function resetEvent() {
    setDestinyRegex("");
    resetFilters();

    //Desenvolver mecanismo automático
    setOpNumber("")
    setOpProduct("")
    setOpSearchRegex("")
    setFormSearch("Nº da Oportunidade");

    let comeBackToFilter = "Minhas Participações";

    if (props.opportunities.some(probe => probe.origin === "Inbox")) {
      comeBackToFilter = "Inbox";
    } else if (props.opportunities.some(probe => probe.origin === "Aberto")) {
      comeBackToFilter = "Aberto";
    }
    // console.log(comeBackToFilter)
    setOriginTab(comeBackToFilter);

    localStorage.removeItem("memory");
    localStorage.removeItem("dayOpps");
    setAdressFilters([]);
    setShowRejectable(false);
  }


  // DESENVOLVER GET DE STATUS PARA CARREGAR AO ATUALIZAR A PAGINA
  const tableDataLocal = tableData.map(o => {
    // console.log(o["localStatus"])
    
    if(statusList.length > 0 && opportunityStatus.length > 0){
      if(opportunityStatus.some(probe => probe.opportunityId === o._id)){
        // console.log(o)
        o["localStatus"] = [opportunityStatus.find(probe => probe.opportunityId === o._id)];
        // console.log(o)
        o["localStatus"][0]["statusName"] = statusList.find(probe => probe._id === o["localStatus"][0]["statusListId"]);
      }
    }
    

    if (o["localStatus"].length > 0) {
      // Oportunidade declinada
      o["action"] = (<>
      <button type="button" disabled={o.declined || o.origin !== "Minhas Participações"} onClick={() => setModalStatusOpportunity(o)} className={"btn btn-" + o.localStatus[0].statusName.classification}>{!o.declined?o.localStatus[0].statusName.name:''} {o.declined ? "Declinada" : ""}</button>
      {/* <button className="btn btn-primary"><FontAwesomeIcon size="lg" icon={faUndo} /></button> */}
      </>)
    } else {
      // Oportunidade classificada para declínio, mas fora da fila
      o["action"] = (<button type="button" disabled={o.declined || o.origin !== "Minhas Participações"} onClick={() => setModalStatusOpportunity(o)} className="btn btn-primary">Ação {o.declined ? " (Declinada)" : ""}</button>)
    }

    // o["manufacturers"] = props.regex.filter(probe => probe.subFilter === false && probe.dashboard === true).filter(probe => probe.category.filter(cat => props.items.filter(probe => probe.fullDescription.toLowerCase().includes(cat.name.toLowerCase()))))
    // console.log(o["manufacturers"])
    return o;
  })


  return (
    <div className="limitador">
      {/* Container global */}  
      {/* <SourcesMenu
          handlerEvent={setSelectedPortal}
          baseEvent={() => {
            props.fetchData(props.user)
          }}
          resetEvent={() => {
            setDestinyRegex("");
            resetFilters();

            //Desenvolver mecanismo automático
            setOpNumber("")
            setOpProduct("")
            setOpSearchRegex("")
            setFormSearch("Nº da Oportunidade");

            let comeBackToFilter = "Minhas Participações";

            if (props.opportunities.some(probe => probe.origin === "Inbox")) {
              comeBackToFilter = "Inbox";
            } else if (props.opportunities.some(probe => probe.origin === "Aberto")) {
              comeBackToFilter = "Aberto";
            }
            // console.log(comeBackToFilter)
            setOriginTab(comeBackToFilter);

            localStorage.removeItem("memory");
            localStorage.removeItem("dayOpps");
            setAdressFilters([]);
            setShowRejectable(false);
          }}
          clearEvent={setMainSelected}
        />  */}
      <div className="dashboard-wrapper">
        {/* Side menu */}
       
        {/* Container */}
        <div>
          {/* Breadcrumb de navegação */}
  <div className="mt-4 "> </div>
          {/* Container */}
          <div className="pl-5 pr-5">
            {/* Parâmetros de pesquisa */}
            <InputGroup className="container-header">
              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={formSearch}
                className="select-search"
              >
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Nº da Oportunidade</p></Dropdown.Item>
                {originTab === "Minhas Participações" ?<Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Produto</p></Dropdown.Item>:null}
                {/* <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Endereço</p></Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Data Início</p></Dropdown.Item> */}
                {/* <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Data Fim</p></Dropdown.Item> */}
              </DropdownButton>
              {originTab === "Minhas Participações" ?<FormControl
                placeholder="Produto"
                aria-label="Produto"
                type="text"
                value={opSearchRegex}
                onChange={(e) => { setOpSearchRegex(e.target.value) }}
                className={(formSearch === "Produto") ? "form-style" : "display-none"}
              />:null}
              {/* <FormControl
                placeholder="Endereço"
                aria-label="Endereço"
                type="text"
                value={adressFilters}
                onChange={(e) => { setAdressFilters(e.target.value) }}
                className={(formSearch === "Endereço") ? "form-style" : "display-none"}
              />
              <FormControl
                placeholder="De"
                aria-label="De"
                type="date"
                aria-describedby="basic-addon1"
                value={dateFrom}
                onChange={(e) => { setDateFrom(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "form-style" : "display-none"}
              />
              <FormControl
                placeholder="Até"
                aria-label="Até"
                type="date"
                aria-describedby="basic-addon1"
                value={dateTo}
                onChange={(e) => { setDateTo(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "form-style" : "display-none"}
              /> */}
              <FormControl
                placeholder="Nº da Oportunidade"
                aria-label="Nº da Oportunidade"
                type="text"
                pattern={/^[0-9\b]+$/}
                // min={7000000000}
                // max={7009999999}
                aria-describedby="basic-addon1"
                value={opNumberField}
                onChange={(e) => {
                  setOpNumberField(e.target.value)
                }}
                className={(formSearch === "Nº da Oportunidade") ? "form-style" : "display-none"}
              />
              <InputGroup.Append>
                <Button
                  className="button-search btn-flat-search"
                  variant="flat"
                  onClick={() => { 
                    // faSearchAction()
                    // handleResetFilter();
                    // localStorage.setItem("selectedPage", 0)
                    
                    if(opNumberField == ""){
                      setRegexButton(opSearchRegex)
                      setOpProduct(opSearchRegex)
                    }else{
                      setOpNumber(opNumberField)
                    }
                    
                   }}
                >
                  <FontAwesomeIcon size="lg" icon={faSearch} />
                </Button>
              </InputGroup.Append>
              <div className="button-filter">
                {originComponent}
                <Tooltip title="Oportunidades que não passaram pelos filtros de palavra-chave">
                <Button
                  className="btn-flat btn-flat-not"
                  variant="flat"
                  onClick={() => {
                    resetFilters();
                    localStorage.removeItem("memory");
                    localStorage.removeItem("selectedPage");
                    localStorage.removeItem("dayOpps");
                    setShowRejectable(!showRejectable);
                  }}><p>Oportunidades não filtradas<br></br>( {rejectableOpps.length} )</p></Button>
                  </Tooltip>
                  </div>
                  
              
            </InputGroup>
            {/* Seleção de abas */}
            <Row className="p-0">
              <Col className="ml-3">
                <Row>
                  <div className="legend-yellow"></div> <h6>Oportunidade com prioridade para cotação</h6>
                </Row>
                <Row>
                  <div className="legend-red"></div> <h6>Oportunidades à vencer em 3 dias</h6>
                </Row>
                <Row>
                  <div className="legend-blue"></div> <h6>Total de oportunidades</h6>
                </Row>
              </Col>
              </Row>
              <Row className="p-0">
                <Col className="">
                  <div className="grouped-cards">
                    {/* Componente com os box e nome dos fabricantes */}
                    <DataFilter
                      param={props}
                      regexFilter={regexFilter}
                      regexBTN={regexButton}
                      tableData={tableDataLocal}
                      originSelected={originTab}
                      setRegexBTN={setRegexButton}
                      handleResetFilter={handleResetFilter}
                      setCategorySelected={setCategorySelected}
                      setMainManufacturer={setMainManufacturer}
                      setArrOppsItems={setOpportunityListWithImportantItems}
                      setArrOppsWarn={setOpportunityListWithWarning}
                      setArrOpps={setArrOpps}
                      setRejectableOpps={setRejectableOpps}
                      rejectableOpps={rejectableOpps}
                      setShowRejectable={setShowRejectable}
                    />
                  </div>
                </Col>
              </Row>
          </div>

          <div xs={12} className={(true) ? "m-0 p-3" : "m-0 p-3 display-none"}>
            <Card>
              <MyTable
                title="Oportunidades"
                data={tableDataLocal}
                sources={props.portals}
                columns={opportunitiesColumns}
                callback={(args) => dataClick(args, props)}
                pagination
                filter
                header
                mainManufacturer={regexButton}
                categorySelected={originTab}
                arrOppsItems={opportunityListWithImportantItems}
                setArrOppsWarn={opportunityListWithWarning}
                showRejectable={showRejectable}
                opItems={props.items}
                regex={props.regex}
                rejectableOpps={rejectableOpps}
                user={props.user}
                initStatusList={props.initStatusList}
                declineStatus={props.declineStatus}
                postDeclineOpportunity={props.postDeclineOpportunity}
                initOneOpportunityStatus={props.initOneOpportunityStatus}
                setOpportunityStatus={props.setOpportunityStatus}
              />
            </Card>
          </div>
        </div>
      </div>
      <OpportunityStatus
        setModalStatusOpportunity={(o => setModalStatusOpportunity(o))}
        modalStatusOpportunity={modalStatusOpportunity}
        statusList={(user) => props.initStatusList(user)}
        user={props.user}
        setOpportunityStatus={(user, data) => props.setOpportunityStatus(user, data)}
        initOneOpportunityStatus={(user, opId) => props.initOneOpportunityStatus(user, opId)}
        declineStatus={props.declineStatus}
        postDeclineOpportunity={props.postDeclineOpportunity}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    opportunities: state.opportunities.opportunities,
    error: state.opportunities.error,
    portals: state.portals.portals,
    items: state.items.items,
    proposals: state.proposals.proposals,
    regex: state.regex.regex,
    category: state.category.category,
    declineStatus: state.declineStatus.declineStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initOpportunities: () => dispatch(storeActions.initOpportunities()),
    initPortals: (token) => dispatch(storeActions.initPortals(token)),
    fetchData: (token) => dispatch(storeActions.fetchData(token)),
    setUserInteraction: (userId, companyId, opportunityId) => dispatch(storeActions.setUserInteraction(userId, companyId, opportunityId)),
    setPastOpportunities: (userId, companyId, dateFrom, dateTo) => dispatch(storeActions.setPastOpportunities(userId, companyId, dateFrom, dateTo)),
    initRegex: () => dispatch(storeActions.initRegex()),
    initOpportunityStatus: (usuario, dateFrom, dateTo) => dispatch(storeActions.initOpportunityStatus(usuario, dateFrom, dateTo)),
    initStatusList: (user) => dispatch(storeActions.initStatusList(user)),
    setOpportunityStatus: (user, data) => dispatch(storeActions.setOpportunityStatus(user, data)),
    initOneOpportunityStatus: (user, opId) => dispatch(storeActions.initOneOpportunityStatus(user, opId)),
    postDeclineOpportunity: (data, usuario) => dispatch(storeActions.postDeclineOpportunity(data, usuario)),
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps,)(Dashboard);
// OPPORTUNITIES
export const FETCH_OPPORTUNITIES_FAILED = "FETCH_OPPORTUNITIES_FAILED";
export const FETCH_OPPORTUNITIES_START = "FETCH_OPPORTUNITIES_START";
export const FETCH_OPPORTUNITIES_SUCCESS = "FETCH_OPPORTUNITIES_SUCCESS";

// DECLINE_STATUS
export const FETCH_DECLINE_STATUS_FAILED = "FETCH_DECLINE_STATUS_FAILED";
export const FETCH_DECLINE_STATUS_START = "FETCH_DECLINE_STATUS_START";
export const FETCH_DECLINE_STATUS_SUCCESS = "FETCH_DECLINE_STATUS_SUCCESS";

// DECLINE_OPPORTUNITY
export const FETCH_DECLINE_OPPORTUNITY_FAILED = "FETCH_DECLINE_OPPORTUNITY_FAILED";
export const FETCH_DECLINE_OPPORTUNITY_START = "FETCH_DECLINE_OPPORTUNITY_START";
export const FETCH_DECLINE_OPPORTUNITY_SUCCESS = "FETCH_DECLINE_OPPORTUNITY_SUCCESS";

// OPPORTUNITY STATUS
export const FETCH_OPPORTUNITY_STATUS_FAILED = "FETCH_OPPORTUNITY_STATUS_FAILED";
export const FETCH_OPPORTUNITY_STATUS_START = "FETCH_OPPORTUNITY_STATUS_START";
export const FETCH_OPPORTUNITY_STATUS_SUCCESS = "FETCH_OPPORTUNITY_STATUS_SUCCESS";

// STATUS LIST
export const FETCH_STATUS_LIST_FAILED = "FETCH_STATUS_LIST_FAILED";
export const FETCH_STATUS_LIST_START = "FETCH_STATUS_LIST_START";
export const FETCH_STATUS_LIST_SUCCESS = "FETCH_STATUS_LIST_SUCCESS";

// FEEDBACK
export const FETCH_FEEDBACK_FAILED = "FETCH_FEEDBACK_FAILED";
export const FETCH_FEEDBACK_START = "FETCH_FEEDBACK_START";
export const FETCH_FEEDBACK_SUCCESS = "FETCH_FEEDBACK_SUCCESS";

// USER
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

// INTEGRITY
export const FETCH_INTEGRITY_FAILED = "FETCH_INTEGRITY_FAILED";
export const FETCH_INTEGRITY_START = "FETCH_INTEGRITY_START";
export const FETCH_INTEGRITY_SUCCESS = "FETCH_INTEGRITY_SUCCESS";

// COMPANY
export const FETCH_COMPANY_FAILED = "FETCH_COMPANY_FAILED";
export const FETCH_COMPANY_START = "FETCH_COMPANY_START";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";

// SIGNUP
export const FETCH_SIGNUP_FAILED = "FETCH_SIGNUP_FAILED";
export const FETCH_SIGNUP_START = "FETCH_SIGNUP_START";
export const FETCH_SIGNUP_SUCCESS = "FETCH_SIGNUP_SUCCESS";

// PAST OPPORTUNITIES
export const FETCH_PAST_OPPORTUNITIES_FAILED = "FETCH_PAST_OPPORTUNITIES_FAILED";
export const FETCH_PAST_OPPORTUNITIES_START = "FETCH_PAST_OPPORTUNITIES_START";
export const FETCH_PAST_OPPORTUNITIES_SUCCESS = "FETCH_PAST_OPPORTUNITIES_SUCCESS";

// DASHBOARD
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";

// PURCHASES
export const FETCH_PURCHASES_FAILED = "FETCH_PURCHASES_FAILED";
export const FETCH_PURCHASES_START = "FETCH_PURCHASES_START";
export const FETCH_PURCHASES_SUCCESS = "FETCH_PURCHASES_SUCCESS";

// PORTALS
export const FETCH_PORTALS_FAILED = "FETCH_PORTALS_FAILED";
export const FETCH_PORTALS_START = "FETCH_PORTALS_START";
export const FETCH_PORTALS_SUCCESS = "FETCH_PORTALS_SUCCESS";


// Items
export const FETCH_ITEMS_FAILED = "FETCH_ITEMS_FAILED";
export const FETCH_ITEMS_START = "FETCH_ITEMS_START";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";

// Regex
export const FETCH_REGEX_FAILED = "FETCH_REGEX_FAILED";
export const FETCH_REGEX_START = "FETCH_REGEX_START";
export const FETCH_REGEX_SUCCESS = "FETCH_REGEX_SUCCESS";

// Category
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";
export const FETCH_CATEGORY_START = "FETCH_CATEGORY_START";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";

// User Interaction
export const FETCH_USER_INTERACTION_FAILED = "FETCH_USER_INTERACTION_FAILED";
export const FETCH_USER_INTERACTION_START = "FETCH_USER_INTERACTION_START";
export const FETCH_USER_INTERACTION_SUCCESS = "FETCH_USER_INTERACTION_SUCCESS";

// COMPANIES
export const FETCH_COMPANIES_FAILED = "FETCH_COMPANIES_FAILED";
export const FETCH_COMPANIES_START = "FETCH_COMPANIES_START";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";


// PARTS
export const FETCH_PARTS_FAILED = "FETCH_PARTS_FAILED";
export const FETCH_PARTS_START = "FETCH_PARTS_START";
export const FETCH_PARTS_SUCCESS = "FETCH_PARTS_SUCCESS";


// Proposals
export const FETCH_PROPOSALS_FAILED = "FETCH_PROPOSALS_FAILED";
export const FETCH_PROPOSALS_START = "FETCH_PROPOSALS_START";
export const FETCH_PROPOSALS_SUCCESS = "FETCH_PROPOSALS_SUCCESS";

// ProposalData
export const FETCH_PROPOSALDATA_FAILED = "FETCH_PROPOSALDATA_FAILED";
export const FETCH_PROPOSALDATA_START = "FETCH_PROPOSALDATA_START";
export const FETCH_PROPOSALDATA_SUCCESS = "FETCH_PROPOSALDATA_SUCCESS";

// Authentication 
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOG_OUT = "LOG_OUT";

// Notification 
export const NOTIFICATION_CREATE = "NOTIFICATION_CREATE";
export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";

// Controll
export const DATA_FETCHING_START = "DATA_FETCHING_START";
export const DATA_FETCHING_SUCCESS = "DATA_FETCHING_SUCCESS";
export const DATA_FETCHING_FAIL = "DATA_FETCHING_FAIL";
export const DATA_FETCHING_ERASE = "DATA_FETCHING_ERASE";
export const DATA_FETCHING_PRIVATE_CONTROL = "DATA_FETCHING_PRIVATE_CONTROL";

import React, { useState, useEffect } from "react"
import { Row, Col, DropdownButton, Dropdown, Container, InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import * as storeActions from "../../../store/actions/index";
import Card from "../../Cards/Card";
import SearchInput from "../Ranking/OpportunityFilter/SearchInput/SearchInput";
import MyTable from "../../Tables/MyTable/DataTableDecline"
import './Decline.scss'

function Decline(props) {

  const [loading, setLoading] = useState(false)
  const [formSearch, setFormSearch] = useState("Data Fim")
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [mesSelecionado, setMesSelecionado] = useState('Janeiro')
  const [anoSelecionado, setAnoSelecionado] = useState('2022')
  const [trimestreSelecionado, setTrimestreSelecionado] = useState('1º Trimestre')
  const [semestreSelecionado, setSemestreSelecionado] = useState('1º Semestre')
  const [_opNumber, _setOpNumber] = useState('')
  const [opNumber, setOpNumber] = useState('')
  const [data, setData] = useState(null)

  useEffect(() => {
    if (_opNumber > 7000000000 && _opNumber < 7009999999) {
      setOpNumber(_opNumber)
    }
  }, [_opNumber])

  /* Gerando anos automaticamente a partir de 2022 */
  let year = [];
  for (let i = 2022; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }

  async function handleGetDashboard() {
    setLoading(true);

    let numMes = 0;
    switch (mesSelecionado) {
      case "Janeiro":
        numMes = 1;
        break;
      case "Fevereiro":
        numMes = 2;
        break;
      case "Março":
        numMes = 3;
        break;
      case "Abril":
        numMes = 4;
        break;
      case "Maio":
        numMes = 5;
        break;
      case "Junho":
        numMes = 6;
        break;
      case "Julho":
        numMes = 7;
        break;
      case "Agosto":
        numMes = 8;
        break;
      case "Setembro":
        numMes = 9;
        break;
      case "Outubro":
        numMes = 10;
        break;
      case "Novembro":
        numMes = 11;
        break;
      case "Dezembro":
        numMes = 12;
        break;
      default:
        numMes = 1;
        break;
    }

    let _dateFrom = dateFrom.split("-")
    let _dateTo = dateTo.split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dashboardData = []
    let dateNow = new Date();
    let seletor = formSearch;

    if (seletor === "Data Fim") {
    } else if (seletor === "Mês") {
      newDateFrom = new Date(anoSelecionado, numMes - 1, 1, "0", "0", "0");
      dateNow.setMonth(numMes - 1);
      dateNow.setDate(1);
      let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");
    } else if (seletor === "Trimestre") {
      switch (trimestreSelecionado) {
        case ("1º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(0);
          let trimestre1 = new Date(anoSelecionado, dateNow.getMonth() + 4, 0);
          trimestre1.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre1.getMonth(), trimestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 3, 1, "0", "0", "0");
          dateNow.setMonth(4);
          let trimestre2 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre2.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre2.getMonth(), trimestre2.getDate() - 1, "23", "59", "59");
          break;
        case ("3º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let trimestre3 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre3.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre3.getMonth(), trimestre3.getDate() - 1, "23", "59", "59");
          break;
        case ("4º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 9, 1, "0", "0", "0");
          dateNow.setMonth(10);
          let trimestre4 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre4.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), trimestre4.getMonth(), trimestre4.getDate() - 1, "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(4);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
      }
    } else if (seletor === "Semestre") {
      switch (semestreSelecionado) {
        case ("1º Semestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let semestre1 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(anoSelecionado, semestre1.getMonth() - 1, semestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Semestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(13);
          let semestre2 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), semestre2.getMonth() - 1, semestre2.getDate(), "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
          break;
      }
    } else {
      newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
      newDateTo = new Date(anoSelecionado, 11, "31", "23", "59", "59");
    }
    await props.getDeclineOpportunity(props.user, props.user.data.companyId, newDateFrom / 1000, newDateTo / 1000, opNumber).then((val) => {
      dashboardData = val
    });
    setData(dashboardData)
    setLoading(false);
  }

  return (
    <Card>
      <Container fluid className="pl-10 pr-0">
        <Row className="container-search">
          <Col style={{ maxWidth: "100%" }}>
            <InputGroup className="container-date">
              <DropdownButton
                className="container-select-type"
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={formSearch}
              >
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Data Fim</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Mês</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Ano</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Nº da Oportunidade</p></Dropdown.Item>
              </DropdownButton>
              <SearchInput
                id="input-opo"
                title="Data"
                value={dateFrom}
                selector="dataStart"
                onChange={(e) => { setDateFrom(e.target.value) }}
                show={formSearch === "Data"}
              />

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={mesSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Janeiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Fevereiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Março</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Abril</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Maio</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Junho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Julho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Agosto</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Setembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Outubro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Novembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Dezembro</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={trimestreSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>1º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>2º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>3º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>4º Trimestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={semestreSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>1º Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>2º Semestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Ano" ? "type-year" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>
              <FormControl
                placeholder="De"
                aria-label="De"
                type="date"
                aria-describedby="basic-addon1"
                value={dateFrom}
                onChange={(e) => { setDateFrom(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <FormControl
                placeholder="Até"
                aria-label="Até"
                type="date"
                aria-describedby="basic-addon1"
                value={dateTo}
                onChange={(e) => { setDateTo(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <FormControl
                placeholder="Nº da Oportunidade"
                aria-label="Nº da Oportunidade"
                type="text"
                pattern={/^[0-9\b]+$/}
                min={7000000000}
                max={7009999999}
                aria-describedby="basic-addon1"
                value={_opNumber}
                onChange={(e) => {
                  if ((/^[0-9\b]+$/).test(e.target.value)) {
                    _setOpNumber(e.target.value)
                  }
                }}
                className={(formSearch === "Nº da Oportunidade") ? "type-opportunity" : "display-none"}
              />
              <InputGroup.Append>
                <Button variant="flat" onClick={async () => handleGetDashboard()}><FontAwesomeIcon size="lg" icon={faSearch} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>

        <Container>{(loading === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>

        <Row className="container-table">
          {data !== null && loading === false && data.opportunities ?
            <MyTable
              opportunities={data.opportunities}
              user={props.user}
            />
            :
            <>
            </>
          }

        </Row>
      </Container>
    </Card >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    portals: state.portals.portals,
    declineStatus: state.declineStatus.declineStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeclineOpportunity: (userId, companyId, dateFrom, dateTo, opNumber) => dispatch(storeActions.getDeclineOpportunity(userId, companyId, dateFrom, dateTo, opNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Decline);
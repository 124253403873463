import { RiCloseFill } from 'react-icons/ri';

import './styles.scss'

export function CloseButton(props) {
  return (
    <div className="container-button-close">
      <button className="close-button" onClick={() => { props.handleClickClose(false) }}>
        <RiCloseFill size="30px" />
      </button>
    </div>
  )
}
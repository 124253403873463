import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchUserInteractionStart = () => {
  return {
    type: actionTypes.FETCH_USER_INTERACTION_START,
  };
};

export const fetchUserInteractionSuccess = (userInteraction) => {
  return {
    type: actionTypes.FETCH_USER_INTERACTION_SUCCESS,
    userInteraction: userInteraction,
  };
};

export const fetchUserInteractionFailed = () => {
  return {
    type: actionTypes.FETCH_USER_INTERACTION_FAILED,
  };
};

export const setUserInteraction = (usuario, opportunityId) => {
  return (dispatch) => {
    dispatch(fetchUserInteractionStart());
    return new Promise((resolve, reject) => {

      ServeServices.setInteraction(usuario, opportunityId)
        .then((response) => {
          dispatch(fetchUserInteractionSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchUserInteractionFailed());
          reject(err);
        });

    });
  };
};
import React from "react";
import "./SearchInput.scss";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popover from '../../../../Base/Popover/Popover';

// import {Autocomplete} from '@material-ui';

const trimStr = (size, str) => {
  return str.substring(0, size) + ' ...';
}

function SearchInput({ id, title, data, selector, onChange, hint, show }) {
  const notRepeatedData = [];
  const maxSize = 20;

  if (!data) {
    data = [];
  }
  data.forEach((el) => {
    if (!notRepeatedData.includes(el[selector])) notRepeatedData.push(el[selector]);
  });

  return (
    <div className={(show === true)?(`auto-sizing`):"display-none"}>
      <Autocomplete
        id={id}
        onChange={(e, value) => onChange(value)}
        multiple
        limitTags={2}
        options={notRepeatedData}
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => {
          if (option.length > maxSize && hint) {
            return (
              <Popover title="" direction="left" content={option}>
                <p>{trimStr(maxSize, option)}</p>
              </Popover>
            )
          } else {
            return (
              <p>{option}</p>
            )
          }
        }}
        // getOptionLabel={(option) => option[selector]}
        // defaultValue={defaultSelection?defaultSelection:[]}

        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={"Pesquisar em \"" + title + "\""}
            placeholder={"Pesquisar em " + title}
          />
        )}
      />
    </div>
  );
}

export default SearchInput;

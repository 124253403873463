import React from 'react'
import ReactApexChart from "react-apexcharts"

export default function RequestBarAndLineChart(props) {

  let state = {
    series: [{
      name: 'Concorrentes',
      type: 'column',
      data: props.dataConcurrent
    },
    {
      name: props.nameCompany,
      type: 'line',
      data: props.dataCompany
    }],
    options: {
      chart: {
        height: 300,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [1, 1, 4]
      },
      title: {
        text: `Comparativo entre ${props.nameCompany} e concorrentes para o item selecionado`
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
        distributed: true,
        background: false
      },
      labels: props.labels,
      xaxis: {
        type: 'category'
      },
      theme: {
        monochrome: {
          enabled: true,
          color: '#524ec6',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      yaxis: [{
        title: {

        },
      }]
    },
  }

  return (
    <ReactApexChart
      className="row d-flex justify-content-center"
      options={state.options}
      series={state.series}
      type="line"
      height="450"
      width="800"
      id="line-and-column"
    />
  )
}
import React from "react";
import "./SideMenuItem.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import SideMenuSubItem from "./SideMenuSubItem/SubMenuSubItem";

function SideMenuItem({ isOpen, title, icon, link, subitems, id, active, handler }) {
  let subItemsContent;



  if (subitems && subitems.length > 0 && active) {
    subItemsContent = (
      <ul id="sidemenu-subitem-ul" className={`sidemenu-subitem-wrapper`}>
        {subitems.map((el) => (
          <SideMenuSubItem title={el.title} link={el.link} key={el.title} />
        ))}
      </ul>
    );
  }

  return (
    <li>
      <div>
        <NavLink
          to={link}
          className={`itemWrapper text-center pt-2 pb-2`}
          activeClassName="active-sidemenu-item"
          onClick={() => handler(id)}
        >
          <img src={icon} alt={title + "_icon"} className="logo" />
          <p className="ml-4 mb-0">{title}</p>
        </NavLink>
        {subItemsContent}
      </div>
    </li>
  );
}

export default SideMenuItem;

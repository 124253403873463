import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import "./Company.scss"

function Company(props) {
  const [name, setName] = useState()
  const [website, setWebsite] = useState()
  const [email, setEmail] = useState()
  const [cnpj, setCnpj] = useState()
  const [crc, setCrc] = useState('')
  const [logo, setLogo] = useState('')
  const [mainCompany, setMainCompany] = useState()
  const [parentCompanyId, setParentCompanyId] = useState()
  const [disabled, setDisabled] = useState()
  const [_id, set_id] = useState()

  const valuesCompany = {
    "name": name,
    "site": website,
    "cnpj": cnpj,
    "crc": crc,
    "logo": logo,
    "email": email,
    "mainCompany": mainCompany,
    "parentCompanyId": parentCompanyId,
    "disabled": disabled,
    "_id": _id
  }

  useEffect(() => {
    props.companies.map((company) => {
      if (company._id === props.user.data.companyId) {
        setName(company.name)
        setWebsite(company.site)
        setEmail(company.email)
        setCnpj(company.cnpj)
        setCrc(company.crc)
        setLogo(company.logo)
        setMainCompany(company.mainCompany)
        setParentCompanyId(company.parentCompanyId)
        setDisabled(company.disabled)
        set_id(company._id)
      }
    })
  }, [])

  //Função utilizada para carregar o crc como base64
  function loadCrc(e) {
    getBase64(e).then(result => {
      setCrc(result);
    })
  }

  //Função utilizada para carregar a logo como base64
  function loadLogo(e) {
    getBase64(e).then(result => {
      setLogo(result);
    })
  }

  // Função utilizada para converter arquivo em base64
  function getBase64(file) {
    return new Promise((resolve) => {
      let baseUrl = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        baseUrl = reader.result;
        resolve(baseUrl);
      })
    })
  }

  // Função para salvar as edições
  const saveEdits = async () => {
    // console.log(await props.putCompany(props.user, valuesCompany))
  }

  return (
    <Card>
      <Row className="justify-content-center mt-4">
        <h4>Dados da empresa</h4>
      </Row>
      <Form>
        <Row className="justify-content-center ml-3 mb-3">
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Nome da empresa" />
          </Form.Group>
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>Site</Form.Label>
            <Form.Control
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              type="text"
              placeholder="Site da empresa" />
          </Form.Group>
        </Row>

        <Row className="justify-content-center ml-3 mb-3">
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="E-mail da empresa" />
          </Form.Group>
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>CNPJ</Form.Label>
            <Form.Control
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              type="text"
              placeholder="CNPJ da empresa" />
          </Form.Group>
        </Row>

        <Row className="justify-content-center ml-3 mb-3">
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>CRC</Form.Label>
            <Col>
              <object data={crc}></object>
            </Col>
            <p></p>
            <Form.Control
              onChange={(e) => loadCrc(e.target.files[0])}
              type="file"
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3 mr-3 text-left">
            <Form.Label>Logo da empresa</Form.Label>
            <Col className="text-center">
              <img className="presentation" src={logo} />
            </Col>
            <Form.Control
              onChange={(e) => loadLogo(e.target.files[0])}
              type="file"
            />
          </Form.Group>
        </Row>
        <Row className="justify-content-center mt-1 ml-3 mb-3">
          <Button variant="flat" onClick={saveEdits}>Salvar Edições <FontAwesomeIcon size="lg" icon={faSave} /></Button>
        </Row>
      </Form>
      <Row className="ml-4 mt-1">
        <p>Deseja verificar os usuários da empresa? <NavLink className="link" to={"/usuarios"}>Clique aqui.</NavLink></p>
      </Row>
    </Card >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    companies: state.companies.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (usuario) => dispatch(storeActions.getUser(usuario)),
    getCompany: (usuario) => dispatch(storeActions.getCompany(usuario)),
    putUser: (user, usuario) => dispatch(storeActions.putUser(user, usuario)),
    putCompany: (usuario, company) => dispatch(storeActions.putCompany(usuario, company)),
    postUser: (user, usuario) => dispatch(storeActions.postUser(user, usuario)),
    postCompany: (company, usuario) => dispatch(storeActions.postCompany(company, usuario)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faSave, faUserPlus, faTrash, faHome, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container, Form, Button, Tabs, Tab, InputGroup, Alert } from "react-bootstrap";
import FormCard from "../../Cards/FormCard/FormCard";
import { NavLink } from "react-router-dom";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import logo from '../../../assets/petrolead-branco.png'
import * as storeActions from "../../../store/actions/index";
import { connect } from "react-redux";
import "./SignUp.scss"

export function SignUp(props) {

  const [key, setKey] = useState('empresa')
  const [corporateName, setCorporateName] = useState('')
  const [uf, setUf] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [cnpj, setCNPJ] = useState('')
  const [base64, setBase64] = useState({
    'crc': '',
    'logo': '',
    'photo': '',
  })  //estado que possui os base64 dos arquivos
  const [emailCompany, setEmailCompany] = useState('')
  const [nameOwner, setNameOwner] = useState('')
  const [office, setOffice] = useState('')
  const [emailOwner, setEmailOwner] = useState('')
  const [employeeRegistration, setEmployeeRegistration] = useState('')
  const [passwordOwner, setPasswordOwner] = useState('')
  const [confirmPasswordOwner, setConfirmPasswordOwner] = useState('')
  const [emailGuest, setEmailGuest] = useState([])
  const [errorsCompany, setErrorsCompany] = useState({})
  const [errorsSubsidiary, setErrorsSubsidiary] = useState([])
  const [formCompanyError, setFormCompanyError] = useState(false);
  const [errorsOwner, setErrorsOwner] = useState({})
  const [errorsGuest, setErrorsGuest] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertEmailGuest, setAlertEmailGuest] = useState(false)
  const [tokenValue, setTokenValue] = useState("")
  const [triggerCompanySubmit, setTriggerCompanySubmit] = useState(false)
  const [statusCaptcha, setStatusCaptcha] = useState(true) //useState(false)
  const [acessStatus, setAcessStatus] = useState(false)
  const [messageInvalidToken, setMessageInvalidToken] = useState("")
  const [disableCompanyForm, setDisableCompanyForm] = useState(false)
  const [messageSuccessSaveCompany, setMessageSuccessSaveCompany] = useState(false)
  const [messageExistsCompany, setMessageExistsCompany] = useState(false)

  /* Dados de subsidiary */
  const [subsidiaryCompany, setSubsidiaryCompany] = useState([])
  const [subsidiaryCompanyCorporateName, setSubsidiaryCompanyCorporateName] = useState([])
  const [subsidiaryCompanyUf, setSubsidiaryCompanyUf] = useState([])
  const [subsidiaryCompanyCompanyWebsite, setSubsidiaryCompanyCompanyWebsite] = useState([])
  const [subsidiaryCompanyCnpj, setSubsidiaryCompanyCNPJ] = useState([])
  const [subsidiaryCompanyBase64, setSubsidiaryCompanyBase64] = useState([])  //estado que possui os base64 dos arquivos
  const [subsidiaryCompanyEmailCompany, setSubsidiaryCompanyEmailCompany] = useState([])

  //variável que armazena o valor dos campos da empresa
  const valuesCompany = {
    "corporateName": corporateName,
    "uf": uf,
    "companyWebsite": companyWebsite,
    "cnpj": cnpj,
    "crc": base64.crc,
    "logo": base64.logo,
    "emailCompany": emailCompany,
    "subsidiaryCompany": subsidiaryCompany,
    "token": tokenValue,
    "userLimit": 5
  }

  //variável que armazena o valor dos campos do gestor
  const valuesOwner = {
    "nameOwner": nameOwner,
    "emailOwner": emailOwner,
    "office": office,
    "photo": base64.photo,
    "employeeRegistration": employeeRegistration,
    "passwordOwner": passwordOwner,
    "confirmPasswordOwner": confirmPasswordOwner,
  }

  //variável que armazena o valor dos campos dos convidados
  const valuesGuest = {
    "emailGuest": emailGuest,
  }

  /* Realizando o teste de validação no primeiro carregamento da tela */
  useEffect(() => {
    setErrorsGuest(validateGuest(valuesGuest))
  }, [])

  useEffect(() => {
    if (triggerCompanySubmit) {
      if (!formCompanyError) {
        setTriggerCompanySubmit(false);
        setDisableCompanyForm(true);
      }
    }
  }, [formCompanyError, errorsCompany])

  /* função para salvar o base64 do CRC no estado */
  function loadCRC(e) {
    getBase64(e).then(result => {
      setBase64({ ...base64, 'crc': result });
    })
  }

  /* função para salvar o base64 da Logo no estado */
  function loadLogo(e) {
    getBase64(e).then(result => {
      setBase64({ ...base64, 'logo': result });
    })
  }

  /* função para salvar o base64 da Foto no estado */
  function loadPhoto(e) {
    getBase64(e).then(result => {
      setBase64({ ...base64, 'photo': result });
    })
  }

  /* função para salvar o base64 do crc da subsidiaria */
  async function loadCRCSubsidiary(e, index) {
    subsidiaryCompanyBase64[index].crc = await getBase64Subsidiary(e)
  }

  /* função para salvar o base64 da logo da subsidiaria */
  async function loadLogoSubsidiary(e, index) {
    subsidiaryCompanyBase64[index].logo = await getBase64Subsidiary(e)
  }

  /* função que processa o arquivo inputado transformando-o em base64 */
  function getBase64(file) {
    return new Promise((resolve) => {
      let fileInfo;
      let baseUrl = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        baseUrl = reader.result;
        resolve(baseUrl);
      })
    })
  }

  /* função que processa o arquivo da subsidiaria inputado transformando-o em base64 */
  function getBase64Subsidiary(file) {
    return new Promise((resolve) => {
      let fileInfo;
      let baseUrl = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        baseUrl = reader.result;
        resolve(baseUrl);
      })
    })
  }

  function handleVerificationSuccess(token, ekey) {
    setStatusCaptcha(false)

    // window.location.assign("/signup")
  }

  /*Função para proporcionar navegação de avanço entre abas por botão*/
  function ajustAbaNext(text) {
    setErrorsOwner(validateOwner(valuesOwner))
    let result = validateOwner(valuesOwner)

    if (key === 'empresa') {
      setErrorsCompany(validateCompany(valuesCompany))
      if (Object.keys(errorsCompany).length === 0) {
        setKey(text)
      }
    }
    if (Object.values(result).length === 0 && key === 'gestor') {
      setKey(text)
    }
  }

  /*Função para proporcionar navegação de retorno entre abas por botão*/
  function ajustAba(text) {
    setKey(text)
  }

  /* função para validação dos campos da empresa */
  function validateCompany(values) {
    const errorsCompany = {};
    let hasError = false;

    // Validando a razão social
    if (values.corporateName === '' || values.corporateName === undefined) {
      errorsCompany.corporateName = 'A Razão Social é obrigatória!';
      hasError = true;
    }

    // Validando a UF
    if (values.uf === '' || values.uf === undefined) {
      errorsCompany.uf = 'A UF da empresa é obrigatória!';
      hasError = true;
    }

    // Validando o site
    if (values.companyWebsite === '' || values.companyWebsite === undefined) {
      errorsCompany.companyWebsite = 'O site da empresa é obrigatório!';
      hasError = true;
    }

    // Validando o CNPJ
    values.cnpj = removeMask(values.cnpj)
    if (values.cnpj === '' || values.cnpj === undefined) {
      errorsCompany.cnpj = 'O CNPJ é obrigatório!'
      hasError = true;
    } else {
      const cnpj = values.cnpj.replace(/[^\d]+/g, '');
      if (cnpj.length !== 14) {
        errorsCompany.cnpj = 'Informe um CNPJ válido'
        hasError = true;
      }
      // Elimina CNPJs inválidos conhecidos
      if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999") {
        hasError = true;
        errorsCompany.cnpj = 'Informe um CNPJ válido'
      }
    }
    // Validando o CRC
    // if (values.crc === '' || values.crc === undefined) {
    //   hasError = true;
    //   errorsCompany.crc = 'O CRC da empresa é obrigatório!';
    // }

    // Validando a logo
    // if (values.logo === '' || values.logo === undefined) {
    //   hasError = true;
    //   errorsCompany.logo = 'A logo da empresa é obrigatória!';
    // }

    //Validando o e-mail da empresa
    if (!values.emailCompany?.includes('@')) {
      hasError = true;
      errorsCompany.emailCompany = 'Informe um e-mail válido'
    }
    if (values.emailCompany === '' || values.emailCompany === undefined) {
      hasError = true;
      errorsCompany.emailCompany = 'O e-mail é obrigatório!'
    }

    // console.log(errorsCompany)
    setFormCompanyError(hasError);
    return errorsCompany
  }

  /*Função para validação dos campos do gestor */
  function validateOwner(values) {
    const errorsOwner = {};

    // Validando nome
    if (values.nameOwner === '') {
      errorsOwner.nameOwner = 'O nome do Gestor é obrigatório!';
    }

    //Validando email
    if (!values.emailOwner.includes('@')) {
      errorsOwner.emailOwner = 'Informe um e-mail válido'
    }
    if (values.emailOwner === '') {
      errorsOwner.emailOwner = 'O e-mail é obrigatório!'
    }

    // Validando cargo 
    if (values.office === '') {
      errorsOwner.office = 'O cargo é obrigatório!';
    }

    //Validando a foto
    // if (values.photo === '') {
    //   errorsOwner.photo = 'A foto do gestor é obrigatória!';
    // }

    // Validando registro
    // if (values.employeeRegistration === '') {
    //   errorsOwner.employeeRegistration = 'O registro é obrigatório!';
    // }

    //Validando a senha 
    if (values.passwordOwner === '') {
      errorsOwner.passwordOwner = 'A senha é obrigatória';
    } else if (values.passwordOwner.length < 6) {
      errorsOwner.passwordOwner = 'A senha deve conter no mínimo 6 caracteres'
    }

    //Validando a confirmação de senha
    if (values.confirmPasswordOwner === '') {
      errorsOwner.confirmPasswordOwner = 'Confirme sua senha';
    } else if (values.confirmPasswordOwner !== values.passwordOwner) {
      errorsOwner.confirmPasswordOwner = 'As senhas devem ser iguais'
    }
    return errorsOwner
  }

  /*Função para validação dos campos dos convidados*/
  function validateGuest(values) {
    const errorsGuest = {};

    // Validando e-mail dos convidados
    values.emailGuest.map((emailGuest, index) => {
      if (!emailGuest.includes('@')) {
        errorsGuest.emailGuest = `Informe um e-mail válido para todos os convidados`
      }
      if (emailGuest === '') {
        errorsGuest.emailGuest = `O e-mail é obrigatório para todos os convidados`
      }
    })
    return errorsGuest
  }

  /* Função para adicionar campos de convite para usuários */
  const addInputGuest = (e) => {
    e.preventDefault()
    if (emailGuest.length >= 3) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
      setEmailGuest([...emailGuest, []])
    }
  }

  /*Função para remover campos de convite de usuário */
  const deletGuest = (position) => {
    setEmailGuest([...emailGuest.filter((_, index) => index !== position)])
    if (emailGuest.length <= 3) {
      setShowAlert(false)
    }
  }

  /* Função para salvar cada um dos emails de convidados*/
  const putOneEmail = (e, index) => {
    emailGuest[index] = e.target.value;
    setEmailGuest([...emailGuest])
  }

  // Função para adicionar campos de empresa subsidiária 
  const addInputSubsidiaryCompany = (e) => {
    e.preventDefault()
    setSubsidiaryCompany([...subsidiaryCompany, {
      "corporateName": null,
      "uf": null,
      "companyWebsite": null,
      "cnpj": null,
      "crc": null,
      "logo": null,
      "emailCompany": null,
    }])

    setSubsidiaryCompanyBase64([...subsidiaryCompanyBase64, {
      "crc": "",
      "logo": "",
    }])
  }

  //Função para remover campos de empresa subsidiária 
  const deletSubsidiaryCompany = (position) => {
    setSubsidiaryCompany([...subsidiaryCompany.filter((_, index) => index !== position)])
  }

  // Função para salvar cada uma das empresas subsidiárias
  const putOneSubsidiaryCompany = (e, index) => {
    subsidiaryCompany[index] = e.target.value;
    setSubsidiaryCompany([...subsidiaryCompany])
  }

  // Funções para controlar os estados dos campos de input de subsidiaria
  function controlSubsidiaryCompanyCorporateName(value, index) {
    subsidiaryCompanyCorporateName[index] = value
    setSubsidiaryCompanyCorporateName([...subsidiaryCompanyCorporateName])
  }

  function controlSubsidiaryCompanyUf(value, index) {
    subsidiaryCompanyUf[index] = value
    setSubsidiaryCompanyUf([...subsidiaryCompanyUf])
  }

  function controlSubsidiaryCompanyCompanyWebsite(value, index) {
    subsidiaryCompanyCompanyWebsite[index] = value
    setSubsidiaryCompanyCompanyWebsite([...subsidiaryCompanyCompanyWebsite])
  }

  function controlSubsidiaryCompanyCnpj(value, index) {
    subsidiaryCompanyCnpj[index] = value
    setSubsidiaryCompanyCNPJ([...subsidiaryCompanyCnpj])
  }

  function controlSubsidiaryCompanyEmailCompany(value, index) {
    subsidiaryCompanyEmailCompany[index] = value
    setSubsidiaryCompanyEmailCompany([...subsidiaryCompanyEmailCompany])
  }

  const saveCompany = async () => {
    // console.log("Tratando dados...")
    setErrorsCompany(validateCompany(valuesCompany));
    /* setTriggerCompanySubmit(true) */

    //Atualizando os estado da subsidiária
    // console.log("Tratando dados de subsidiária...")
    subsidiaryCompany.map((company, index) => {
      subsidiaryCompany[index] = {
        "corporateName": subsidiaryCompanyCorporateName[index],
        "uf": subsidiaryCompanyUf[index],
        "companyWebsite": subsidiaryCompanyCompanyWebsite[index],
        "cnpj": removeMask(subsidiaryCompanyCnpj[index]),
        "crc": subsidiaryCompanyBase64[index].crc,
        "logo": subsidiaryCompanyBase64[index].logo,
        "emailCompany": subsidiaryCompanyEmailCompany[index],
      }
    })

    let message = []
    subsidiaryCompany.map(company => {
      message.push(validateCompany(company))
    })
    setErrorsSubsidiary(message)

    if (Object.values(validateCompany(valuesCompany)).length === 0) {
      let result = await props.postSignUp({
        "company": valuesCompany,
      })

      // console.log(result)

      if (result.message === "success") {
        setMessageSuccessSaveCompany(true)
      }

      if (result.message === "This company already exists") {
        setMessageExistsCompany(true)
      }
    }
  }

  /* Função para salvar o formulário */
  const saveForm = async () => {
    // console.log("Validando...")
    setErrorsGuest(validateGuest(valuesGuest))
    // console.log("Validado!")

    // a variável 'result' armazena a mensagem de erro retornada da api caso exista
    // Fazendo cadastro de empresa e usuários
    // console.log("Cadastrando...")
    let result = await props.postSignUp({
      "company": valuesCompany,
      "mainUser": valuesOwner,
      "emailGuest": valuesGuest.emailGuest,
    })

    // percorrendo a variável 'result' para reconhecer qual mensagem de erro ela 
    // possui e setar o erro no campo correto
    result.message.map((res) => {
      if (res === "Usuário administrador já existe") {
        errorsOwner.emailOwner = 'Usuário administrador já existe'
      }

      if (res === "Empresa já existe") {
        errorsCompany.cnpj = 'Empresa já existe'
      }

      if (res.includes("Email de convidado(s) já existe: ")) {
        errorsGuest.emailGuest = res
      }

      if (res === "Senhas não coincidem") {
        errorsOwner.confirmPasswordOwner = "Senhas não coincidem"
      }

      if (res === "success!") {
        setAlertEmailGuest(true)
        // window.location.assign("/login")
      }
    })
  }

  const cnpjMask = (value) => {
    if (value) {
      return value
        .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 dígitos e o segundo de com 3 dígitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 dígitos, separados por /
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
    }
  }

  const removeMask = (value) => {
    return value.replace(/[^0-9]+/g, '');
  }

  const acessToken = async () => {
    let result = await props.postSignUp({
      validate: tokenValue
    })

    if (result.length > 0) {
      setAcessStatus(true)
      setMessageInvalidToken("")
    } else {
      setMessageInvalidToken("Por gentileza, insira um token válido")
    }
  }

  // window.location.assign("/login") // DESCOMENTAR PARA PRODUÇÃO

  return (
    <>
      {statusCaptcha === true
        ?
        <FormCard>
          <Row className="w-100 h-100">
            <Col xs={12} className="d-flex flex-row pl-4 pt-3">
              <NavLink to="/login">
                <i className="arrow left"></i>
              </NavLink>
            </Col>

            <Col xs={12} className="justify-content-center d-flex flex-row pl-4 pt-3">
              <HCaptcha
                sitekey="dfc29da1-236a-4160-8057-e51409ede601"
                onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
              />
            </Col>
          </Row>
        </FormCard>
        :
        <>
          <div id="header" className="w-100 p-2">
            <Row>
              <Col>
                <p id="titleHeader">Cadastro Petrolead</p>
              </Col>
              <Col>
                <img id="logoHeader" src={logo} alt="logo" />"
              </Col>
              <Col>
                {/* <Button id="buttonHeader" href="/" variant="secondary" size="lg"> <FontAwesomeIcon className="" size="lg" icon={faHome} /></Button> */}
              </Col>
            </Row>
          </div>
          <Container className="teste">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              className="mb-3"
              onSelect={(k) => setKey(k)} //permite navegação através do click no titulo da aba correspondente
            >
              <Tab disabled={acessStatus === true ? true : false} transition={false} eventKey="empresa" title="Empresa" >
                <Container>
                  <Form >
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Razão Social:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} value={corporateName} onChange={(e) => setCorporateName(e.target.value)} type="text" placeholder="Razão Social" />
                        {errorsCompany.corporateName && <span className="formField__error">{errorsCompany.corporateName}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        UF da empresa:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} value={uf} onChange={(e) => { e.target.value.length <= 2 && setUf(e.target.value) }} type="text" placeholder="UF da empresa" />
                        {errorsCompany.uf && <span className="formField__error">{errorsCompany.uf}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Site da empresa:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} type="text" placeholder="Site da empresa" />
                        {errorsCompany.companyWebsite && <span className="formField__error">{errorsCompany.companyWebsite}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        CNPJ:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} value={(cnpj)} onChange={(e) => setCNPJ((e.target.value))} type="text" placeholder="CNPJ" />
                        {errorsCompany.cnpj && <span className="formField__error">{errorsCompany.cnpj}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        CRC Petronect:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} onChange={(e) => loadCRC(e.target.files[0])} type="file" />
                        {errorsCompany.crc && <span className="formField__error">{errorsCompany.crc}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Logo:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} onChange={(e) => loadLogo(e.target.files[0])} type="file" />
                        {errorsCompany.logo && <span className="formField__error">{errorsCompany.logo}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        E-mail da empresa:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control disabled={disableCompanyForm} value={emailCompany} onChange={(e) => setEmailCompany(e.target.value)} type="email" placeholder="E-mail da empresa" />
                        {errorsCompany.emailCompany && <span className="formField__error">{errorsCompany.emailCompany}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left">
                      <Form.Label column sm="4">Clique para adicionar empresas subsidiárias <Button variant="flat" className="btn btn-primary" id="add-category" onClick={addInputSubsidiaryCompany}><FontAwesomeIcon className="" size="lg" icon={faUserPlus} /></Button></Form.Label>
                    </Form.Group>
                    {
                      subsidiaryCompany.map((_, index) => (
                        <Container key={index} className="ml-4 mt-3 mb-3">
                          <Row className="justify-content-center mt-4">
                            <h5 className="mt-1 ml-auto">{`Empresa subsidiária ${index + 1}`}</h5>
                            <button className="btn btn-circle text-danger ml-auto mr-auto" onClick={(e) => { e.preventDefault(); deletSubsidiaryCompany(index) }}><FontAwesomeIcon className="" size="lg" icon={faTrash} /></button>
                          </Row>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              Razão social:
                            </Form.Label>
                            <Col sm="8" className="mt-2">
                              <Form.Control disabled={disableCompanyForm} value={subsidiaryCompanyCorporateName[index]} onChange={(e) => controlSubsidiaryCompanyCorporateName(e.target.value, index)} type="text" placeholder="Razão Social" />
                              {errorsSubsidiary[index]?.corporateName && <span className="formField__error">{errorsSubsidiary[index]?.corporateName}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              UF da empresa:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} value={subsidiaryCompanyUf[index]} onChange={(e) => { e.target.value.length <= 2 && controlSubsidiaryCompanyUf(e.target.value, index) }} type="text" placeholder="UF da empresa" />
                              {errorsSubsidiary[index]?.uf && <span className="formField__error">{errorsSubsidiary[index]?.uf}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              Site da empresa:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} value={subsidiaryCompanyCompanyWebsite[index]} onChange={(e) => controlSubsidiaryCompanyCompanyWebsite(e.target.value, index)} type="text" placeholder="Site da empresa" />
                              {errorsSubsidiary[index]?.companyWebsite && <span className="formField__error">{errorsSubsidiary[index]?.companyWebsite}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              CNPJ:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} value={cnpjMask(subsidiaryCompanyCnpj[index])} onChange={(e) => controlSubsidiaryCompanyCnpj((e.target.value), index)} type="text" placeholder="CNPJ" />
                              {errorsSubsidiary[index]?.cnpj && <span className="formField__error">{errorsSubsidiary[index]?.cnpj}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              CRC Petronect:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} onChange={(e) => loadCRCSubsidiary(e.target.files[0], index)} type="file" />
                              {errorsSubsidiary[index]?.crc && <span className="formField__error">{errorsSubsidiary[index]?.crc}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              Logo:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} onChange={(e) => loadLogoSubsidiary(e.target.files[0], index)} type="file" />
                              {errorsSubsidiary[index]?.logo && <span className="formField__error">{errorsSubsidiary[index]?.logo}</span>}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                              E-mail da empresa:
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control disabled={disableCompanyForm} value={subsidiaryCompanyEmailCompany[index]} onChange={(e) => controlSubsidiaryCompanyEmailCompany(e.target.value, index)} type="email" placeholder="E-mail da empresa" />
                              {errorsSubsidiary[index]?.emailCompany && <span className="formField__error">{errorsSubsidiary[index]?.emailCompany}</span>}
                            </Col>
                          </Form.Group>
                        </Container>
                      ))}
                  </Form>
                  <h3 className={messageExistsCompany == true ? "text-warning" : "display-none"}>Empresa já existente</h3>
                  <h3 className={messageSuccessSaveCompany === true ? "text-success" : "display-none"}>Solicitação realizada com sucesso. <br />Veja o seu email para mais detalhes</h3>
                  <Button /* disabled={disableCompanyForm} */ /* onClick={saveCompany} */ href="/" variant="flat">Ir para Início <FontAwesomeIcon className="" size="lg" icon={faChevronCircleLeft} /></Button>
                  <br /><br />
                  <Button disabled={disableCompanyForm} onClick={saveCompany} variant="flat">Salvar <FontAwesomeIcon className="" size="lg" icon={faSave} /></Button>
                </Container>
              </Tab>

              <Tab transition={false} eventKey="gestor" title="Gestor">
                <Container className={acessStatus === true ? "display-none" : ""}>
                  <Row className="justify-content-center">
                    <label>
                      Para acessar o conteúdo desta página, informe o token de autorização recebido pelo suporte da Libo.Di no seu e-mail
                    </label>
                  </Row>
                  <Row className="justify-content-center">
                    <input className="input-token" value={tokenValue} placeholder="Digite o seu token aqui" onChange={(e) => setTokenValue(e.target.value)} type="text"></input>
                  </Row>
                  <Row className="justify-content-center mt-2">
                    {messageInvalidToken !== "" ? <span className="formField__error">{messageInvalidToken}</span> : <></>}
                  </Row>
                  <Row className="justify-content-center mt-2">
                    <Button onClick={acessToken} variant="flat">Acessar</Button>
                  </Row>
                </Container>

                <Container className={acessStatus === false ? "display-none" : ""}>
                  <Form >
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Nome:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={nameOwner} onChange={(e) => setNameOwner(e.target.value)} type="text" placeholder="Nome" />
                        {errorsOwner.nameOwner && <span className="formField__error">{errorsOwner.nameOwner}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        E-mail:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={emailOwner} onChange={(e) => setEmailOwner(e.target.value)} type="email" placeholder="E-mail" />
                        {errorsOwner.emailOwner && <span className="formField__error">{errorsOwner.emailOwner}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Setor:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={office} onChange={(e) => setOffice(e.target.value)} type="text" placeholder="Setor" />
                        {errorsOwner.office && <span className="formField__error">{errorsOwner.office}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Foto:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control onChange={(e) => loadPhoto(e.target.files[0])} type="file" />
                        {errorsOwner.photo && <span className="formField__error">{errorsOwner.photo}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Form.Label column sm="2">
                        Registro:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={employeeRegistration} onChange={(e) => { setEmployeeRegistration(e.target.value) }} type="text" placeholder="Registro" />
                        {errorsOwner.employeeRegistration && <span className="formField__error">{errorsOwner.employeeRegistration}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left">
                      <Form.Label column sm="2">
                        Senha:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={passwordOwner} onChange={(e) => { setPasswordOwner(e.target.value) }} type="password" placeholder="Senha" />
                        {errorsOwner.passwordOwner && <span className="formField__error">{errorsOwner.passwordOwner}</span>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-left">
                      <Form.Label column sm="2">
                        Confirme sua senha:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={confirmPasswordOwner} onChange={(e) => { setConfirmPasswordOwner(e.target.value) }} type="password" placeholder="Confirme sua senha" />
                        {errorsOwner.confirmPasswordOwner && <span className="formField__error">{errorsOwner.confirmPasswordOwner}</span>}
                      </Col>
                    </Form.Group>
                  </Form>
                  <Button onClick={(e) => ajustAbaNext('usuarios')} variant="flat">Próximo <FontAwesomeIcon className="" size="lg" icon={faArrowRight} /></Button>{' '}
                </Container>
              </Tab>
              <Tab transition={false} eventKey="usuarios" title="Usuários">
                <Container className={acessStatus === true ? "display-none" : ""}>
                  <Row className="justify-content-center">
                    <label>
                      Para acessar o conteúdo desta página, informe o token de autorização recebido pelo suporte da Libo.Di
                    </label>
                  </Row>
                  <Row className="justify-content-center">
                    <input className="input-token" value={tokenValue} placeholder="Digite o seu token aqui" onChange={(e) => setTokenValue(e.target.value)} type="text"></input>
                  </Row>
                  <Row className="justify-content-center mt-2">
                    {messageInvalidToken !== "" ? <span className="formField__error">{messageInvalidToken}</span> : <></>}
                  </Row>
                  <Row className="justify-content-center mt-2">
                    <Button onClick={acessToken} variant="flat">Acessar</Button>
                  </Row>
                </Container>

                <Container className={acessStatus === false ? "display-none" : ""}>
                  <Form >
                    <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
                      <Col sm="8">
                        <p>Clique para adicionar convidados <Button variant="flat" className="btn btn-primary" id="add-category" onClick={addInputGuest}><FontAwesomeIcon className="" size="lg" icon={faUserPlus} /></Button></p>
                      </Col>
                    </Form.Group>
                    {errorsGuest.emailGuest && <span className="formField__error">{errorsGuest.emailGuest}</span>}
                    {
                      emailGuest.map((email, index) => (
                        <Form.Group key={index} as={Row} className="mb-3 text-left">
                          <Form.Label column sm="2" for={`email${index + 1}`}>
                            {`E-mail do convidado ${index + 1}:`}
                          </Form.Label>
                          <Col sm="8">
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="email"
                                placeholder="E-mail"
                                id={`email${index + 1}`}
                                value={email}
                                onChange={(e) => putOneEmail(e, index)}
                              />
                              <button className="btn btn-circle text-danger" onClick={(e) => { e.preventDefault(); deletGuest(index) }}><FontAwesomeIcon className="" size="lg" icon={faTrash} /></button>
                            </InputGroup>
                          </Col>
                        </Form.Group>
                      ))}
                    {showAlert === true ?
                      <Row className="justify-content-center">
                        <Alert className="alert-guest" variant="danger" onClose={() => setShowAlert(false)} dismissible>
                          <Alert.Heading className="mt-2">Quantidade limite de convites alcançada!</Alert.Heading>
                          <p className="mt-2 mb-2">
                            A quantidade máxima de envio de convites foi alcançada,
                            caso tenha alguma dúvida entre em contato com o suporte da Libo.Di
                          </p>
                        </Alert>
                      </Row>
                      :
                      <></>
                    }
                    <Container fluid>
                      {alertEmailGuest === true ?
                        <Row className="justify-content-center">
                          <Alert variant="success" onClose={() => setAlertEmailGuest(false)} dismissible>
                            E-mails de convite enviados com sucesso!!!
                          </Alert>
                        </Row>
                        :
                        <></>
                      }
                      <Button onClick={(e) => ajustAba('gestor')} variant="secondary"><FontAwesomeIcon className="" size="lg" icon={faArrowLeft} /> Anterior</Button>{' '}
                      <Button variant="flat" onClick={saveForm}>Salvar <FontAwesomeIcon className="" size="lg" icon={faSave} /></Button>
                    </Container>
                    <br></br>
                  </Form>
                </Container>
              </Tab>
            </Tabs>
          </Container >
        </>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSignUp: (o) => dispatch(storeActions.postSignUp(o))
  }
}

export default connect(null, mapDispatchToProps)(SignUp)
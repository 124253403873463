import React, { useState } from "react";
import html2canvas from "html2canvas";
import { RiArrowLeftLine, RiCameraLine, RiDeleteBin2Line } from 'react-icons/ri';
import { Spinner } from "react-bootstrap";
import * as storeActions from "../../../../store/actions/index";
import { connect } from "react-redux";

import "./styles.scss"
import { CloseButton } from "../CloseButton";

function FeedbackForm(props) {
  const [comment, setComment] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  async function handleTakeScreenshot() {
    setIsTakingScreenshot(true)

    const canvas = await html2canvas(document.querySelector('html'))
    const base64image = canvas.toDataURL('image/png')
    setScreenshot(base64image)
    setIsTakingScreenshot(false)
  }

  async function handleSubmitFeedback(event) {
    event.preventDefault();
    setIsSendingFeedback(true);

    const dataPost = {
      "message": comment,
      "type": props.typeFeedback.title,
      screenshot,
      "companyId": props.user.data.companyId,
      "userId": props.user.data._id,
    }

    //integração com back-end
    await props.postFeedback(dataPost, props.user)

    setIsSendingFeedback(false);
    props.onFeedbackSent()
  }

  return (
    <>
      <div className="container-header-buttons">
        <button className="return-button-form" onClick={() => { props.setFormFeedback() }}>
          <RiArrowLeftLine size="30px" />
        </button>
        <div className="legend-form">
          <img src={props.typeFeedback.image.source} alt={props.typeFeedback.image.alt} />
          <span>{props.typeFeedback.title}</span>
        </div>
        <div>
          <CloseButton handleClickClose={props.setFeedbackIsClicked} />
        </div>
      </div>

      <form onSubmit={handleSubmitFeedback} className="my-3 w-full">
        <textarea
          className="textarea-feedback"
          placeholder="Conte em detalhes o que esta acontecendo ..."
          onChange={event => setComment(event.target.value)}
        />

        <div className="container-buttons-feedback">

          {isTakingScreenshot ?
            <button
              type="button"
              className="screenshot-feedback-button">
              <Spinner size="sm" animation="border" />
            </button>
            :
            screenshot ?
              <button
                type="button"
                onClick={() => setScreenshot(null)}
                className="screenshot-feedback-button">
                <RiDeleteBin2Line size="25px" />
              </button>
              :
              <button
                type="button"
                onClick={handleTakeScreenshot}
                className="screenshot-feedback-button">
                <RiCameraLine size="25px" />
              </button>
          }

          <button
            className={comment === '' ? "submit-feedback-button-disabled" : "submit-feedback-button"}
            disabled={comment !== '' ? false : true}
            type="submit"
          >
            {isSendingFeedback ? <Spinner size="sm" animation="border" /> : "Enviar Feedback"}
          </button>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postFeedback: (data, user) => dispatch(storeActions.postFeedback(data, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
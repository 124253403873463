import React, { useState } from "react";
import "./Oportunidade.scss";
import Breadcrumb from "../../Base/Breadcrumb/Breadcrumb";
import { Row, Col, Button, Modal } from "react-bootstrap";
import MenuButton from "./MenuButton/MenuButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faExternalLinkAlt, faEye, faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import declineIcon from "../../../assets/decline-24dp.svg";
import AttachmentMenu from "./AttachmentMenu/AttachmentMenu";
import OpportunitiesFunction from "./OpportunitiesFunctions/OpportunitiesFunction";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import MyTable from "../../Tables/MyTable/DataTableOpportunity";
import ModalDecline from '../Decline/ModalDecline/ModalDecline'
import Cards from "../../Cards/Card";
import { Link } from "react-router-dom";

function Oportunidade(props) {

  const [showModalDecline, setShowModalDecline] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [modalItem, setModalItem] = useState(false);
  const [itemDescription, setItemDescription] = useState([]);
  const itemsColumns = [
    {
      name: "Nº",
      selector: "index",
      sortable: true,
    },
    {
      name: "Descrição do Item",
      selector: "description",
      sortable: true,
    },
    {
      name: "Categoria",
      selector: "categoryDescription",
      sortable: true,
    },
    {
      name: "Qtd",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Un. Medida",
      selector: "measurementUnit",
      sortable: true,
    },
    {
      name: "DT Remessa",
      selector: "shipping",
      sortable: true,
    },
    {
      name: "$",
      selector: "currency",
      sortable: true,
    },
    {
      name: "Entrega",
      selector: "entrega",
      sortable: true,
    },
    {
      name: "Ações",
      selector: "Ações",
      sortable: true
    },
    {
      name: "Petronect",
      selector: "calc",
      sortable: true
    }
  ];

  function startModal(item) {
    setShowModalDecline(true)

    if ((typeof item) === "number") {
      setTitleModal({
        "message": "Declínio da oportunidade",
        "value": item,
      })
    } else if ((typeof item) === "string") {
      setTitleModal({
        "message": "Declínio do item",
        "value": item,
      })
    }
  }

  const pathArray = props.location.pathname.split("/");
  const id = pathArray[pathArray.length - 1];
  const opportunity = props.opportunities.find(
    (el) => el.index.toString().toLowerCase() === id.toString().toLowerCase()
  );
  // console.log(opportunity)
  let newDateStart = new Date(opportunity["dateStart"] * 1000);
  newDateStart.setHours(newDateStart.getHours() - 3);
  newDateStart = newDateStart.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
  let newData = newDateStart.split(" ");
  let newFormData = newData[0].split("-");
  newDateStart = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
  let newDateEnd = new Date(opportunity["dateEnd"] * 1000);
  newDateEnd.setHours(newDateEnd.getHours() - 3);
  newDateEnd = newDateEnd.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
  newData = newDateEnd.split(" ");
  newFormData = newData[0].split("-");
  newDateEnd = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
  const criterio = opportunity['criterioJulgamento']

  const arquivos = Array.isArray(opportunity.files) ? opportunity.files.length : opportunity.files.split(",").length //Workaround
  let filesName = [];
  let fileid = ""
  filesName = Array.isArray(opportunity.files) ? opportunity.files : opportunity.files.split(',') //Workaround
  filesName.push("Tudo")

  if (opportunity.fileId) {
    fileid = opportunity.fileId;
  }

  let proposta = props.proposals.find((prop) => {
    if (prop.opportunityId) return prop.opportunityId._id === opportunity._id;
  });

  // Faz o sininho funcionar
  const notifyCreation = (type, message) => {
    props.notify(
      {
        id: `id ${message}`,
        header: id,
        content: message,
        type: type,
      },
      5000
    );
  };

  let itemsContent;
  let items = []

  items = props.items.filter(it => it.opportunityId._id === opportunity._id)
  items = items.filter(it => {
    if (it.description !== "Item de controle Libo.Di") {
      it['entrega'] = it.destinyZone;
      it['Ações'] = [
        <Button variant="flat" onClick={() => { setModalItem(true); setItemDescription(it.fullDescription); }} className="mr-2"><FontAwesomeIcon size="lg" icon={faEye} /></Button>,
      ];
      it['calc'] = [
        <a href='http://calc.tempsite.ws/calc_preco.asp' target='_blank' className='btn btn-flat'><FontAwesomeIcon size="lg" icon={faExternalLinkAlt} /></a>
      ];
      return it
    }
  })

  if (items.length > 0) {
    itemsContent = (
      <MyTable
        data={items}
        columns={itemsColumns}
      />
    );
  } else {
    itemsContent = <p>Não existem itens. Veja os Anexos</p>;
  }

  return (
    <div className="opportunity-view-wrapper m-0 p-0 ml-0 pl-2 w-100">

      <Breadcrumb />
      <Link to="/oportunidades"><Button variant="flat" className="p-3 mb-1 btn-rounded float-left"><FontAwesomeIcon size="lg" icon={faBackward} /></Button></Link>

      <Cards>
        <Row className="container-initial">
          <Col>
            <div className="opportunity-view-header-box">
              <p>Número</p>
              <p>{opportunity.index}</p>
            </div>
          </Col>
          <Col>
            <div className="opportunity-view-header-box">
              <p>Abertura</p>
              <p>{newDateStart}</p>
            </div>
          </Col>
          <Col>
            <div className="opportunity-view-header-box">
              <p>Fechamento</p>
              <p>{newDateEnd}</p>
            </div>
          </Col>

          <Col xs={4}></Col>

          <Col>
            <Row className="container-decline-opportunity mr-4">
              <Button size="lg" variant="danger" onClick={() => { startModal(opportunity.index) }}><FontAwesomeIcon size="lg" icon={faSortAmountDownAlt} /></Button>
              <AttachmentMenu options={filesName} fileid={fileid} arquivos={arquivos} />
              {/* <OpportunitiesFunction className="opportunity-options" /> */}
            </Row>
          </Col>
        </Row>

        <Row className="m-0 p-3 ml-4 mt-2 mb-2 w-100">
          <Col
            xs={12}
            className="w-100 m-0 p-0 opportunity-view-item w-100 mb-5"
          >
            <Col
              xs={12}
              className="m-0 p-0 w-100 d-flex flex-row justify-content-start flex-wrap"
            >
              <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Status</p>
                <p className="opportunity-view-text">{opportunity.status}</p>
              </div>

              <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Origem</p>
                <p className="opportunity-view-text">{opportunity.origin}</p>
              </div>

              {/* <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Gmt</p>
                <p className="opportunity-view-text">{opportunity.gmt}</p>
              </div> */}

              <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Descrição do Objeto de Contratação</p>
                <p className="opportunity-view-text">
                  {opportunity.description}
                </p>
              </div>

              {/* <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Status</p>
                <p className="opportunity-view-text">Não criada</p>
              </div> */}

              {/* <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Proposta</p>
                <p className="opportunity-view-text">{proposta ? proposta.index : "Sem registro"}</p>
              </div> */}

              {/* <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Provedor</p>
                <p className="opportunity-view-text">{proposta ? proposta.provider : "Sem registro"}</p>
              </div> */}

              <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Critério de julgamento</p>
                <p className="opportunity-view-text">{criterio}</p>
              </div>

              {/* <div className="opportunity-view-field-wrapper">
                <p className="opportunity-view-label">Contato</p>
                <p className="opportunity-view-text">
                  {proposta ? proposta.contactPerson : "Sem registro"}
                </p>
              </div> */}

              <div className="w-100 mt-5">
                <p className="opportunity-view-label">Itens</p>
                {itemsContent}
              </div>

            </Col>
          </Col>

          <Row className="mt-3 d-flex justify-content-center w-100" style={{ visibility: "hidden" }}>
            <Col
              xs={6}
              className="d-flex justify-content-end"
              onClick={() => notifyCreation("error", "declinio")}
            >
              {/* <MenuButton icon={declineIcon} title="Declínio" /> */}
            </Col>
          </Row>
        </Row>
      </Cards>

      <Modal show={modalItem} onHide={() => setModalItem(!modalItem)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes de Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>{itemDescription}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalItem(!modalItem)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalDecline
        showModal={showModalDecline}
        setShowModal={setShowModalDecline}
        title={titleModal}
        declineStatus={props.declineStatus}
        declineOpportunity={(data, usuario) => props.postDeclineOpportunity(data, usuario)}
        opportunity={opportunity}
        user={props.user}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    opportunities: state.opportunities.opportunities,
    portals: state.portals.portals,
    items: state.items.items,
    proposals: state.proposals.proposals,
    companies: state.companies.companies,
    parts: state.parts.parts,
    declineStatus: state.declineStatus.declineStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
    postDeclineOpportunity: (data, usuario) => dispatch(storeActions.postDeclineOpportunity(data, usuario)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Oportunidade);
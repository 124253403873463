import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import {defineState} from 'redux-localstore';

const defaultState = {
  portals: [],
  error: false,
  loading: false,
  lastSync: false
};

const initialState = defineState(defaultState)('portals');

const fetchStart = (state) => {
  return updateObject(state, { error: false, loading: true, lastSync: false });
};

const fetchSuccess = (state, action) => {
  const result = action.portals.length > 0 ? action.portals : [];  
  return updateObject(state, {
    portals: result,
    error: false,
    loading: false,
    lastSync: new Date()
  });
};

const fetchFail = (state) => {
  return updateObject(state, { error: true, loading: false, lastSync: false, portals: [] });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PORTALS_START:
      return fetchStart(state);
    case actionTypes.FETCH_PORTALS_SUCCESS:
      return fetchSuccess(state, action);
    case actionTypes.FETCH_PORTALS_FAILED:
      return fetchFail(state);
    default:
      return {
        ...state,
        error: true,
        loading: false
      };
  }
};

export default reducer;

import React, { Component } from "react";
import "./SideNotification.scss";
import NotificationItem from "./NotificationItem/NotificationItem";

import { connect } from "react-redux";

class SideNotification extends Component {

  render() {
    let content;

    if (this.props.notifications.length > 0) {
      content = this.props.notifications.map((el) =>{
        if(!el.created){
          el.created = true;
          return (
            <NotificationItem
              key={el.id + Math.round(Math.random() * 100)}
              header={el.header}
              content={el.content}
              type={el.type}
              notificationTime={this.props.delay}
            />
          )
        }
      } 
      );
    }

    return (
      <>
        <div className="notification-bar-wrapper d-flex justify-content-center align-items-end">
          <ul className="p-0 m-0 w-100 notification-list-wrapper">{content}</ul>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    delay: state.notifications.notificationDelay,
  };
};


export default connect(mapStateToProps)(SideNotification);

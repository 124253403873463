import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchRegexStart = () => {
  return {
    type: actionTypes.FETCH_REGEX_START,
  };
};

export const fetchRegexSuccess = (regex) => {
  return {
    type: actionTypes.FETCH_REGEX_SUCCESS,
    regex: regex,
  };
};

export const fetchRegexFailed = () => {
  return {
    type: actionTypes.FETCH_REGEX_FAILED,
  };
};

export const initRegex = (usuario) => {
  return (dispatch) => {
    dispatch(fetchRegexStart());
    return new Promise((resolve, reject) => {

      ServeServices.getRegex(usuario)
        .then((response) => {
          dispatch(fetchRegexSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchRegexFailed());
          reject(err);
        });

    });
  };
};

export const putRegex = (regex, token) => {
  return (dispatch) => {
    dispatch(fetchRegexStart());
    return new Promise((resolve, reject) => {
      ServeServices.putRegex(regex, token)
        .then((response) => {
          dispatch(fetchRegexSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchRegexFailed());
          reject(err);
        });

    });
  };
};

export const postRegex = (regex, token) => {
  return (dispatch) => {
    dispatch(fetchRegexStart());
    return new Promise((resolve, reject) => {
      ServeServices.postRegex(regex, token)
        .then((response) => {
          dispatch(fetchRegexSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchRegexFailed());
          reject(err);
        });

    });
  };
};

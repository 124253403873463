import React, { useState } from "react";
import "./ForgotPassword.scss";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormCard from "../../Cards/FormCard/FormCard";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ForgotPassword(props) {
  const origin = window.location.origin;

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false)
  const [statusCaptcha, setStatusCaptcha] = useState(!origin.includes("localhost:3000")); // Se estiver sob domínio = TRUE; Se estiver local = FALSE 

  const notifyCreation = (type, message) => {
    props.notify(
      {
        id: `id ${message}`,
        header: 'TODO',
        content: message,
        type: type,
      },
      5000
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const baseUrl = (origin.includes("localhost:3000")) ? "http://localhost:3002/api/v1/": origin + "/api/v1/";

    const response = await fetch(baseUrl + 'user/recover' ,{
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({email})
    })

    // console.log(response)

    setSubmitted(true)

    // props.onForgotPasswordSubmitted(userData);
  };

  const ForgotPasswordTitle = (
    <Row className="d-flex flex-row justify-content-center align-items-center">
      <Col xs={1} className="arrow-click-container"></Col>
      <Col xs={11}>
        <h1>Esqueci a senha</h1>
      </Col>
    </Row>
  );

  function handleVerificationSuccess(token, ekey) {
    setStatusCaptcha(false)
  }

  return (
    <FormCard>
      <Row className="w-100 h-100">

        <Col xs={12} className="d-flex flex-row pl-4 pt-3">
          <a href="/login">
            <FontAwesomeIcon size="lg" icon={faArrowAltCircleLeft} /> Voltar
          </a>
        </Col>

        <Col>
        <br></br>
          <Col xs={12}>
            <p className="forgotpassword-title">
              Por favor, informe o e-mail cadastrado para o envio da nova senha.
            </p>
          </Col>

          <Col xs={12} className="d-flex flex-column justify-content-center">
            <Form
              className="p-2 align-items-center mb-5"
              onSubmit={(e) => onSubmit(e)}
            >
              <Form.Group
                controlId="exampleForm.ControlInput1"
                // className="d-flex flex-row align-items-center mb-4"
              >
                <Row className="w-100 align-items-center">
                  <Col
                    xs={12}
                    className="d-flex justify-content-center flex-column w-100 align-items-center"
                  >
                    <Form.Label className="forgotpassword-input-label">
                    <p className="email-legend"> E-mail </p>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="forgotpassword-input-control ml-4"
                      value={email}
                      placeholder="E-mail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              { statusCaptcha ?
              <><p className="legend-captcha">Complete o captcha</p>
              <Col xs={12} className="content-captcha">
                <HCaptcha
                  sitekey="dfc29da1-236a-4160-8057-e51409ede601"
                  onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />
              </Col></>
              :
              <></>
              }

            { !statusCaptcha && !submitted ?
              <Button
                variant="primary"
                type="submit"
                className="button-subimit"
                onClick={()=>notifyCreation("alert","Foi enviado")}
              >
                Enviar
              </Button>
              :
              <></>
              }
              
            </Form>
          </Col>
        </Col>
      </Row>
    </FormCard>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPasswordSubmitted: (userData) =>
      dispatch(storeActions.signup(userData)),
      
    notify: (notification, delay) =>
    dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React from "react";
import "./SubMenuSubItem.scss";
import { NavLink } from "react-router-dom";

export default function SubMenuSubItem({ title, link }) {

  return (
    <li>
      <NavLink
        to={link}
        className={`itemWrapper text-center pt-2 pb-2`}
        activeClassName="active-sidemenu-subitem"
      >
        {/* <img src={icon} alt={title + "_icon"} className="logo" /> */}
        <p className="ml-4 mb-0">{title}</p>
      </NavLink>
    </li>
  );
}

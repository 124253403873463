import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchUserStart = () => {
  return {
    type: actionTypes.FETCH_USER_START,
  };
};

export const fetchUserSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    user: user,
  };
};

export const fetchUserFailed = () => {
  return {
    type: actionTypes.FETCH_USER_FAILED,
  };
};

export const getUser = (token) => {
  return (dispatch) => {
    dispatch(fetchUserStart());
    return new Promise((resolve, reject) => {

      ServeServices.getUser(token)
        .then((response) => {
          dispatch(fetchUserSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchUserFailed());
          reject(err);
        });
    });
  };
};

export const getOneUser = (usuario, dateFrom, dateTo) => {
  return (dispatch) => {
    dispatch(fetchUserStart());
    return new Promise((resolve, reject) => {

      ServeServices.getOneUser(usuario, dateFrom, dateTo)
        .then((response) => {
          dispatch(fetchUserSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchUserFailed());
          reject(err);
        });
    });
  };
};

export const putUser = (user, usuario) => {
  return (dispatch) => {
    dispatch(fetchUserStart());
    return new Promise((resolve, reject) => {

      ServeServices.putUser(user, usuario)
        .then((response) => {
          dispatch(fetchUserSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchUserFailed());
          reject(err);
        });
    });
  };
};

export const postUser = (user) => {
  return (dispatch) => {
    dispatch(fetchUserStart());
    return new Promise((resolve, reject) => {

      ServeServices.postUser(user)
        .then((response) => {
          dispatch(fetchUserSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchUserFailed());
          reject(err);
        });
    });
  };
};
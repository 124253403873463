import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchItemsStart = () => {
  return {
    type: actionTypes.FETCH_ITEMS_START,
  };
};

export const fetchItemsSuccess = (items) => {
  return {
    type: actionTypes.FETCH_ITEMS_SUCCESS,
    items: items,
  };
};

export const fetchItemsFailed = () => {
  return {
    type: actionTypes.FETCH_ITEMS_FAILED,
  };
};

export const initItems = (usuario) => {
  return (dispatch) => {
    dispatch(fetchItemsStart());
    return new Promise((resolve, reject) => {

      ServeServices.getItems(usuario)
        .then((response) => {
          dispatch(fetchItemsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchItemsFailed());
          reject(err);
        });

    });
  };
};

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { defineState } from 'redux-localstore';

const defaultState = {
  dataFetched: false,
  dataInterlaced: false,
  loading: false,
  error: false,
  lastSync: null,
  token: null,
  private: false
};

const initialState = defineState(defaultState)('control');

const dataFetchingStart = (state, action) => {
  localStorage.dataFetched = false;
  return updateObject(state, {
    error: false,
    loading: true,
    token: action.token,
  });
};

const dataFetchingSuccess = (state) => {
  localStorage.dataFetched = true;
  return updateObject(state, {
    user: true,
    error: false,
    loading: false,
    lastSync: new Date(),
    dataFetched: true,
  });
};

const dataFetchingFail = (state) => {
  localStorage.dataFetched = false;
  return updateObject(state, { error: true, loading: false });
};

const eraseData = (state) => {
  localStorage.dataFetched = false;
  return updateObject(state, {
    dataFetched: false,
    dataInterlaced: false,
    loading: false,
    error: false,
    lastSync: null,
    token: null,
    private: false
  });
};

const privateState = (state, type) => {
  localStorage.private = type === true;
  return updateObject(state, {
    private: type === true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DATA_FETCHING_START:
      return dataFetchingStart(state, action);
    case actionTypes.DATA_FETCHING_SUCCESS:
      return dataFetchingSuccess(state, action);
    case actionTypes.DATA_FETCHING_FAIL:
      return dataFetchingFail(state);
    case actionTypes.DATA_FETCHING_ERASE:
      return eraseData(state);
    case actionTypes.DATA_FETCHING_PRIVATE_CONTROL:
      return privateState(state, action.private);
    default:
      return { ...state };
  }
};

export default reducer;

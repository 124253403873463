import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchFeedbackStart = () => {
  return {
    type: actionTypes.FETCH_FEEDBACK_START,
  };
};

export const fetchFeedbackSuccess = (feedback) => {
  return {
    type: actionTypes.FETCH_FEEDBACK_SUCCESS,
    feedback: feedback,
  };
};

export const fetchFeedbackFailed = () => {
  return {
    type: actionTypes.FETCH_FEEDBACK_FAILED,
  };
};

export const postFeedback = (data, usuario) => {
  return (dispatch) => {
    dispatch(fetchFeedbackStart());
    return new Promise((resolve, reject) => {

      ServeServices.postFeedback(data, usuario)
        .then((response) => {
          dispatch(fetchFeedbackSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          dispatch(fetchFeedbackFailed());
          reject(err);
        });
    });
  };
};
import React from 'react';
import "./Spinner.scss";
import { Row, Col } from "react-bootstrap";

function Spinner({ title }) {
  let infoComponent;
  if (title) {
    infoComponent = <p>{title}...</p>
  }
  return (
    <Row className="align-item-center justify-content-center">
      <Col xs={12}>
        <div className="loader"></div>
      </Col>
      <Col xs={12}>
        {infoComponent}
      </Col>
    </Row>
  )
}

export default Spinner;
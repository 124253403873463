import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import "./ModalRanking.scss"

export default function ModalRanking(props) {
  const [itemSelectTop3, setItemSelectTop3] = useState(false);
  const [top3Data, setTop3Data] = useState([]);

  // console.log(props)

  useEffect(() => {
    if (itemSelectTop3 === false) {
      if (props.isMultiItemState) {
        // console.log(props.nomeTop3Concorrente[Object.keys(props.nomeTop3Concorrente)[0]])
        setTop3Data(props.nomeTop3Concorrente[Object.keys(props.nomeTop3Concorrente)[0]])
      } else {
        // console.log(props.nomeTop3Concorrente)
        setTop3Data(props.nomeTop3Concorrente)
      }
    }
  }, [props])

  let isMulti = 0;
  for (let data in props.controlItemsManufacturersData) {
    isMulti++;
  }

  const dataCurrentCompany = props.dataEmpresa.filter(data => { if (data.totalPrice > 0) { return data } })
  let itemsCurrentCompany = [];
  dataCurrentCompany.map(data => {
    itemsCurrentCompany.push(data.itemDescription)
  })

  function findProposalReload() {
    props.setSelected("")
    props.setItemSelect("")
    props.setSelectedTable("")
    props.setItensConcorrenteSelecionado([])
    props.setConcorrentes([])
    props.setDataBar({})

    let proposal = props.proposalSelectedData

    if (proposal) {
      props.findProposal(props.opId, proposal);
      props.setModalShow(true);
    }
  }

  function adjustingTag() {
    //ajustando qual gráfico aparece em tela
    let option = !props.tag
    props.setTag(option)
  }

  function filterItemTop3(itemSelected) {
    setItemSelectTop3(itemSelected)

    if (props.isMultiItemState) {
      setTop3Data(props.nomeTop3Concorrente[itemSelected])
    }

    let dataItemSelect = [];
    for (let data in props.controlItemsManufacturersData) {
      if (data === itemSelected) {
        dataItemSelect = props.controlItemsManufacturersData[data]
      }
    }

    dataItemSelect.sort(function (a, b) {
      if (a.totalPrice > b.totalPrice) {
        return true;
      } else {
        return -1;
      }
    })

    let dataItemSelectCurrentCompany;
    dataItemSelect.map(data => {
      if (data.corporateName === props.currentCompany) {
        dataItemSelectCurrentCompany = data
      }
    })

    let dataTableFormatted = dataItemSelect;
    dataTableFormatted.sort(function (a) {
      if (a.corporateName !== props.currentCompany) {
        return true;
      } else {
        return -1;
      }
    })
    props.setFullDataTop3Table(dataTableFormatted)

    let dataItemSelectFormatted = []
    dataItemSelect.map(data => { if (dataItemSelectFormatted.length < 3 && data.corporateName !== props.currentCompany) { dataItemSelectFormatted.push(data) } })

    let seriesGrapich = [];
    if (dataItemSelectCurrentCompany) {
      seriesGrapich.push({
        "name": dataItemSelectCurrentCompany.corporateName,
        "data": [dataItemSelectCurrentCompany.totalPrice],
      })
    }
    dataItemSelectFormatted.map(data => {
      seriesGrapich.push({
        "name": data.corporateName,
        "data": [data.totalPrice],
      })
    })

    let datasetBar = {}
    datasetBar = {
      series: seriesGrapich,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: `Comparativo entre ${props.currentCompanyName} e os principais concorrentes desta oportunidade`
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [itemSelected],
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "R$ " + val
            }
          }
        }
      }
    }
    props.setDataBar(datasetBar)
  }

  function downloadTable(e) {
    e.preventDefault();
    let dataPorItem = {}
    for (let name in props.dataManufacturers) {
      for (let i = 0; i < props.dataManufacturers[name].length; i++) {
        if (dataPorItem[props.dataManufacturers[name][i]["itemDescription"]] === undefined) {
          dataPorItem[props.dataManufacturers[name][i]["itemDescription"]] = []
        }
        dataPorItem[props.dataManufacturers[name][i]["itemDescription"]].push(props.dataManufacturers[name][i])
      }
    }

    //ordenando o objeto 'dataPorItem'
    let dataPorItemClassificado = {};
    for (let prop in dataPorItem) {
      let empresa = dataPorItem[prop].find((item) => item.corporateName === props.currentCompany)
      let newProp = dataPorItem[prop].filter((item) => item.corporateName !== props.currentCompany)
      if (dataPorItemClassificado[prop] === undefined) {
        dataPorItemClassificado[prop] = []
      }
      var teste = newProp.sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (empresa !== undefined) {
        teste.unshift(empresa)
      }
      dataPorItemClassificado[prop] = teste
    }

    //Formatando o objeto como array
    let dataPorItemCompleto = []
    for (var prop in dataPorItemClassificado) {
      for (let i = 0; i < dataPorItemClassificado[prop].length; i++) {
        dataPorItemCompleto.push({
          "corporateName": dataPorItemClassificado[prop][i].corporateName,
          "currency": dataPorItemClassificado[prop][i].currency,
          "itemDescription": dataPorItemClassificado[prop][i].itemDescription,
          "itemNumber": dataPorItemClassificado[prop][i].itemNumber,
          "itemPrice": dataPorItemClassificado[prop][i].itemPrice,
          "proposalNumber": dataPorItemClassificado[prop][i].proposalNumber,
          "quantity": dataPorItemClassificado[prop][i].quantity,
          "taxCode": dataPorItemClassificado[prop][i].taxCode,
          "totalPrice": dataPorItemClassificado[prop][i].totalPrice,
          "um": dataPorItemClassificado[prop][i].um
        })
      }
    }
    let formData = dataPorItemCompleto.map(item => {
      return {
        "Razão Social": item.corporateName,
        "Descrição do item": item.itemDescription,
        "Quantidade": item.quantity,
        "Unidade de medida": item.um,
        "Valor Item": item.itemPrice,
        "Valor Total": item.totalPrice,
        "Moeda": item.currency,
        "Número da Proposta": item.proposalNumber,
        "Número do item": item.itemNumber,
        "Código Fiscal": item.taxCode,
        "Estrutura": item.structure,
      }
    })
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = { Sheets: { 'Dados da oportunidade': ws }, SheetNames: ['Dados da oportunidade'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Proposta:" + props.opId);
  }

  return (
    <Modal onHide={() => { props.setShowModal(false) }} show={props.showModal} size="xl" className="p-0 m-0">
      <Modal.Header closeButton onClick={() => {
        props.setShowModal(false);
        props.setTag(false);
        props.setItemSelect("");
        props.setSelected("");
        props.setSelectedTable("");
        props.setItensConcorrenteSelecionado([]);
        props.setConcorrentes([]);
        props.setOpId("");
        props.setNomeTop3Concorrente([])
        setTop3Data([])
        setItemSelectTop3(false)
      }}>
        <Modal.Title>Oportunidade: {props.opId}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container fluid>
          <Row>
            <Col sm={6}>
              <h5></h5>
            </Col>
            <Col sm={4}>
              {/* <Button variant="dark" onClick={() => { adjustingTag() }}>
                Alterar Gráfico
              </Button> */}
            </Col>
            <Col sm={2}>
              <Button variant="dark" closeButton onClick={() => { findProposalReload(props.numOportunidade, props.selectedProposal) }}>
                <FontAwesomeIcon className="" size="lg" icon={faSync} />
              </Button>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col>
              <h5>Principais concorrentes</h5>
              <select onChange={(e) => { props.filterItens(e) }} value={props.selected} className="p-1 pl-2 pr-2 w-75">
                <option className={props.selected !== "" ? "display-none" : ""}>Selecione o concorrente</option>
                {top3Data?.map(item => {
                  return (<option value={item?.corporateName}>{item?.corporateName}</option>)
                })}
              </select>
              <br></br>
              <br></br>
              <h6>Todos concorrentes desta oportunidade</h6>
              <div>
                <select onChange={(e) => { props.filterItens(e) }} value={props.selected} className="p-1 pl-2 pr-2 w-75">
                  <option className={props.selected !== "" ? "display-none" : ""}>Selecione o concorrente</option>
                  {props.concorrentes.map(item => {
                    return (<option value={item.corporateName}>{item.corporateName}</option>)
                  })}
                </select>
              </div>
            </Col>
            <Col>
              <div className={(props.selected === "" && props.isMultiItemState === true) ? "mt-2" : "display-none"} >
                <h6>Demais itens desta oportunidade:</h6>
                <select onChange={(e) => { filterItemTop3(e.target.value) }} value={itemSelectTop3} className="p-1 pl-2 pr-2 w-75">
                  <option className={itemSelectTop3 !== false ? "display-none" : ""}>Selecione um item</option>
                  {props.itensConcorrenteSelecionado.map(item => {
                    if (itemsCurrentCompany.some(itemCurrentCompany => itemCurrentCompany === item)) {
                      return (<option className="optionItems" value={item}> {item}</option>)
                    } else {
                      return (<option value={item}>{item}</option>)
                    }
                  })}
                </select>
              </div>

              <div className={(props.selected !== "" && isMulti !== 1) ? "display-block" : "display-none"}>
                <h5>Itens desta oportunidade:</h5>
                <select onChange={(e) => { props._itemSelect(e) }} value={props.itemSelect} className="p-1 pl-2 pr-2 w-75">
                  <option className={props.itemSelect !== "" ? "display-none" : ""}>Selecione um item</option>
                  {props.itensConcorrenteSelecionado.map(item => {
                    return (<option value={item}>{item}</option>)
                  })}
                </select>
              </div>
            </Col>
          </Row>
        </Container>
        <br></br>
        <Container fluid>
          <div className={(props.tag === false) ? "display-block" : "display-none"}>
            <ReactApexChart
              className="row d-flex justify-content-center"
              options={props.dataBar.options}
              series={props.dataBar.series}
              type="bar"
              width="90%"
              height="500"
              id="bar"
            />
          </div>

          <div className={(props.tag === true) ? "display-block" : "display-none"}>
            <ReactApexChart
              className="row d-flex justify-content-center"
              options={props.dataPie.options}
              series={props.dataPie.series}
              type="pie"
              width="60%"
            />
          </div>
        </Container>
        <div className="justify-content-center align-items-center d-flex">
          <Table striped bordered hover className="table-ranking">
            <thead>
              <tr>
                <th>Razão social</th>
                <th>
                  Descrição do item
                  {/* <select onChange={(e) => { props.itemSelectTable(e) }} value={props.selectedTable} className="listaItensTable">
                    <option className={props.selectedTable !== "" ? "display-none" : ""}> - </option>
                    {props.itensConcorrenteSelecionado.map(item => {
                      return (<option value={item}>{item}</option>)
                    })}
                  </select> */}
                </th>
                <th>Quantidade</th>
                <th>Unidade de medida</th>
                <th>Valor Item</th>
                <th>Valor Total</th>
                <th>Moeda</th>
                <th>Código Fiscal</th>
              </tr>
            </thead>
            <tbody>
              {props.fullDataTop3Table.map(item => {
                return (<>
                  <tr>
                    <td><small>{item.corporateName}</small></td>
                    <td><small>{item.itemDescription}</small></td>
                    <td><small>{item.quantity}</small></td>
                    <td><small>{item.um}</small></td>
                    <td><small>{item.itemPrice}</small></td>
                    <td><small>{item.totalPrice}</small></td>
                    <td><small>{item.currency}</small></td>
                    <td><small>{item.taxCode}</small></td>
                  </tr>
                </>)
              })}
            </tbody>
          </Table>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => { downloadTable(e) }}>
          <FontAwesomeIcon className="" size="lg" icon={faDownload} />
        </Button>
        <Button variant="secondary" closeButton onClick={() => {
          props.setShowModal(false);
          props.setTag(false);
          props.setItemSelect("");
          props.setSelected("");
          props.setSelectedTable("");
          props.setTag(false);
          props.setItensConcorrenteSelecionado([]);
          props.setConcorrentes([]);
          props.setOpId("");
        }}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal >
  )
}
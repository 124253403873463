import React, { useState } from "react";
import { Row, Col, ListGroup, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss"

export default function ModalControlCounterKeys(props) {
  const [newKey, setNewKey] = useState("");
  const [categoryItemSelected, setCategoryItemSelected] = useState(!props.category?.exception ? { ...props.category, "exception": { "item": [], "description": [] } } : props.category);
  const [exceptionTarget, setExceptionTarget] = useState("description");

  // console.log(props.category)

  async function addNewKey() {
    let newCategoryItemSelected = categoryItemSelected;
    if (newKey !== "" && !newCategoryItemSelected.exception[exceptionTarget].some(probe => probe === newKey)) {
      newCategoryItemSelected.exception[exceptionTarget].push(newKey);
      await props.putCategory(newCategoryItemSelected, props.user);
    }

    await props.putCategory(newCategoryItemSelected, props.user);
    // console.log(newCategoryItemSelected)
    setCategoryItemSelected(newCategoryItemSelected);

    setNewKey("");
  }

  async function removeKey(key) {
    let newCategoryItemSelected = categoryItemSelected;
    if (categoryItemSelected.exception[exceptionTarget].some(probe => probe === key)) {
      newCategoryItemSelected.exception[exceptionTarget] = categoryItemSelected.exception[exceptionTarget].filter((item) => item !== key)
    }

    await props.putCategory(newCategoryItemSelected, props.user);
    // console.log(newCategoryItemSelected)
    setCategoryItemSelected(newCategoryItemSelected);
  }

  return (
    <Modal show={props.category} onHide={props.closeCounterModal} >
      <Modal.Header closeButton>
        <Modal.Title>{props.category.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <ListGroup className="" variant="flush">
          <ListGroup.Item className="overflow-y-modal">
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Contra-Filtro de</div>
                  </div>
                  <select
                    onChange={(e) => { setExceptionTarget(e.target.value) }}
                    value={exceptionTarget}
                    className={(props.category.subFilter === true) ? "display-none" : "form-control"}
                  >
                    <option value="description">Descrição</option>
                    <option value="item">Anexos</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    value={newKey}
                    onChange={(e) => { setNewKey(e.target.value) }}
                    placeholder="Novo contra-filtro (palavra-chave)"
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => { addNewKey() }}
                      className="btn btn-flat"
                    >
                      <FontAwesomeIcon size="md" icon={faPlus} />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="separator mb-3"></div>
            {categoryItemSelected?.exception[exceptionTarget].map((regItem, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <div className="input-group mb-3">
                      <input className="form-control" type="text" value={regItem} />
                      <div className="input-group-append">
                        <button
                          onClick={() => { removeKey(regItem) }}
                          className="btn btn-danger"><FontAwesomeIcon size="md" icon={faTrash} /></button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })}
          </ListGroup.Item>
        </ListGroup>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.closeCounterModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchProposalDataStart = () => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_START
  }
}

export const fetchProposalDataSuccess = (proposalData) => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_SUCCESS,
    proposalData: proposalData,
  }
}

export const fetchProposalDataFailed = () => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_FAILED
  }
}

export const getProposalDataStart = () => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_START
  }
}

export const getProposalDataSuccess = (proposalData) => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_SUCCESS,
    proposalData: proposalData,
  }
}

export const getProposalDataFailed = () => {
  return {
    type: actionTypes.FETCH_PROPOSALDATA_FAILED
  }
}

export const initProposalData = (usuario, index) => {
  return dispatch => {
    dispatch(fetchProposalDataStart());

    return new Promise((resolve, reject) => {

      ServeServices.getProposalDataByIndex(usuario, index)
        .then(response => {
          dispatch(fetchProposalDataSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchProposalDataFailed());
          reject(err);
        })
    })
  }
}
import React, { useState, useEffect } from "react";
import "./SourcesMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import SourceItem from "./SourceItem/SourceItem";

import * as storeActions from "../../../../store/actions/index";

import { connect } from "react-redux";

function SourcesMenu(props) {
  const [activeItem, setActiveItem] = useState("");

  const setActiveItemHandler = (id) => {
    setActiveItem(id);
  };

  let Items = [];
  if (props.portals.length > 0) {
    if (activeItem === "") {
      setActiveItem(props.portals[0].name + "-source");
    }


    // Items = props.portals.map((el) => {
    //   return (
    //     <SourceItem
    //       id={`${el.name}-source`}
    //       key={`source-${el.name}`}
    //       clickHandler={() => {
    //         props.handlerEvent(el._id);
    //         setActiveItemHandler(el.name + "-source");
    //         // props.clearEvent([]);
    //       }}
    //       title={el.name[0]}
    //       // selected={activeItem == `${el.name}-source` ? true : false}
    //     />
    //   );
    // });

    Items.unshift(<SourceItem
      title={<FontAwesomeIcon size="xs" icon={faArrowCircleLeft} />}
      selected={false}
      clickHandler={() => {
        props.resetEvent()
      }}
    />)

    Items.unshift(<SourceItem
      title={<FontAwesomeIcon size="xs" icon={faSyncAlt} />}
      selected={false}
      clickHandler={() => {
        props.baseEvent()
      }}
    />)
  }

window.onscroll = function() {scrollFunction()};
function scrollFunction() {

document.getElementById("sourcesmenu").style.top = String(document.documentElement.scrollTop - 80) + "px";

}

  return (
    <div className="sourcesmenu-container d-flex flex-column" id="sourcesmenu"
    >
      <div className="teste">
        {Items[0]}
      </div>
      <div className="teste">
        {Items[1]}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    portals: state.portals.portals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourcesMenu);

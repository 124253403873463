import React from 'react'
import ReactApexChart from "react-apexcharts"

export default function RequestBarChart(props) {

  let state = {

    series: [{
      name: props.name,
      data: props.data
    }],
    options: {
      chart: {
        height: props.height,
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
          }
        }
      },
      title: {
        text: props.title,
        align: 'center'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: props.categories,
      },
      theme: {
        monochrome: {
          enabled: true,
          color: '#524ec6',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
    },
  };

  return (
    <>
      <ReactApexChart
        className={props.className}
        options={state.options}
        series={state.series}
        type="bar"
        height={props.height}
        width={props.width}
      />
    </>
  )
}
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap"
import * as storeActions from "../../../../store/actions/index"
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

import './styles.scss'

function CreatePortal(props) {
  const [name, setName] = useState(null);
  const [site, setSite] = useState(null);
  const [companyIdSelected, setCompanyIdSelected] = useState(null);
  const [isSendingPortal, setIsSendingPortal] = useState(false);
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    if (companies === null) {
      getCompanies()
    }
  }, [])

  async function getCompanies() {
    let allCompanies = await props.initCompanies(props.user);
    let dataCompanies = [];
    allCompanies.map(company => {
      let aux = company.name.split(' ')[0]
      aux = aux[0].toUpperCase() + aux.substr(1).toLocaleLowerCase()
      dataCompanies.push({
        "name": aux,
        "uf": company.uf,
        "id": company._id,
      })
    })
    setCompanies(dataCompanies)
  }

  const handleClose = () => props.setShowModal(false);

  async function handleSubmitPortal(event) {
    event.preventDefault();
    setIsSendingPortal(true)

    let data = {
      name,
      site,
      companyId: companyIdSelected,
      disabled: false
    }
    await props.setPortal(data, props.user)

    setIsSendingPortal(false)
    handleClose();
  }

  return (
    <Modal
      size="sm"
      show={props.showModal}
      onHide={handleClose}
    >
      <Modal.Header className="headerModal" closeButton>
        <Modal.Title className="title-modal-portal">Cadastro de um novo portal</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmitPortal}>
          <div className="container-name-portal">
            <label className="label-name" >Nome:</label>
            <input
              className="input-name"
              type="text"
              placeholder="Nome do portal"
              onChange={event => setName(event.target.value)}
            />
          </div>

          <div className="container-site-portal">
            <label className="label-portal" >Site:</label>
            <input
              className="input-site"
              type="text"
              placeholder="Site do portal"
              onChange={event => setSite(event.target.value)}
            />
          </div>

          <div className="container-select-company">
            <label className="label-select-company" >Empresa:</label>
            <select
              className="input-company"
              onChange={event => { setCompanyIdSelected(event.target.value) }}
            >
              <option value={props.user.data.companyId}>Selecione a empresa</option>
              {companies?.map(company => {
                return (
                  <option value={company.id}>{company.name} {company.uf}</option>
                )
              })}
            </select>
          </div>

          <div className="container-submit-form-create-portal">
            <button
              className={!name || !site ? "submit-form-create-portal-disabled" : "submit-form-create-portal"}
              disabled={!name || !site ? true : false}
              type="submit"
            >{isSendingPortal ? <Spinner size="sm" animation="border" /> : "Salvar"}</button>
          </div>

        </form>
      </Modal.Body>
    </Modal >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    portals: state.portals.portals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCompanies: (user) => dispatch(storeActions.initCompanies(user)),
    setPortal: (data, user) => dispatch(storeActions.setPortal(data, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePortal)
import React from 'react'
import ReactApexChart from "react-apexcharts"

export default function RequestLineChart(props) {

  let seriesChart = [];
  props.data?.map(o => {
    seriesChart.push({
      "name": o.manufacturer,
      "data": o.mes
    })
  })

  let state = {
    series: seriesChart,
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: props.title,
        align: 'center'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: '#0000cd',
          shadeTo: 'light',
          shadeIntensity: 0.8
        }
      },
      yaxis: {
        show: false
      },
      xaxis: {
        categories: props.categories,
      },
      stroke: {
        width: 3
      }
    },
  };
  return (
    <>
      <ReactApexChart
        className={props.className}
        options={state.options}
        series={state.series}
        type={props.type}
        height={props.height}
        width={props.width}
      />
    </>
  )
}
const formatPortals = (portals, companies) => {
  return portals.map((port) => {
    // const company = companies.find((company) => company._id === port.companyId || company.parentCompanyId === port.companyId);
    // port.companyId = company ? company : "";
    return portals;
  });
};

// const formatManufacturers = (manufacturers, companies) => {
//   return manufacturers.map((manu) => {
//     const company = companies.find((company) => company._id === manu.companyId);
//     manu.companyId = company ? company : "";
//     return manu;
//   });
// };

// const formatParts = (parts, manufacturers, companies) => {
//   parts.map((part) => {
//     const company = companies.find((company) => company._id === part.companyId);
//     const manufacturer = manufacturers.find((manu) => manu._id === part.manufacturerId);
//     part.companyId = company ? company : "";
//     part.manufacturerId = manufacturer ? manufacturer : "";

//     return part;
//   });
// };

export const interlaceData = (
  portals,
  // manufacturers,
  // parts,
  companies
) => {
  return new Promise((resolve, reject) => {
    formatPortals(portals, companies);
    // formatManufacturers(manufacturers, companies);
    // formatParts(parts, manufacturers, companies);
    resolve();
  });
};
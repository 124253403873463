import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";


export const fetchingDeclineStatusStart = () => {
  return {
    type: actionTypes.FETCH_DECLINE_STATUS_START
  }
}

export const fetchDeclineStatusSuccess = (action) => {
  return {
    type: actionTypes.FETCH_DECLINE_STATUS_SUCCESS,
    declineStatus: action,
  }
}

export const fetchDeclineStatusFailed = () => {
  return {
    type: actionTypes.FETCH_DECLINE_STATUS_FAILED
  }
}

export const initDeclineStatus = (usuario, portal) => {
  return dispatch => {
    dispatch(fetchingDeclineStatusStart());
    return new Promise((resolve, reject) => {

      ServeServices.getDeclineStatus(usuario, portal)
        .then(response => {
          dispatch(fetchDeclineStatusSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchDeclineStatusFailed());
          reject(err);
        })

    })
  }
}

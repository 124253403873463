import React from "react";
import "./NotFound.scss";
import { NavLink } from "react-router-dom";
import { Card } from "react-bootstrap";

export default function NotFound(props) {

  return (
    <div className="not-found-wrapper">
      <Card>
        <Card.Body>
          <Card.Title><h1>Libo.Di</h1></Card.Title>
          <Card.Text className="mx-auto">
            <b>Navegue nos links à esquerda ou clique no botão abaixo:</b>
          </Card.Text>
          <NavLink className="not-found-button" to="/oportunidades">Oportunidades</NavLink>
        </Card.Body>
      </Card>
    </div>
  );
}

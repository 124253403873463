import React from "react";
import "./Businessinteligence.scss";

import Card from "../../Cards/Card";
import { Row, Col, Container } from "react-bootstrap";


import { connect } from "react-redux";


import ReactEcharts from 'echarts-for-react';

function Businessinteligence(props) {


  return (
  
    <Card>
      <Container>
        <iframe width="100%" height="990px"  src="https://datastudio.google.com/embed/reporting/66d78cc3-a393-4003-b93d-3e0bb43346f7/page/MQTIC" frameborder="0" allowfullscreen></iframe>
        <iframe width="100%" height="700px"  styleName={{marginTop:'-100px'}} src="https://datastudio.google.com/embed/reporting/ff136b78-9c16-4de0-aaee-19254eb97801/page/AARIC" frameborder="0" allowfullscreen></iframe>
        <iframe width="100%" height="900px"  styleName={{marginTop:'-200px'}}  src="https://datastudio.google.com/embed/reporting/a05f365e-2297-4d9b-868d-27415471803d/page/tcUIC" frameborder="0" allowfullscreen></iframe>
        <Row>
          <Col xs={12} styleName={{marginTop:'-200px'}} >
            <Row>
              <Col id='container'xs={6}>
                <h3>Cotação por Fabricante</h3>
                  <ReactEcharts
                    option={{
                      tooltip: {
                          trigger: 'axis',
                          axisPointer: {            // Use axis to trigger tooltip
                              type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                          }
                      },
                      legend: {
                          data: ['Lovato','Stahl','Steute','Philips','Wolf','Schneider']
                      },
                      grid: {
                          left: '3%',
                          right: '4%',
                          bottom: '3%',
                          containLabel: true
                      },
                      xAxis: {
                          type: 'value'
                      },
                      yAxis: [{
                          type: 'category',
                          data: ['13/abr', '14/abr', '15/abr', '16/abr', '17/abr', '18/abr', '19/abr']
                      },
                      {
                          type: 'category',
                          data: [2400,2500,2700,2800,3600,3890, 3890]
                      }],
                      series: [
                          {
                              name: 'Lovato',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [320, 302, 301, 334, 390, 330, 320]
                          },
                          {
                              name: 'Stahl',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [120, 132, 101, 134, 90, 230, 210]
                          },
                          {
                              name: 'Steute',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [220, 182, 191, 234, 290, 330, 310]
                          },
                          {
                              name: 'Philips',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [150, 212, 201, 154, 190, 330, 410]
                          },
                          {
                              name: 'Wolf',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [820, 832, 901, 934, 1290, 1330, 1320]
                          },
                          {
                              name: 'Schneider',
                              type: 'bar',
                              stack: 'total',
                              label: {
                                  show: true
                              },
                              emphasis: {
                                  focus: 'series'
                              },
                              data: [820, 832, 901, 934, 1290, 1330, 1320]
                          }
                      ]
                  }}
                  />
              </Col>
              <Col id='container'xs={6}>
              <h3>Faturamento por Fabricante</h3>
                <ReactEcharts
                  option={{
                    // title: {
                    //     text: 'Cotação',
                    //     subtext: 'por Fabricantes',
                    //     left: 'center'
                    // },
                    tooltip: {
                        trigger: 'item'
                    },
                    // legend: {
                    //     orient: 'vertical',
                    //     left: 'left',
                    // },
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: '100%',
                            data: [
                                {value: 448, name: 'Lovato'},
                                {value: 648, name: 'Steute'},
                                {value: 735, name: 'Stahl'},
                                {value: 580, name: 'Philips'},
                                {value: 484, name: 'Wolf'},
                                {value: 120, name: 'Schneider'}
                            ],
                            label: {
                                position: 'inner',
                                fontSize: 14,
                                formatter: '{c} K',
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                }}
                />
              </Col>
            
            </Row>  
          </Col>          
        </Row>
      </Container>
     </Card>
    
    
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Businessinteligence);

import React, { useState } from "react";
import { Row, Col, ListGroup, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss"

export default function ModalControlKeys(props) {
  const [newKey, setNewKey] = useState(null);
  const [categoryItemSelected, setCategoryItemSelected] = useState(props.categoryItemSelected);
  const [target, setTarget] = useState(props.categoryItemSelected.target)

  async function addNewKey() {
    let newCategoryItemSelected = categoryItemSelected;

    newCategoryItemSelected.regex.push(newKey);

    await props.putCategory(newCategoryItemSelected, props.user);
    setCategoryItemSelected(newCategoryItemSelected);

    setNewKey("");
  }

  async function removeKey(key) {
    let newCategoryItemSelected = categoryItemSelected
    newCategoryItemSelected.regex = categoryItemSelected.regex.filter((item) => item !== key)

    await props.putCategory(newCategoryItemSelected, props.user);
    setCategoryItemSelected(newCategoryItemSelected);
  }

  async function updateTarget(value) {
    let newCategoryItemSelected = categoryItemSelected;
    newCategoryItemSelected.target = value;

    setTarget(value);

    await props.putCategory(newCategoryItemSelected, props.user)
  }

  return (
    <Modal show={props.categoryItemSelected} onHide={props.closeModal} >
      <Modal.Header closeButton>
        <Modal.Title>{props.categoryItemSelected.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <ListGroup className="" variant="flush">
          <ListGroup.Item className="overflow-y-modal">
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">{(props.category.subFilter === false) ? "Filtrar em" : "Sub-Filtro de"}</div>
                  </div>
                  <select
                    onChange={(e) => { updateTarget(e.target.value) }}
                    value={target}
                    className={(props.category.subFilter === true) ? "display-none" : "form-control"}
                  >
                    <option value="description">Descrição</option>
                    <option value="item">Anexos</option>
                  </select>
                  <select
                    onChange={(e) => { updateTarget(e.target.value) }}
                    value={target}
                    className={(props.category.subFilter === false) ? "display-none" : "form-control"}
                  >
                    {props.categoriesList.map(r => (<option value={r.name}>{r.name}</option>))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    value={newKey}
                    onChange={(e) => { setNewKey(e.target.value) }}
                    placeholder="Novo item (palavra-chave)"
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => { addNewKey() }}
                      className="btn btn-flat"
                    >
                      <FontAwesomeIcon size="md" icon={faPlus} />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="separator mb-3"></div>
            {categoryItemSelected.regex.map((regItem, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <div className="input-group mb-3">
                      <input className="form-control" type="text" value={regItem} />
                      <div className="input-group-append">
                        <button
                          onClick={() => { removeKey(regItem) }}
                          className="btn btn-danger"><FontAwesomeIcon size="md" icon={faTrash} /></button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })}
          </ListGroup.Item>
        </ListGroup>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import logo from '../../../../assets/petrolead-branco.png'
import * as storeActions from "../../../../store/actions/index";
import { connect } from "react-redux";
import "./SignUpGuest.scss"

export function SignUpGuest(props) {

  const [nameUser, setNameUser] = useState('');
  const [office, setOffice] = useState('');
  const [email, setEmail] = useState('');
  const [employeeRegistration, setEmployeeRegistration] = useState('');
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [photoBase64, setPhotoBase64] = useState('');
  const [errors, setErrors] = useState({ "default": 1 }) //este valor no estado garante que o mesmo inicie com uma condição de erro bloqueando os botoes
  const [token, setToken] = useState('')

  const values = {
    "nameUser": nameUser,
    "office": office,
    "email": email,
    "photoBase64": photoBase64,
    "employeeRegistration": employeeRegistration,
    "password": password,
    "confirmPassword": confirmPassword,
    "token": token,
  }

  // função para validar os campos do formulario
  function validate(values) {
    const errors = {}

    //Validando o nome
    if (values.nameUser === '') {
      errors.nameUser = 'O nome é obrigatório';
    }

    //Validando o email
    if (!values.email.includes('@')) {
      errors.email = 'Informe um e-mail válido'
    }
    if (values.email === '') {
      errors.email = 'O e-mail é obrigatório!'
    }

    //Validando o cargo
    if (values.office === '') {
      errors.office = 'O cargo é obrigatório';
    }

    // //Validando a foto
    // if (values.photoBase64 === '') {
    //   errors.photo = 'A foto é obrigatória';
    // }

    //Validando o registro
    if (values.employeeRegistration === '') {
      errors.employeeRegistration = 'O registro é obrigatório';
    }

    //Validando a senha
    if (values.password === '') {
      errors.password = 'A senha é obrigatória';
    } else if (values.password.length < 6) {
      errors.password = 'A senha deve conter no mínimo 6 caracteres'
    }

    //Validando a confirmação de senha
    if (values.confirmPassword === '') {
      errors.confirmPassword = 'Confirme sua senha';
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'As senhas devem ser iguais'
    }
    return errors
  }

  // Monitorando o token da url para saber se é valido
  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const page_type = urlParams.get('token')

    if (page_type === null) {
      window.location.assign('/')
    }
    setToken(page_type)
  }, [window.location.search])

  //Monitorando o campo de confirmação para limpar as mensagem de error anteriores caso não exista
  useEffect(() => {
    setErrors(validate(values))
  }, [confirmPassword])

  // Função para salvar o formulario
  const saveForm = async () => {

    setErrors(validate(values))

    if (Object.keys(errors).length === 0) {
      // Salvando as mensagens de erro retornada
      let result = await props.confirmSignUp(values)
      // percorrendo a variavel 'result' para reconhecer qual mensagem de erro ela 
      // possui e seta o erro no campo correto
      result.message.map((res) => {
        if (res === "token inválido") {

        }

        if (res === "Email inválido") {
          errors.email = 'Email inválido'
        }

        if (res === "Senhas não coincidem") {
          errors.confirmPassword = "Senhas não coincidem"
        }

        if (res === "Validado com sucesso") {
          window.location.assign('/login') // redireciona para a tela inicial
        }
      })
    }
  }

  //Função utilizada para carregar a foto como base64
  // function loadPhoto(e) {
  //   getBase64(e).then(result => {
  //     setPhotoBase64(result);
  //   })
  // }

  // Função utilizada para converter imagem em base64
  // function getBase64(file) {
  //   return new Promise((resolve) => {
  //     let fileInfo;
  //     let baseUrl = '';
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = (() => {
  //       baseUrl = reader.result;
  //       resolve(baseUrl);
  //     })
  //   })
  // }

  return (
    <>
      <div id="header" className="w-auto p-4 sticky-top">
        <Row>
          <Col>
            <p id="titleHeader">Cadastro de Usuário Libo.Di</p>
          </Col>
          <Col>
            <img id="logoHeader" src={logo} alt="logo" />"
          </Col>
        </Row>
      </div>
      <Container>
        <Form >
          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Nome:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={nameUser} onChange={(e) => setNameUser(e.target.value)} type="text" placeholder="Nome" />
              {errors.nameUser && <span className="formField__error">{errors.nameUser}</span>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              E-mail:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="E-mail" />
              {errors.email && <span className="formField__error">{errors.email}</span>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Setor:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={office} onChange={(e) => setOffice(e.target.value)} type="text" placeholder="Setor" />
              {errors.office && <span className="formField__error">{errors.office}</span>}
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Foto:
            </Form.Label>
            <Col sm="8">
              <Form.Control onChange={(e) => loadPhoto(e.target.files[0])} type="file" />
              {errors.photo && <span className="formField__error">{errors.photo}</span>}
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Registro:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={employeeRegistration} onChange={(e) => setEmployeeRegistration(e.target.value)} type="text" placeholder="Registro" />
              {errors.employeeRegistration && <span className="formField__error">{errors.employeeRegistration}</span>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 text-left">
            <Form.Label column sm="2">
              Senha:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Senha" />
              {errors.password && <span className="formField__error">{errors.password}</span>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 text-left">
            <Form.Label column sm="2">
              Confirme sua senha:
            </Form.Label>
            <Col sm="8">
              <Form.Control autocomplete="off" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} type="password" placeholder="Confirme sua senha" />
              {errors.confirmPassword && <span className="formField__error">{errors.confirmPassword}</span>}
            </Col>
          </Form.Group>
          <Container fluid>
            <Button variant="flat" onClick={saveForm} >Salvar <FontAwesomeIcon className="" size="lg" icon={faSave} /></Button>
          </Container>
          <br></br>
        </Form>
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    confirmSignUp: (o) => dispatch(storeActions.confirmSignUp(o))
  };
};

export default connect(null, mapDispatchToProps)(SignUpGuest);
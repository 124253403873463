import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import Fabricantes from "./fabricante/Fabricantes"
import Portal from "./portal/Portal"
import Margem from "./margem/Margem"
import "./Dashboard.scss";

export default function Dashboard() {

  const [dashboardSelect, setDashboardSelect] = useState("");
  const [statusFabricantes, setStatusFabricantes] = useState(false);
  const [statusPortal, setStatusPortal] = useState(false);
  const [statusMargem, setStatusMargem] = useState(false);

  const [dashboardEnabled, setDashboardEnabled] = useState(<></>)

  function selectDashboard(e) {
    setStatusFabricantes(false)
    setStatusPortal(false)
    setStatusMargem(false)
    setDashboardSelect(e.target.value)

    if (e.target.value === "Fabricante") {
      setDashboardEnabled(<Fabricantes />)
    } else if (e.target.value === "Portal") {
      setStatusPortal(true)
      setDashboardEnabled(<Portal />)
    } 
    // else if (e.target.value === "Margem") {
    //   setStatusMargem(true)
    //   setDashboardEnabled(<Margem />)
    // }
  }

  return (
    <>
      <Card>
        <Container className="pb-4">
          <h5>Selecione o tipo de dashboard que deseja visualizar </h5>
          <select onChange={(e) => { selectDashboard(e) }} value={dashboardSelect} className="select-option-dashboard">
            <option className={dashboardSelect !== "" ? "display-none" : ""}>Selecione uma opção</option>
            <option value="Fabricante"> Dashboard Fabricante</option>
            <option value="Portal"> Dashboard Portal/Vendedor</option>
            {/* <option value="Margem"> Dashboard Margem</option> */}
          </select>
        </Container>
      </Card>
      {dashboardEnabled}

      {/*<div className={statusFabricantes === false ? "display-none" : "display-block"}>
        <Fabricantes />
      </div>

      <div className={statusPortal === false ? "display-none" : "display-block"}>
        <Portal />
      </div>

      <div className={statusMargem === false ? "display-none" : "display-block"}>
        <Margem />
      </div> */}
    </>
  )
}
import * as actionTypes from "./actionTypes";

// header, content, type, id

export const createNotification = (notification) => {
  return {
    type: actionTypes.NOTIFICATION_CREATE,
    notification: notification,
  };
};

export const deleteNotification = (id) => {
  return {
    type: actionTypes.NOTIFICATION_DELETE,
    id,
  };
};

export const notify = (notification, delay) => {
  return (dispatch) => {
    
    dispatch(createNotification(notification));

    setTimeout(() => {
      dispatch(deleteNotification(notification.id));
    }, delay);

  };
};

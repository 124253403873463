import React, { useState } from "react";
import { FeedbackTypes } from "../Feedback/FeedbackTypes";
import './styles.scss'

export function Feedback(props) {
  const [showContext, setShowContext] = useState(props.showContext);

  let classV;
  if (showContext === undefined) {
    classV = null;
  } else {
    classV = showContext ? "show" : "hidden";
  }

  return (
    <>
      <div
        className={`d-flex flex-column notification-info-context m-0 show`}
      >
        <div
          style={{ height: "320px", overflowY: "auto", overflowX: "hidden" }}
        >
          <FeedbackTypes setFeedbackIsClicked={props.setFeedbackIsClicked} />
        </div>
      </div>
    </>
  )
}
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchPortalsStart = () => {
  return {
    type: actionTypes.FETCH_PORTALS_START
  }
}

export const fetchPortalsSuccess = (portals) => {
  return {
    type: actionTypes.FETCH_PORTALS_SUCCESS,
    portals: portals,
  }
}

export const fetchPortalsFailed = () => {
  return {
    type: actionTypes.FETCH_PORTALS_FAILED
  }
}

export const initPortals = (usuario) => {
  return dispatch => {
    dispatch(fetchPortalsStart());

    return new Promise((resolve, reject) => {
      ServeServices.getPortals(usuario)
        .then(response => {
          dispatch(fetchPortalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchPortalsFailed());
          reject(err);
        })
    })
  }
}

export const setPortal = (data, usuario) => {
  return dispatch => {
    dispatch(fetchPortalsStart());

    return new Promise((resolve, reject) => {
      ServeServices.postPortal(data, usuario)
        .then(response => {
          dispatch(fetchPortalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchPortalsFailed());
          reject(err);
        })
    })
  }
}

export const updatePortal = (data, usuario) => {
  return dispatch => {
    dispatch(fetchPortalsStart());

    return new Promise((resolve, reject) => {
      ServeServices.putPortal(data, usuario)
        .then(response => {
          dispatch(fetchPortalsSuccess(response));
          resolve(response);
        })
        .catch((err) => {
          localStorage.removeItem("reduxStore");
          localStorage.removeItem("private");
          localStorage.removeItem("dataFetched");
          window.location.reload();
          dispatch(fetchPortalsFailed());
          reject(err);
        })
    })
  }
}
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Pagination from "./Pagination/DashboardOpportunityPagination";
import Filter from "./Filter/Filter";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import "./DataTable.scss";

const sortArrayOfObjects = (array, selector, direction) => {
        if (direction === "ASC") {
                array.sort(function (a, b) {
                        if (
                                a[selector].toString().toLowerCase() >
                                b[selector].toString().toLowerCase()
                        ) {
                                return 1;
                        }
                        if (
                                a[selector].toString().toLowerCase() <
                                b[selector].toString().toLowerCase()
                        ) {
                                return -1;
                        }
                        return 0;
                });
        } else if (direction === "DES") {
                array.sort(function (a, b) {
                        if (
                                a[selector].toString().toLowerCase() <
                                b[selector].toString().toLowerCase()
                        ) {
                                return 1;
                        }
                        if (
                                a[selector].toString().toLowerCase() >
                                b[selector].toString().toLowerCase()
                        ) {
                                return -1;
                        }
                        return 0;
                });
        }
};

const downloadTable = (o, opItems, regex) => {
        let fileName = "";
        let formData = o.map(item => {
                fileName = item.origin;
                const filterRegex = regex.filter(probe => probe.subFilter === false);
                let manufacturers = [];
                let localStatus = item.localStatus.length > 0 ? item.localStatus[0].statusName.name : "Sem Status";
                let note = item.localStatus.length > 0 ? item.localStatus[0].note : "Sem comentários";


                filterRegex.map(catalog => catalog.category.map(probe => {
                        probe.regex.map(word => {
                                if (opItems.find(test => test.opportunityId._id === item._id).fullDescription.toLowerCase().includes(word.toLowerCase())) {
                                        manufacturers.push(word);
                                }
                        })
                }))

                return {
                        "Número": item.index.props.children,
                        "Descrição Obj. Contratação": item.description,
                        "Data Início": item.dateStart,
                        "Data Fim": item.dateEnd,
                        "Origem": item.origin,
                        "Status": item.status,
                        "Visto": item.active ? "Sim" : "Não",
                        "Status Interno": localStatus,
                        "Comentário": note,
                        "Fabricantes": manufacturers.join(",")
                }
        })

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(formData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
}

export default function DataTableDashboardOpportunities({
        title,
        data,
        columns,
        dataType,
        callback,
        pagination,
        filter,
        header,
        categorySelected,
        mainManufacturer,
        showRejectable,
        opItems,
        regex,
        rejectableOpps,
        user,
        initStatusList,
        declineStatus,
        postDeclineOpportunity,
        initOneOpportunityStatus,
        setOpportunityStatus
}) {
        const [itemsShow, setItemsShow] = useState((localStorage.getItem("itemsShow") === null) ? 0 : localStorage.getItem("itemsShow"));
        const [selectedPage, setSelectedPage] = useState((localStorage.getItem("selectedPage") === null) ? 0 : localStorage.getItem("selectedPage"));
        const [sortIndex, setSortIndex] = useState(-1);
        const [sortAscDesc, setSortAscDesc] = useState("");
        const [dataSlice, setDataSlice] = useState("");
        // const [appliedFilters, setAppliedFilters] = useState([]);

        if (!data) {
                data = [];
        }

        data = dataSlice !== "" ? data.filter(o => o.dateEnd.split(" ")[0] === dataSlice) : data;
        // console.log(data)

        const selectedPageTrigger = localStorage.getItem("selectedPage");
        useEffect(() => {
                setSelectedPage(localStorage.getItem("selectedPage"))
        }, [selectedPageTrigger])

        data?.sort(function (a, b) {
                if (a?.index.props?.children > b?.index.props?.children) {
                        return true;
                } else {
                        return -1;
                }
        })

        const options = [10, 15, 20, 25, 30, 50, 100];
        let numberOfPages;
        const requiredPages = data.length / options[itemsShow];

        if (requiredPages % 1 !== 0) {
                if (requiredPages % 1 >= 5) {
                        numberOfPages = Math.floor(requiredPages);
                } else {
                        numberOfPages = Math.floor(requiredPages) + 1;
                }
        } else {
                numberOfPages = requiredPages;
        }

        const selectors = columns.map((column) => column["selector"]);
        // const overlays = columns.map((column) => {
        //   if (column["overlay"] === true) {
        //     return column["selector"];
        //   }
        // });

        const sliceIndex = Number(selectedPage) * options[itemsShow];

        // let filteredData = [];
        // let foundId = [];
        // let aux = 0;
        let dataSorted;

        // if (appliedFilters.length > 0) {
        //   for (let i = 0; i < appliedFilters.length; i++) {
        //     for (let j = 0; j < selectors.length; j++) {
        //       data.forEach((element) => {
        //         if (
        //           element[selectors[j]]
        //             .toString()
        //             .toLowerCase()
        //             .includes(appliedFilters[i].toLowerCase())
        //         ) {
        //           if (!foundId.includes(element._id)) {
        //             foundId[aux] = element._id;
        //             filteredData[aux] = element;
        //             aux++;
        //           }
        //         }
        //       });
        //     }
        //   }
        //   dataSorted = [...filteredData];
        // } else {
        //   dataSorted = [...data];
        // }

        dataSorted = [...data];

        if (sortIndex >= 0) {
                sortArrayOfObjects(dataSorted, selectors[sortIndex], sortAscDesc);
        }

        const dataToShow = dataSorted.slice(
                sliceIndex,
                sliceIndex + options[itemsShow]
        );

        const handleSortFilterSelect = (index) => {
                if (index === -1 || index !== sortIndex) {
                        setSortIndex(index);
                        setSortAscDesc("DES");
                } else if (index === sortIndex && sortAscDesc === "DES") {
                        setSortAscDesc("ASC");
                } else if (index === sortIndex && sortAscDesc === "ASC") {
                        setSortIndex(-1);
                        setSortAscDesc("");
                }
        };

        const tableHeader = columns.map((column, index) => {
                let activeFilter = "";
                if (sortIndex === index) {
                        activeFilter = "show ";
                        if (sortAscDesc === "DES") {
                                activeFilter += " arrow-up";
                        } else {
                                activeFilter += " arrow-down";
                        }
                }
                return (
                        <th className="datatable-header-col" key={`${column}-${index}`}>
                                <div
                                        className="d-flex flex-row"
                                        onClick={() => {
                                                handleSortFilterSelect(index);
                                        }}
                                >
                                        <p>
                                                {column.name}
                                        </p>
                                        <i className={"arrow-up ml-2 " + activeFilter}></i>
                                </div>
                        </th>
                );
        });

        const tableBody = dataToShow.map((row, index) => {
                const rowClass =
                        index % 2 === 0 ? "datatable-row-even" : "datatable-row-odd";
                let active = row.active ? null : "unviewed";
                const selectorSize = selectors.length;
                return (
                        <tr
                                className={"datatable-row " + rowClass}
                                style={row.multiple ?  {borderLeft: "6px solid #1367ec "} : {borderLeft: "6px solid #092a60"}}
                                key={`${row}-${index}`}
                        >
                                {selectors.map((col, ct) => {
                                        // console.log(ct, selectorSize)
                                        return (

                                                <th
                                                        onClick={() => {
                                                                if (callback && ct < selectorSize - 1) {
                                                                        callback(row);
                                                                }
                                                        }}
                                                        style={row.multiple ? { }
                                                                : row.declineQueue ? { backgroundColor: "rgba(236, 85, 85,0.5)" }
                                                                        : row.declined ? { backgroundColor: "rgba(236, 85, 85,0.5)" }
                                                                                : {}
                                                        }
                                                        className={"datatable-row-col " + active}
                                                        key={`${row}-${col}-${index}`}>
                                                        {row[col]}
                                                </th>
                                        )
                                })
                                }
                        </tr >
                );
        });

        const onPageChanged = (index) => {
                if (index < 1) {
                        index = 0;
                } else if (index >= numberOfPages - 1) {
                        index = numberOfPages - 1;
                }
                localStorage.setItem("selectedPage", index);
                setSelectedPage(index);
        };

        const onItemsShowChanged = (index) => {
                localStorage.setItem("itemsShow", index);
                setItemsShow(index);

                localStorage.setItem("selectedPage", 0);
                setSelectedPage(0);
        };

        let PaginationContent;

        /* Legenda das opções selecionadas para exibição da tabela */
        let titleContent;
        if (title) {
                titleContent = (
                        <Col xs={12}>
                                <p className="datatable-legend mt-2">
                                        {'Filtro: '}{categorySelected} {mainManufacturer ? "/" : ""} {mainManufacturer} {showRejectable === true ? "/ Oportunidades não filtradas" : ""}</p> {/*{title} {"/"}*/}
                        </Col>
                );
        }

        /* Sub-filtro (Filtre os campos) ** DESATIVADO ** */
        // const handleFilterAdding = (value) => {
        //   const newFilters = [...appliedFilters];
        //   newFilters.push(value);
        //   setAppliedFilters(newFilters);
        // };

        // const handleFilterRemoving = (index) => {
        //   const newFilters = [...appliedFilters];
        //   newFilters.splice(index, 1);
        //   setAppliedFilters(newFilters);
        // };

        if (pagination) {
                PaginationContent = (
                        <Col className="d-flex flex-row justify-content-end align-content-center mt-3 mr-3">
                                <Pagination
                                        title={dataType !== undefined ? dataType : title}
                                        itens={data.length}
                                        options={options}
                                        selectedPage={selectedPage}
                                        selectedOption={itemsShow}
                                        onPageChanged={onPageChanged}
                                        onItemsShowChanged={onItemsShowChanged}
                                        pagesNumber={numberOfPages}
                                        opItems={opItems}
                                        downloadTable={(o, opItems, regex) => { downloadTable(o, opItems, regex) }}
                                        dataToShow={data}
                                        regex={regex}
                                />
                        </Col>
                );
        }

        return (
                <Row className="w-100">
                        {/* Legenda das opções selecionadas para exibição da tabela */}
                        {titleContent}
                        <Col className="ml-3">
                                <Row>
                                        <div className="legend-multiple"></div> <h6>Oportunidade presente na matriz e na filial</h6>
                                </Row>
                                <Row>
                                        <div className="legend-matriz"></div> <h6>Oportunidade na matriz</h6>
                                </Row>
                        </Col>

                        {/* Filtro de data */}
                        
                        <Filter
                                data={data}
                                setDataSlice={(o) => { setDataSlice(o) }}
                                rejectableOpps={rejectableOpps}
                                showRejectable={showRejectable}
                                user={user}
                                initStatusList={initStatusList}
                                declineStatus={declineStatus}
                                postDeclineOpportunity={postDeclineOpportunity}
                                initOneOpportunityStatus={initOneOpportunityStatus}
                                setOpportunityStatus={setOpportunityStatus}
                        />

                        <Col xs={12} className="w-100 datatable-outer">
                                <table className="w-100 datable-main">
                                        <thead>
                                                <tr className="datable-head-row">{tableHeader}</tr>
                                        </thead>
                                        <tbody>{tableBody}</tbody>
                                </table>
                        </Col>
                        {PaginationContent}
                </Row>
        );
}
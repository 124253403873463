import React from "react";
import "./AttachmentMenu.scss";
import { Dropdown } from "react-bootstrap";
import AttachLogo from "../../../../assets/attach-24dp.svg";
import ServeServices from "../../../../ServeServices";
import { connect } from "react-redux";
import * as storeActions from "../../../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

function AttachmentMenu({ options, fileid, user, arquivos }) {
  const DownloadFileAsync = async (filename, fileid) => {
    const link = await ServeServices.getOpportunityFile(user, filename, fileid);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link.data.link;
    a.target = "_blank";
    a.download = filename;
    a.click();
  }

  // const DownloadFileAsync = async (filename, fileid) => {
  //   const file = await ServeServices.getOpportunittyFile(user.token, filename, fileid);

  //   const data = new Blob([file.data], {type: 'application/zip'});
  //   const element = document.createElement("a");
  //       element.setAttribute("href", window.URL.createObjectURL(data));
  //       element.setAttribute("download", filename);
  //       element.style.display = "none";
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.removeChild(element);

  //   // var data = new Blob([file.data], {type: 'octet/stream'});
  //   // var csvURL = window.URL.createObjectURL(data);

  //   // var a = document.createElement('a');
  //   // document.body.appendChild(a);
  //   // a.style = "display: none";
  //   // //a.href = csvURL;
  //   // a.setAttribute('href', 'application/zip,' + data)
  //   // a.download = filename;
  //   // a.click();
  //   // window.URL.revokeObjectURL(csvURL);
  // }

  let itemsContent;

  if (options) {
    itemsContent = options.map(el => {
      return (
        <>
          {el === "Tudo" ?
            <>
              {/* <Dropdown.Divider />
              <Dropdown.Item onClick={async () => { options.map(obj => { DownloadFileAsync(obj, fileid) }) }}><b>Baixar {el}</b></Dropdown.Item> */}
            </>
            :
            <Dropdown.Item onClick={async () => DownloadFileAsync(el, fileid)}>Baixar {el}</Dropdown.Item>
          }

        </>
      )
    })
  }

  return (
    <Dropdown className="attachment-function">
      <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ display: 'block', width: '2rem' }}>
        {/* <img className="attachment-img" src={AttachLogo} alt="attach-icon" /> */}
        <FontAwesomeIcon className="paperClipColor mt-2" size="lg" icon={faPaperclip} />
        {/* <div id="clips" className="notification-float-ballom"><p>{arquivos}</p></div> */}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {itemsContent}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentMenu);


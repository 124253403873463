import React, { useState, useEffect } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import * as storeActions from "../../../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import CategoryFilters from "./CategoryFilters";
import CategoriesList from "./CategoriesList";
import CategorySelectedList from "./CategorySelectedList"

function Category(props) {

  const [categoriesList, setCategoriesList] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState(null);

  useEffect(async () => {
    if (!categoriesList) setCategoriesList(await props.initRegex(props.user))
  }, [])

  async function createNewCategory() {
    const regex = {
      "name": newCategoryName,
      "disabled": false,
      "dashboard": false,
      "subFilter": false,
      "userId": props.user.data._id,
      "companyId": props.user.data.companyId,
      "portalId": props.user.data.selectedPortalId
    }
    await props.postRegex(regex, props.user.token)
    setCategoriesList(await props.initRegex(props.user))
    setNewCategoryName('')
  }

  return (
    <>
      <Container className="container">
        <h1>Catálogo</h1>
        <Card className="mb-4">
          <Card.Body>
            <Card.Text>
              <Row>
                <Col>
                  <Card className="mb-1 limit-height">
                    <Card.Body>
                      <Card.Title>Adicionar Categoria</Card.Title>
                      <Card.Text>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <div className="input-group mb-3">
                                  <input
                                    value={newCategoryName}
                                    onChange={(e) => { setNewCategoryName(e.target.value) }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome da Categoria"
                                  />
                                  <div className="input-group-append">
                                    <button
                                      onClick={createNewCategory}
                                      className="btn btn-flat"
                                    >
                                      <FontAwesomeIcon size="lg" icon={faPlus} />
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  {<CategoriesList
                    categories={categoriesList}
                    categorySelected={categorySelected}
                    setCategorySelected={setCategorySelected}
                  />}
                </Col>
                <Col>
                  {categorySelected && <CategoryFilters category={categorySelected} />}
                  {categorySelected && <CategorySelectedList category={categorySelected} categoriesList={categoriesList} />}
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initRegex: (token) => dispatch(storeActions.initRegex(token)),
    postRegex: (regex, token) => dispatch(storeActions.postRegex(regex, token)),
    putRegex: (regex, token) => dispatch(storeActions.putRegex(regex, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);

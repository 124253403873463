import React, { useState } from "react";
import "./SideMenu.scss";
import SideMenuItem from "./SideMenuItem/SideMenuItem";
import * as storeActions from "../../store/actions/index";
import { connect } from "react-redux";

import dashboardLogo from "../../assets/dashboard.svg";
import catLogo from "../../assets/catalog-24dp.svg";
import oppLogo from "../../assets/opp-24dp.svg";
import sourceLogo from "../../assets/source-24dp.svg";
import rankLogo from "../../assets/rank-24dp.svg";
import declineLogo from "../../assets/decline.svg";

import liboNew from "../../assets/blank.png";
import liboLupa from "../../assets/petrolead-icon.png"

const SideMenu = (props) => {
  let permissionsUser = props.user.data.permissions;
  if(permissionsUser.length === 1){
    permissionsUser = permissionsUser[0]
  }

  const [activeItem, setActiveItem] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const sideMenuOnHover = (show) => {
    if (!show) {
      document.getElementById("sideMenu").classList.add("hide-sidemenu");
      document.getElementById("topMenu").classList.remove("open-top-menu");
      document
        .getElementById("main-content-container")
        .classList.add("normalize-main-container");
      document
        .getElementById("sidemenu-logo")
        .classList.remove("sidemenu-logo-show");


      if (document.getElementById("sidemenu-subitem-ul")) {
        document
          .getElementById("sidemenu-subitem-ul")
          .classList.add("sidemenu-subitem-hide");
      }

      if (document.getElementById("sidemenu-subitem-l")) {
        document
          .getElementById("sidemenu-subitem-il")
          .classList.add("sidemenu-subitem-hide");
      }

    } else {
      document.getElementById("sideMenu").classList.remove("hide-sidemenu");
      document.getElementById("topMenu").classList.add("open-top-menu");
      document
        .getElementById("main-content-container")
        .classList.remove("normalize-main-container");

      document
        .getElementById("sidemenu-logo")
        .classList.add("sidemenu-logo-show");

      if (document.getElementById("sidemenu-subitem-ul")) {
        document
          .getElementById("sidemenu-subitem-ul")
          .classList.remove("sidemenu-subitem-hide");
      }

    }
  };

  const setActiveItemHandler = (id) => {
    setActiveItem(id);
  };

  return (
    <div
      className="sidemenu-container hide-sidemenu"
      id="sideMenu"
      onMouseOver={() => sideMenuOnHover(true)}
      onMouseOut={() => sideMenuOnHover(false)}
    >
      <div className="side-menu-title-container">
        <img
          id="sidemenu-logo"
          src={liboNew}
          alt="libo logo"
          className="sidemenu-logo"
        />
      </div>
      <hr />
      <nav>
        <ul className="side_menu_items_wrapper pt-3">
          <SideMenuItem
            isOpen={isOpen}
            id="side-opp"
            title="Oportunidades"
            icon={oppLogo}
            link="/oportunidades"
            active={activeItem == "side-opp" && isOpen}
            handler={setActiveItemHandler}
          />
          {permissionsUser.viewCatalog &&
            <SideMenuItem
              isOpen={isOpen}
              id="side-cat"
              title="Catálogo"
              icon={catLogo}
              link="/catalogo"
              active={activeItem == "side-cat" && isOpen}
              handler={setActiveItemHandler}
            />}
          {/* {permissionsUser.viewPortal &&
            <SideMenuItem
              isOpen={isOpen}
              id="side-sour"
              title="Portal"
              icon={sourceLogo}
              link="/portais"
              active={activeItem == "side-sour" && isOpen}
              handler={setActiveItemHandler}
            />
          } */}
          {permissionsUser.viewRanking &&
            <SideMenuItem
              isOpen={isOpen}
              id="side-rank"
              title="Ranking"
              icon={rankLogo}
              link="/ranking"
              active={activeItem == "side-rank" && isOpen}
              handler={setActiveItemHandler}
            />
          }
          {permissionsUser.viewDashboard &&
            <SideMenuItem
              isOpen={isOpen}
              id="side-dash"
              title="Dashboard"
              icon={dashboardLogo}
              link="/dashboard"
              active={activeItem == "side-dash" && isOpen}
              handler={setActiveItemHandler}
            />
          }
          <SideMenuItem
            isOpen={isOpen}
            id="side-decline"
            title="Declinadas"
            icon={declineLogo}
            link="/decline"
            active={activeItem == "side-decline" && isOpen}
            handler={setActiveItemHandler}
          />
          {/* DESATIVADO */}
          {/* <SideMenuItem
            isOpen={isOpen}
            id="side-bi"
            title="B.I"
            active
            icon={biLogo}
            link="/businessinteligence"
            active={activeItem == "side-bi" && isOpen}
            handler={setActiveItemHandler}
          /> */}
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
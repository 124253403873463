import React from "react";
import "./Card.scss";

function Card({ children, cardStyle, title }) {
  let titleComponent;
  if (title) {
    titleComponent = (
      <div style={{ flexGrow: 0.1 }}>
        <p>{title}</p>
      </div>
    );
  }
  return (
    <div
      style={cardStyle}
      className="card-container d-flex flex-column h-100 w-100 p-2 m-0"
    >
      {titleComponent}
      <div
        style={{ flexGrow: 0.9 }}
        className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-0 w-100"
      >
        {children}
      </div>
    </div>
  );
}

export default Card;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import "./styles.scss"

const CategoriesList = (props) => (
  <Card className="cat-limiter">
    <Card.Body>
      <Card.Title>Categorias</Card.Title>
      <Card.Text>
        <Row className="p-3">
          <Col>Nome</Col>
          <Col>Editar</Col>
        </Row>
        <ListGroup variant="flush">
          {props.categories?.map(item => {
            return (<ListGroup.Item key={item._id}>
              <Row>
                <Col>
                  {item.name}
                </Col>
                <Col>
                  <button
                    onClick={() => props.setCategorySelected(item)}
                    className={(props.categorySelected === item) ? "btn btn-success btn-circle" : "btn btn-flat btn-circle"}
                  >
                    <FontAwesomeIcon size="lg" icon={faArrowRight} />
                  </button>
                </Col>
              </Row>
            </ListGroup.Item>)
          })}
        </ListGroup>
      </Card.Text>
    </Card.Body>
  </Card>
)

export default CategoriesList;
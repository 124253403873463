import React, { useState } from "react";
import './Portal.scss';
import Card from "../../../Cards/Card";
import { Row, Col, DropdownButton, Dropdown, Container, InputGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as storeActions from "../../../../store/actions/index";
import SearchInput from "../../Ranking/OpportunityFilter/SearchInput/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ReactApexChart from "react-apexcharts";

function Portal(props) {

  const [formSearch, setFormSearch] = useState("Data Fim")
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [listaPortal, setListaPortal] = useState([])
  const [listaVendedor, setListaVendedor] = useState([])
  const [oportunidades, setOportunidades] = useState([])
  const [mesSelecionado, setMesSelecionado] = useState("Janeiro")
  const [anoSelecionado, setAnoSelecionado] = useState("2021")
  const [trimestreSelecionado, setTrimestreSelecionado] = useState("1º Trimestre")
  const [semestreSelecionado, setSemestreSelecionado] = useState("1º Semestre")
  const [statusPieChart, setStatusPieChart] = useState(<></>)
  const [statusBarChart, setStatusBarChart] = useState(<></>)
  const [pieSelecionado, setPieSelecionado] = useState("")
  const [propostasFiltradasBarChart, setPropostasFiltradasBarChart] = useState([])
  const [propostasFiltradasPieChart, setPropostasFiltradasPieChart] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [alert, setAlert] = useState(<></>)
  const [loading, setLoading] = useState(false)
  const [loadingProposal, setLoadingProposal] = useState(false)
  const [dataPie, setDataPie] = useState([])
  const [viewUser, setViewUser] = useState("")

  // console.log(props.portals)

  const currentCompany = props.companies.find(data => data._id === props.user.data.companyId).name

  let filteredOpportunities = []
  let vendedorSelecionado = null
  let portalSelecionado = null

  /* Gerando anos automaticamente a partir de 2021 */
  let year = [];
  for (let i = 2021; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }

  /* ================================ Função chamada pós click na lupa =============================================== */
  async function handleGetDashboard() {
    setLoading(true);
    setStatusPieChart(<></>)
    setStatusBarChart(<></>)
    portalSelecionado = null
    vendedorSelecionado = null
    setPieSelecionado("")
    setListaPortal([])
    setListaVendedor([])
    setAlert(<></>)

    let numMes = 0;
    switch (mesSelecionado) {
      case "Janeiro":
        numMes = 1;
        break;
      case "Fevereiro":
        numMes = 2;
        break;
      case "Março":
        numMes = 3;
        break;
      case "Abril":
        numMes = 4;
        break;
      case "Maio":
        numMes = 5;
        break;
      case "Junho":
        numMes = 6;
        break;
      case "Julho":
        numMes = 7;
        break;
      case "Agosto":
        numMes = 8;
        break;
      case "Setembro":
        numMes = 9;
        break;
      case "Outubro":
        numMes = 10;
        break;
      case "Novembro":
        numMes = 11;
        break;
      case "Dezembro":
        numMes = 12;
        break;
      default:
        numMes = 1;
        break;
    }

    let _dateFrom = dateFrom.split("-")
    let _dateTo = dateTo.split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dashboardData = []
    let dateNow = new Date();
    let seletor = formSearch;

    if (seletor === "Data Fim") {
    } else if (seletor === "Mês") {
      newDateFrom = new Date(anoSelecionado, numMes - 1, 1, "0", "0", "0");
      dateNow.setMonth(numMes - 1);
      dateNow.setDate(1);
      let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");
    } else if (seletor === "Trimestre") {
      switch (trimestreSelecionado) {
        case ("1º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(0);
          let trimestre1 = new Date(anoSelecionado, dateNow.getMonth() + 4, 0);
          trimestre1.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre1.getMonth(), trimestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 3, 1, "0", "0", "0");
          dateNow.setMonth(4);
          let trimestre2 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre2.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre2.getMonth(), trimestre2.getDate() - 1, "23", "59", "59");
          break;
        case ("3º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let trimestre3 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre3.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre3.getMonth(), trimestre3.getDate() - 1, "23", "59", "59");
          break;
        case ("4º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 9, 1, "0", "0", "0");
          dateNow.setMonth(10);
          let trimestre4 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre4.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), trimestre4.getMonth(), trimestre4.getDate() - 1, "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(4);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
      }
    } else if (seletor === "Semestre") {
      switch (semestreSelecionado) {
        case ("1º Semestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let semestre1 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(anoSelecionado, semestre1.getMonth() - 1, semestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Semestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(13);
          let semestre2 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), semestre2.getMonth() - 1, semestre2.getDate(), "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
          break;
      }
    } else {
      newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
      newDateTo = new Date(anoSelecionado, 11, "31", "23", "59", "59");
    }

    await props.setDashboard(props.user, newDateFrom / 1000, newDateTo / 1000).then((val) => {
      dashboardData = val;
      // console.log(dashboardData.opportunities.length);
    })

    setDashboardData(dashboardData);

    /* -------------------- Consultando todas as oportunidades válidas e fabricantes --------------------- */
    let oportunidadesValidas = []
    let allOpportunities = []
    let listaFabricante = []
    let listaFabricanteOptions = []
    dashboardData.opportunities?.map((opportunity) => {

      if (opportunity.matches.length > 0) {
        opportunity.matches.map(match => {

          if (match.items.length > 0) {
            oportunidadesValidas.push(opportunity)
            listaFabricante.push(match.manufacturer);
            listaFabricanteOptions.push({
              "value": match.manufacturer,
              "label": match.manufacturer,
              "isDisabled": false
            })

            if (!allOpportunities.includes(opportunity)) {
              allOpportunities.push(opportunity)
            }
          }
        })
      } else {
        oportunidadesValidas.push(opportunity)
        listaFabricante.push("Não Classificada");
        listaFabricanteOptions.push({
          "value": "Não Classificada",
          "label": "Não Classificada",
          "isDisabled": false
        })
        if (!allOpportunities.includes(opportunity)) {
          allOpportunities.push(opportunity)
        }
      }
    })
    setOportunidades(oportunidadesValidas)

    /* --------------- Salvando todos os portais ---------------------- */
    let listaPortais = [];
    oportunidadesValidas.map(oportunidade => {
      if (!listaPortais.includes(oportunidade.portalId)) {
        listaPortais.push(oportunidade.portalId)
      }
    })
    setListaPortal(listaPortais)

    /* ----------------- Salvando todos os vendedores ----------------- */
    let listaVendedores = [];
    oportunidadesValidas.map(oportunidade => {
      oportunidade.viewedBy.map(vendedor => {
        if (!listaVendedores.includes(vendedor)) {
          listaVendedores.push(vendedor)
        }
      })
    })

    //salvando o nome de quem visualizou as oportunidades 
    let nameSellers = [];
    let allUsers = await props.getUser(props.user)
    listaVendedores.map(cod => {
      allUsers.map(data => {
        if (cod === data._id) {
          nameSellers.push({
            "name": data.name,
            "id": data._id
          })
        }
      })
    })

    setListaVendedor(nameSellers)
    setLoading(false);
  }

  function selectPortal(e) {
    setAlert(<></>)
    portalSelecionado = e.target.value
    vendedorSelecionado = null

    let oportunidadesPortal = [];
    oportunidades.map(oportunidade => {
      if (oportunidade.portalId === e.target.value) {
        oportunidadesPortal.push(oportunidade)
      }
    })

    setDataPie(dashboardData.opportunities)
    filteredOpportunities = oportunidadesPortal

    let fabricantes = [];
    let fabricantesFiltrado = [];
    oportunidadesPortal.map(opportunity => {
      opportunity.matches.map(match => {
        if (match.items.length > 0) {
          fabricantes.push(match.manufacturer);
          if (!fabricantesFiltrado.includes(match.manufacturer)) {
            fabricantesFiltrado.push(match.manufacturer);
          }
        }
      })
    })

    let quantidadePorFabricante = {};
    for (let i = 0; i < fabricantes.length; i++) {
      if (!quantidadePorFabricante[fabricantes[i]]) {
        quantidadePorFabricante[fabricantes[i]] = 0;
      }
      quantidadePorFabricante[fabricantes[i]]++;
    }

    let qtdFabricante = []
    for (let prop in quantidadePorFabricante) {
      qtdFabricante.push(quantidadePorFabricante[prop])
    }

    let auxDataPie = [];
    for (let i = 0; i < qtdFabricante.length; i++) {
      auxDataPie.push({
        "fabricante": fabricantesFiltrado[i],
        "ocorrencia": qtdFabricante[i]
      })
    }

    setPropostasFiltradasPieChart(auxDataPie)

    //Atualizando o gráfico de pizza
    let datasetPie = {
      options: {
        labels: fabricantesFiltrado,
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.50
          }
        },
        title: {
          text: 'Análise de aparições em oportunidades'
        },
        responsive: [
          {
            breakpoint: 360,
            options: {
              chart: {
                width: "100%"
              },
              legend: {
                show: false
              }
            }
          }
        ],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              selectPie(config.w.config.labels[config.dataPointIndex]);
            }
          }
        }
      },
      series: qtdFabricante
    };

    setStatusPieChart(
      <Container fluid>
        <Col id="pieChartID">
          <ReactApexChart
            className="row d-flex justify-content-center"
            options={datasetPie.options}
            series={datasetPie.series}
            type="pie"
            width="500"
          />
          <br></br>
        </Col>
      </Container>
    )
  }

  function selectVendedor(e) {
    setAlert(<></>)
    setPieSelecionado("")
    setStatusBarChart(<></>)
    vendedorSelecionado = e.target.value
    setViewUser(e.target.value);
    // console.log(e.target.value)

    let oportunidadesVendedor = [];
    oportunidades.map(oportunidade => {
      if (portalSelecionado !== null) {
        if (oportunidade.portalId === portalSelecionado) {
          oportunidade.viewedBy.map(vendedor => {
            if (vendedor === e.target.value) {
              oportunidadesVendedor.push(oportunidade)
            }
          })
        }
      } else {
        oportunidade.viewedBy.map(vendedor => {
          if (vendedor === e.target.value) {
            oportunidadesVendedor.push(oportunidade)
          }
        })
      }
    })

    setDataPie(oportunidadesVendedor)
    filteredOpportunities = oportunidadesVendedor

    let fabricantes = [];
    let fabricantesFiltrado = [];
    oportunidadesVendedor.map(opportunity => {
      opportunity.matches.map(match => {
        if (match.items.length > 0) {
          fabricantes.push(match.manufacturer);
          if (!fabricantesFiltrado.includes(match.manufacturer)) {
            fabricantesFiltrado.push(match.manufacturer);
          }
        }
      })
    })

    let visualizacaoVendedor = {};
    for (let i = 0; i < fabricantes.length; i++) {
      if (!visualizacaoVendedor[fabricantes[i]]) {
        visualizacaoVendedor[fabricantes[i]] = 0;
      }
      visualizacaoVendedor[fabricantes[i]]++;
    }

    let qtdFabricante = []
    for (let prop in visualizacaoVendedor) {
      qtdFabricante.push(visualizacaoVendedor[prop])
    }
    let auxDataPie = [];
    for (let i = 0; i < qtdFabricante.length; i++) {
      auxDataPie.push({
        "fabricante": fabricantesFiltrado[i],
        "ocorrencia": qtdFabricante[i]
      })
    }
    setPropostasFiltradasPieChart(auxDataPie)

    //Atualizando o gráfico de pizza
    let datasetPie = {
      options: {
        labels: fabricantesFiltrado,
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.50
          }
        },
        title: {
          text: 'Análise de visualização de oportunidade por fabricante'
        },
        responsive: [
          {
            breakpoint: 360,
            options: {
              chart: {
                width: "100%"
              },
              legend: {
                show: true
              }
            }
          }
        ],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              selectPie(config.w.config.labels[config.dataPointIndex]);
            }
          }
        }
      },
      series: qtdFabricante
    };

    setStatusPieChart(
      <Container fluid>
        <Col id="pieChartID">
          <ReactApexChart
            className="row d-flex justify-content-center"
            options={datasetPie.options}
            series={datasetPie.series}
            type="pie"
            width="500"
          />
          <br></br>
        </Col>
      </Container>
    )
  }

  async function selectPie(text) {
    setPieSelecionado(text)
    setLoadingProposal(true)

    let oportunidadesFabricanteSelecionado = [];
    let itens = [];
    let propostaItensFabricante = [];
    let propostaItensFabricanteFiltrado = [];
    let itensAux = [];
    let valorAux = [];
    let proposalSelectedManufacturer = []

    filteredOpportunities.map(opportunity => {
      if (opportunity.matches.length > 0) {
        opportunity.matches.map(match => {
          if (match.manufacturer === text) {
            oportunidadesFabricanteSelecionado.push(opportunity)
            match.items.map(item => {
              itens.push(item)
            })
          }
        })
      }
    })

    let indexAPI = []
    oportunidadesFabricanteSelecionado.map(op => {
      indexAPI.push(op.index)
    })

    await props.initProposalData(props.user, indexAPI).then((val) => {
      proposalSelectedManufacturer = val
    });

    proposalSelectedManufacturer.map(proposal => {
      if (proposal.totalPrice !== 0 && proposal.corporateName === currentCompany) {
        propostaItensFabricante.push({
          "corporateName": proposal.corporateName,
          "itemDescription": proposal.itemDescription,
          "itemPrice": proposal.itemPrice,
          "quantity": proposal.quantity,
          "totalPrice": proposal.totalPrice,
          "match": proposal.itemDescription,
        })
      }
    })

    if (vendedorSelecionado === null) {
      if (propostaItensFabricante.length === 0) {
        setPieSelecionado("")
        setStatusBarChart(<></>)
        setAlert(
          <Container fluid>
            <h6>Ainda não existem propostas para o fabricante selecionado</h6>
          </Container>
        )
      } else {
        setAlert(<></>)
      }

      propostaItensFabricante.map(proposta => {
        if (!itensAux.includes(proposta.itemDescription)) {
          propostaItensFabricanteFiltrado.push(proposta)
          itensAux.push(proposta.itemDescription)
          valorAux.push(proposta.totalPrice)
        }
      })
    } else {
      proposalSelectedManufacturer.map(proposta => {
        if (proposta.totalPrice !== 0 && proposta.corporateName === currentCompany) {
          propostaItensFabricante.push({
            "corporateName": proposta.corporateName,
            "itemDescription": proposta.itemDescription,
            "itemPrice": proposta.itemPrice,
            "quantity": proposta.quantity,
            "totalPrice": proposta.totalPrice,
            "match": proposta.itemDescription,
          })
        }
      })
      if (propostaItensFabricante.length === 0) {
        setPieSelecionado("")
        setStatusBarChart(<></>)
        setAlert(
          <Container fluid>
            <h6>Ainda não existem propostas para o fabricante selecionado</h6>
          </Container>
        )
      } else {
        setAlert(<></>)
      }

      propostaItensFabricante.map(proposta => {
        if (!itensAux.includes(proposta.itemDescription)) {
          propostaItensFabricanteFiltrado.push(proposta)
          itensAux.push(proposta.itemDescription)
          valorAux.push(proposta.totalPrice)
        }
      })
    }
    setPropostasFiltradasBarChart(propostaItensFabricanteFiltrado)

    let datasetBar = {
      series: [{
        name: "Valor total: ",
        type: 'column',
        data: valorAux
      }],
      options: {
        chart: {
          type: 'bar',
          height: "60%",
          width: "80%"
        },
        title: {
          text: 'Cotações Realizadas',
          align: 'center'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1],
          distributed: true,
          background: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: itensAux,
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: "80%"
              },
              legend: {
                show: false
              }
            }
          }
        ],
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
      },
    };

    if (propostaItensFabricante.length > 0) {
      setStatusBarChart(
        <Container fluid>
          <ReactApexChart
            className="row d-flex justify-content-center"
            options={datasetBar.options}
            series={datasetBar.series}
            type="bar"
            width="800"
            height="500"
          />
          <br></br>
          <Button variant="flat" onClick={(e) => { downloadTable(e) }}>
            <FontAwesomeIcon className="" size="lg" icon={faDownload} />
          </Button>
        </Container>
      )
    }
    setLoadingProposal(false)
  }

  function downloadTable(e) {
    e.preventDefault();

    // console.log(propostasFiltradasPieChart)
    // console.log(propostasFiltradasBarChart)
    let formData1 = propostasFiltradasPieChart.map(item => {
      return {
        "Fabricante": item.fabricante,
        "Ocorrência": item.ocorrencia,
      }
    })

    let formData2 = propostasFiltradasBarChart.map(item => {
      return {
        "Fabricante selecionado": pieSelecionado,
        "Descrição do item": item.itemDescription,
        "Preço do item": item.itemPrice,
        "Match": item.match,
        "Quantidade": item.quantity,
        "Preço total": item.totalPrice,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws1 = XLSX.utils.json_to_sheet(formData1);
    const ws2 = XLSX.utils.json_to_sheet(formData2);
    const wb = { Sheets: { 'Pie': ws1, 'Bar': ws2 }, SheetNames: ['Pie', 'Bar'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Dashboard Portal/Vendedor" + ".xlsx");
  }

  function timestampToDate(dataIn) {
    try {
      let data = new Date(dataIn * 1000)
      data.setHours(data.getHours() - 3)
      data = data.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
      let newData = data.split(" ");
      let newFormData = newData[0].split("-");
      let outPutData = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
      return outPutData;
    }
    catch (e) {
      return "31/12/2099 23:59:59"
    }
  }

  function downloadDataPie(e) {
    e.preventDefault();
    let fileName = "";

    // console.log(dataPie)

    let formData = dataPie.filter(probe => probe.viewedBy.includes(viewUser) || viewUser === "").map(item => {
      let manufacturers = item.matches?.map(o => o.manufacturer)?.join(",")
      let declineStatus = "";
      if (item.declined?.declined) {
        if (item.declined.declined) {
          declineStatus = "Declinado";
        } else if (item.declined?.expired) {
          declineStatus = "Expirado"
        } else {
          declineStatus = "Não Computado"
        }
      } else {
        declineStatus = "Não Computado"
      }
      fileName = item.origin;
      return {
        "Número": item.index,
        "Descrição Obj. Contratação": item.description,
        "Data Início": timestampToDate(item.dateStart),
        "Data Fim": timestampToDate(item.dateEnd),
        "Origem": item.origin,
        "Status": item.status,
        "Declínio supervisionado": declineStatus,
        "Fabricantes": manufacturers,
        "Ofertados": item.totalPrice > 0 ? "Sim" : "Não"
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(formData);
    const timeseries = timeserieManufacturer();
    const workSheets = {
      "Oportunidades": ws,
      "Declínios": XLSX.utils.json_to_sheet(Object.entries(timeserieDecline())),
      "Ofertados": XLSX.utils.json_to_sheet(Object.entries(timeserieParticipations()))
    };
    Object.entries(timeseries).map(obj1 => {
      workSheets[obj1[0]] = XLSX.utils.json_to_sheet(Object.entries(obj1[1]));
    });
    // console.log(timeserieDecline());
    const wb = { Sheets: workSheets, SheetNames: ['Oportunidades', 'Declínios', 'Ofertados', ...Object.keys(timeseries)] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToDownload = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToDownload, fileName + fileExtension);
  }

  function timeserieManufacturer() {
    let dictionary = {};

    dataPie.filter(probe => probe.viewedBy.includes(viewUser) || viewUser === "").map(item => {
      let month = new Date(item.dateEnd * 1000);

      item.matches?.map(o => o.manufacturer).map(test => {
        if (!!dictionary[test]) {
          dictionary[test][month.toLocaleString('default', { month: 'long' })] = !dictionary[test][month.toLocaleString('default', { month: 'long' })] ? 1 : dictionary[test][month.toLocaleString('default', { month: 'long' })] + 1
        } else {
          dictionary[test] = {};
          dictionary[test][month.toLocaleString('default', { month: 'long' })] = 1;
        }
      })
    });

    return (dictionary);
  }

  function timeserieDecline() {
    let dictionary = {};

    dataPie.filter(probe => probe.viewedBy.includes(viewUser) || viewUser === "").filter(o => o.declined.declined === true).map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  function timeserieParticipations() {
    let dictionary = {};

    dataPie.filter(probe => probe.viewedBy.includes(viewUser) || viewUser === "").filter(o => o.totalPrice > 0).map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  return (
    <Card>
      <Container fluid className="pl-10 pr-0">
        <Row className="p-0">
          <Col style={{ maxWidth: "100%" }}>
            <InputGroup className="container-date">
              <DropdownButton
                className="container-select-type"
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={formSearch}
              >
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Data Fim</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Mês</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Ano</Dropdown.Item>
              </DropdownButton>
              <SearchInput
                id="input-opo"
                title="Data"
                value={dateFrom}
                selector="dataStart"
                onChange={(e) => { setDateFrom(e.target.value) }}
                show={formSearch === "Data"}
              />

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={mesSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Janeiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Fevereiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Março</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Abril</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Maio</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Junho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Julho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Agosto</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Setembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Outubro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Novembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Dezembro</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={trimestreSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>1º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>2º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>3º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>4º Trimestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={semestreSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>1º Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>2º Semestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Ano" ? "type-year" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <FormControl
                placeholder="De"
                aria-label="De"
                type="date"
                aria-describedby="basic-addon1"
                value={dateFrom}
                onChange={(e) => { setDateFrom(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <FormControl
                placeholder="Até"
                aria-label="Até"
                type="date"
                aria-describedby="basic-addon1"
                value={dateTo}
                onChange={(e) => { setDateTo(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <InputGroup.Append>
                <Button variant="flat" onClick={async () => handleGetDashboard()}><FontAwesomeIcon size="lg" icon={faSearch} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>

        <Container fluid>
          <Row>
            <Col>
              <h4>Portal:</h4>
              <select disabled={listaPortal.length === 0} onChange={(e) => { selectPortal(e) }} value={portalSelecionado} className="p-2 pl-4 pr-4 w-75">
                <option className={portalSelecionado !== null ? "display-none" : ""}>Selecione o Portal</option>
                {listaPortal.map(item => {
                  if (props.portals.some(probe => probe._id === item)) {
                    let portalValue = props.portals.find(probe => probe._id === item)
                    return (<option value={portalValue._id}>{portalValue.name}</option>)
                  } else {
                    return (<option value={item}>{item}</option>)
                  }
                })}
              </select>
            </Col>
            <Col>
              <h4>Vendedor:</h4>
              <select disabled={listaVendedor.length === 0} onChange={(e) => { selectVendedor(e) }} value={vendedorSelecionado} className="p-2 pl-4 pr-4 w-75">
                <option className={vendedorSelecionado !== "" ? "display-none" : ""}>Selecione o Vendedor</option>
                {listaVendedor.map(data => {
                  return (<option value={data.id}>{data.name}</option>)
                })}
              </select>
            </Col>
          </Row>
          <Container>{(loading === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>
        </Container>
        <br></br>
        {/* Gráfico de pizza */}
        {statusPieChart}
        <Container>{(loadingProposal === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>
        {/* Gráfico de barras */}

        <Container>{(loadingProposal === true) ? <></> : <>
          <Row className="pt-3 d-flex flex-row justify-content-center align-content-center" >
            <b>Baixar Indicadores</b>
          </Row>
          <Row className="p-3 d-flex flex-row justify-content-center align-content-center">
            <Button variant="flat" >
              <FontAwesomeIcon size="lg" icon={faDownload} onClick={e => downloadDataPie(e)} />
            </Button>
          </Row>
        </>
        }</Container>

        {loadingProposal === false && statusBarChart}

        {/* Mensagem em caso de não existir informações para opção selecionada */}
        {loadingProposal === false && alert}
      </Container>
    </Card >
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (usuario) => dispatch(storeActions.getUser(usuario)),
    setDashboard: (userId, companyId, dateFrom, dateTo) => dispatch(storeActions.setDashboard(userId, companyId, dateFrom, dateTo)),
    initOpportunities: () => dispatch(storeActions.initOpportunities()),
    initPortals: (token) => dispatch(storeActions.initPortals(token)),
    fetchData: (token) => dispatch(storeActions.fetchData(token)),
    setUserInteraction: (userId, companyId, opportunityId) => dispatch(storeActions.setUserInteraction(userId, companyId, opportunityId)),
    setPastOpportunities: (userId, companyId, dateFrom, dateTo) => dispatch(storeActions.setPastOpportunities(userId, companyId, dateFrom, dateTo)),
    initRegex: () => dispatch(storeActions.initRegex()),
    initProposalData: (usuario, index) => dispatch(storeActions.initProposalData(usuario, index)),
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    opportunities: state.opportunities.opportunities,
    error: state.opportunities.error,
    portals: state.portals.portals,
    items: state.items.items,
    proposals: state.proposals.proposals,
    regex: state.regex.regex,
    companies: state.companies.companies
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
import * as actionTypes from "./actionTypes";
import ServeServices from "../../ServeServices";

export const fetchIntegrityStart = () => {
    return {
        type: actionTypes.FETCH_INTEGRITY_START
    }
}

export const fetchIntegritySuccess = (integrity) => {
    return {
        type: actionTypes.FETCH_INTEGRITY_SUCCESS,
        integrity: integrity,
    }
}

export const fetchIntegrityFailed = () => {
    return {
        type: actionTypes.FETCH_INTEGRITY_FAILED
    }
}

export const initIntegrity = (usuario) => {
    return dispatch => {
        dispatch(fetchIntegrityStart());

        return new Promise((resolve, reject) => {
            ServeServices.setIntegrity(usuario)
                .then(response => {
                    dispatch(fetchIntegritySuccess(response));
                    resolve(response);
                })
                .catch((err) => {
                    localStorage.removeItem("reduxStore");
                    localStorage.removeItem("private");
                    localStorage.removeItem("dataFetched");
                    window.location.reload();
                    dispatch(fetchIntegrityFailed());
                    reject(err);
                })

        })

    }
}
import React from "react";
import "./SourceItem.scss";

export default function SourceItem({ title, selected, clickHandler, id,clearEvent }) {


  
  return (
    <div style={{ position: "relative" }} id={id} className="sourceitem-container mt-4" onClick={() => clickHandler(id)}>
      <div className={`sourceitem-back ${selected ? "item-selected" : null} `}></div>
      <div className={`sourceitem-wrapper ${selected ? "sourceitem-selected":null} d-flex flex-colum justify-content-center align-items-center m-0 p-0`}>
        <p className="m-0 p-0">{title}</p>
      </div>
    </div>
  );
}

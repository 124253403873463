import React, { useState, useEffect } from "react";
import './Users.scss'
import { Card, ListGroup, Row, Col, Button, Form, InputGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faUserPlus, faTrash, faShare, faUserLock, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import ModalUser from "./modal/ModalUser"

function Users(props) {
  const [modalShow, setModalShow] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const [emailGuest, setEmailGuest] = useState([])
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [statusInput, setStatusInput] = useState(true)
  const [error, setError] = useState(null)
  const [isUserBlocked, setIsUserBlocked] = useState([])

  /* Função para leitura inicial de todos os usuários e todas as empresas */
  async function read() {
    setLoading(true);
    const totalUsers = await props.getUser(props.user)
    setAllUsers(totalUsers) // -> dados dos usuários 

    //Preenchendo o status dos botões com base nos dados atuais do banco
    if (isUserBlocked.length === 0) {
      const temp = new Array(parseInt(totalUsers.length))
      totalUsers.map((user, key) => {
        temp[key] = user.disabled
      })
      setIsUserBlocked(temp)
    }
    setLoading(false);
  }

  useEffect(() => {
    read();
  }, [modalShow])

  /* Função para adicionar campos de convite para usuários */
  const addInputGuest = (e) => {
    if (allUsers.length >= props.companies[0].userLimit || allUsers.length + emailGuest?.length >= props.companies[0].userLimit) {
      setShowAlert(true)
      setStatusInput(false)
    }
    e.preventDefault()
    setEmailGuest([...emailGuest, []])
  }

  /*Função para remover campos de convite de usuário */
  const deleteGuest = (position) => {
    setEmailGuest([...emailGuest.filter((_, index) => index !== position)])
    if (emailGuest.length === 1) {
      setError(null)
    }
  }

  /* Função para salvar cada um dos emails de convidados*/
  const putOneEmail = (e, index) => {
    emailGuest[index] = e.target.value;
    setEmailGuest([...emailGuest])
  }

  /* Função para salvar o formulário (guest) */
  const saveForm = async () => {
    if ((allUsers.length + emailGuest.length) > props.companies[0].userLimit) {
      setShowAlert(true)
    } else {
      const resp = await props.postInvite({
        "emailGuest": emailGuest,
        "companyId": props.user.data.companyId,
        "companyIdList": props.user.data.companyIdList,
        "portalId": props.user.data.portalId,
        "selectedPortalId": props.user.data.selectedPortalId
      }, props.user)

      if (resp.message === "Unsatisfied request") { //em branco
        setError("Por favor, informe o e-mail")
      } else if (resp.message === "These emails already exists") { //email ja existe
        setError("E-mail já cadastrado")
      } else if (resp.message === "success") {
        setEmailGuest([])
      }
    }
  }

  async function controlDisabledUser(index, status, id) {
    let _isUserBlocked = isUserBlocked;
    _isUserBlocked[index] = status
    setIsUserBlocked(_isUserBlocked)

    await props.putUser({ "disabled": status, "_id": id }, props.user)

    read();
  }

  return (
    <Card className="mt-0 mb-3 ml-3">
      <Card.Body>
        <Form.Group as={Row} className="mb-3 text-left" controlId="formPlaintextEmail">
          <Col sm="8">
            <p>Clique para adicionar convidados <Button variant="flat" className="btn btn-primary" id="add-category" onClick={addInputGuest}><FontAwesomeIcon className="" size="lg" icon={faUserPlus} /></Button></p>
          </Col>
        </Form.Group>
        {error && <span className="formField__error">{error}</span>}
        {
          emailGuest.map((email, index) => (
            <Form.Group key={index} as={Row} className={statusInput ? "mb-1 text-left" : "display-none"}>
              <Form.Label column sm="3" for={`email${index + 1}`}>
                {`E-mail do convidado ${index + 1}:`}
              </Form.Label>
              <Col sm="8">
                <InputGroup className="mb-1">
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    id={`email${index + 1}`}
                    value={email}
                    onChange={(e) => putOneEmail(e, index)}
                  />
                  <button className="btn btn-circle text-danger" onClick={(e) => { e.preventDefault(); deleteGuest(index) }}><FontAwesomeIcon className="" size="lg" icon={faTrash} /></button>
                </InputGroup>
              </Col>
            </Form.Group>
          ))}
        <Button className={(emailGuest.length === 0) || !statusInput ? 'display-none' : 'mb-3 mt-0'} variant="flat" onClick={saveForm}>Enviar <FontAwesomeIcon className="" size="sm" icon={faShare} /></Button>
        {showAlert === true ?
          <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
            <Alert.Heading>Quantidade limite de usuários alcançada!</Alert.Heading>
            <p>
              Entre em contato com o suporte da Libo.Di caso deseja remover algum usuário, ou alterar o seu plano.
            </p>
          </Alert>
          :
          <></>
        }
        <Card.Title>Usuários cadastrados</Card.Title>
        <Card.Text style={{ marginLeft: '10rem', marginRight: '2rem', marginTop: '2rem' }}>
          <ListGroup variant="flush">
            <Row className="container-headers-list">
              <Col >Registro</Col>
              <Col >Nome</Col>
              <Col >Portal</Col>
              <Col >Status</Col>
              <Col >Ações</Col>
            </Row>
          </ListGroup>
          <ListGroup variant="flush">

            {allUsers.map((user, key) => {
              if (true) {
                return (
                  <Row key={key}>
                    <Col className="container-data-user">
                      <p style={isUserBlocked[key] ? { fontSize: '80%', color: "red", textDecoration: "line-through" } : { fontSize: '80%' }}>
                        {user.userReg}
                      </p>
                    </Col>
                    <Col className="container-data-user">
                      <p style={isUserBlocked[key] ? { fontSize: '80%', color: "red", textDecoration: "line-through" } : { fontSize: '80%' }}>
                        {user.name}
                      </p>
                    </Col>
                    <Col className="container-data-user">
                      <p style={isUserBlocked[key] ? { fontSize: '80%', color: "red", textDecoration: "line-through" } : { fontSize: '80%' }}>
                        {props.portals.filter(data => data._id === user.selectedPortalId)[0].name}
                      </p>
                    </Col>
                    <Col className="container-data-user">
                      <p style={isUserBlocked[key] ? { fontSize: '80%', color: "red", textDecoration: "line-through" } : { fontSize: '80%' }}>
                        {user.disabled ? "Desativado" : "Ativado"}
                      </p>
                    </Col>
                    <Col className="container-buttons-actions">
                      <Button
                        disabled={isUserBlocked[key]}
                        className=""
                        style={{ width: '2rem', height: '2rem' }}
                        variant="flat"
                        onClick={() => { setModalShow(true); setCurrentUser(user) }}
                      >
                        <FontAwesomeIcon style={{ width: '110%', height: '110%' }} icon={faUserEdit} />
                      </Button>

                      <Button
                        className={isUserBlocked[key] ? "display-none" : "m-1"}
                        style={{ width: '2rem', height: '2rem' }}
                        variant="danger"
                        onClick={() => { controlDisabledUser(key, true, user._id) }}
                      >
                        <FontAwesomeIcon style={{ width: '110%', height: '110%' }} icon={faUserLock} />
                      </Button>

                      <Button
                        className={isUserBlocked[key] ? "m-1" : "display-none"}
                        style={{ width: '2rem', height: '2rem' }}
                        variant="success"
                        onClick={() => { controlDisabledUser(key, false, user._id) }}
                      >
                        <FontAwesomeIcon style={{ width: '110%', height: '110%' }} icon={faUserCheck} />
                      </Button>
                    </Col>
                  </Row>
                )
              }
            })}
            <Row className="justify-content-center">{(loading === true) ? <div className="lds-dual-ring m-4 "></div> : <></>}</Row>
            <ModalUser
              user={currentUser}
              company={props.companies}
              loggedUser={props.user}
              show={modalShow}
              putUser={props.putUser}
              onHide={() => setModalShow(false)}
              portals={props.portals}
              userData={props.user}
            />
          </ListGroup>
        </Card.Text>
      </Card.Body>
    </Card >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    companies: state.companies.companies,
    portals: state.portals.portals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (usuario) => dispatch(storeActions.getUser(usuario)),
    getCompany: (usuario) => dispatch(storeActions.getCompany(usuario)),
    putUser: (user, usuario) => dispatch(storeActions.putUser(user, usuario)),
    putCompany: (company, usuario) => dispatch(storeActions.putCompany(company, usuario)),
    postUser: (user) => dispatch(storeActions.postUser(user)),
    postCompany: (company, usuario) => dispatch(storeActions.postCompany(company, usuario)),
    postInvite: (user, usuario) => dispatch(storeActions.postInvite(user, usuario)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
import React from 'react';
import "./Popover.scss";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function PopoverBase({ id, content, title, children, direction }) {
    if(['top','left','right','bottom'].includes(direction)) direction = 'left';
    return (

        <OverlayTrigger
            key={id}
            placement={direction}
            overlay={<Tooltip id={id}>
                <h3>{title}</h3>
                <p>{content}</p>
            </Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}

export default PopoverBase;
import React, { useState } from "react"

import bugImageUrl from "../../../../assets/bug.png"
import ideaImageUrl from "../../../../assets/idea.png"
import thoughtImageUrl from "../../../../assets/thought.png"
import { CloseButton } from "../CloseButton"
import FeedbackForm from "../FeedbackForm";
import { FeedbackSuccess } from "../FeedbackSuccess";

import "./styles.scss"

const feedbackTypes = {
  BUG: {
    title: 'Problema',
    image: {
      source: bugImageUrl,
      alt: 'imagem de um inseto'
    }
  },
  IDEA: {
    title: 'Ideia',
    image: {
      source: ideaImageUrl,
      alt: 'imagem de uma lampada'
    }
  },
  OTHER: {
    title: 'Outro',
    image: {
      source: thoughtImageUrl,
      alt: 'imagem de um balão de pensamento'
    }
  }
}

export function FeedbackTypes(props) {

  const [formFeedback, setFormFeedback] = useState(false)
  const [typeSelected, setTypeSelect] = useState(null)
  const [feedbackSent, setFeedbackSent] = useState(false)

  function handleRestartFeedback() {
    setFeedbackSent(false)
    setTypeSelect(null)
    setFormFeedback(false)
  }

  return (
    <>
      {feedbackSent ?
        <FeedbackSuccess
          setFeedbackIsClicked={props.setFeedbackIsClicked}
          onFeedbackRestartRequest={handleRestartFeedback}
        />
        :
        formFeedback ?
          <FeedbackForm
            typeFeedback={typeSelected}
            setFeedbackIsClicked={props.setFeedbackIsClicked}
            setFormFeedback={() => setFormFeedback(false)}
            onFeedbackSent={() => setFeedbackSent(true)}
          />
          :
          <div>
            <CloseButton handleClickClose={props.setFeedbackIsClicked} />
            {Object.entries(feedbackTypes).map(([key, value]) => {
              return (
                <div key={key} className="container-button-type">
                  <button
                    className="button-feedback-type"
                    onClick={() => { setFormFeedback(true); setTypeSelect(value) }}
                    type="button"
                  >
                    <img src={value.image.source} alt={value.image.alt} />
                    <br />
                    <span>{value.title}</span>
                  </button>
                </div>
              );
            })}
          </div>
      }
    </>
  )
}
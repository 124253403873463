import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./Breadcrumb.scss";

export default function BreadcrumbNav(props) {
  let locations = useLocation().pathname.split("/");
  locations = locations.filter((local) => local !== "");

  let routesComponents;
  let auxRoute = "";

  if (locations.length > 0) {
    routesComponents = locations.map((route, index) => {
      auxRoute += "/" + route;
      return (
        <div key={route}>
          <p className="breadcrum-divider">
            {"/ "}
            <NavLink
              to={`${auxRoute}`}
              className={`breadcrumb-wrapper ${index === locations.length - 1 ? "active-breadcumb-item" : ""
                } text-center pt-2 pb-2`}
            >
              {route}
            </NavLink>
          </p>
        </div>
      );
    });
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-start align-items-center">
        {routesComponents}
      </div>
    </>
  );
}
